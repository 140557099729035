export const InitializeCustomWorker = (successEvent, errorEvent) => {
  const worker = new Worker(/* webpackChunkName: "Worker" */new URL("/Worker.js", import.meta.url));
  worker.onmessage = results => {
    if (successEvent) {
      successEvent(results.data);
      worker.terminate();
    }
  };
  worker.onerror = results => {
    if (errorEvent) {
      errorEvent(results);
      worker.terminate();
    }
  };
  worker.onmessageerror = results => {
    if (errorEvent) {
      errorEvent(results);
      worker.terminate();
    }
  };
  return {
    worker,
    payload: function (action, requestOptions, responseType) {
      if (responseType === void 0) {
        responseType = 0;
      }
      return {
        action,
        requestOptions,
        responseType
      };
    }
  };
};