import {
  CFW_ACCESSTOKEN,
  CFW_CLIENT_COUNTRY,
  CFW_CONTACT_NUMBER,
  CFW_CountryId,
  CFW_EMAIL,
  CFW_FACEBOOKID,
  CFW_FullName,
  CFW_GOOGLEID,
  CFW_LINKEDINID,
  CFW_LOGINFO,
  CFW_PARENTPUBLISHERID,
  CFW_POINTS,
  CFW_PUBLISHERID,
  CFW_ROLES,
  CFW_SOCIAL_INTEGRATION,
  CFW_TWITTERID,
  CFW_USERGUID,
  Role_Admin,
  Role_Agency,
  Role_CompanyOwner,
  Role_Driver,
  Role_NormalUser,
  Role_OpratorOrMarketer,
  Role_Reseller,
  Role_SuperAdmin,
  Role_TeamLeader,
  Role_Translator,
} from "./CFW.js";
import { GetStorage, SetStorage, Decrypt } from "./SFW.js";
import { GetStateField } from "./StoreToolkit.js";
import Cookies from "js-cookie";
export const User = {
  GetUserInfo: (val) => {
    let usr = Cookies.get(CFW_LOGINFO);
    if (usr) {
      usr = JSON.parse(Decrypt(usr));
      if (!usr) {
        return null;
      }
      return usr[val];
    }
    return null;
  },
  SelectedCountry: (countryVal = null) => {
    const selectedCountry = countryVal ?? Cookies.get(CFW_CLIENT_COUNTRY);
    if (selectedCountry) {
      return JSON.parse(Decrypt(selectedCountry));
    }
    return null;
  },
  IsLoggedIn: (roles = "") => {
    return Cookies.get(CFW_LOGINFO) || (roles ?? "") ? true : false;
  },
  IsAdmin: (roles = "") => {
    return (
      [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(
        (i) => parseInt(i, 10) === Role_Admin
      ).length > 0
    );
  },
  IsSuperAdmin: (roles = "") => {
    return (
      [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(
        (i) => parseInt(i, 10) === Role_SuperAdmin
      ).length > 0
    );
  },
  IsCompanyOwner: (roles = "") => {
    return (
      [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(
        (i) => parseInt(i, 10) === Role_CompanyOwner
      ).length > 0
    );
  },
  IsAgency: (roles = "") => {
    return (
      [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(
        (i) => parseInt(i, 10) === Role_Agency
      ).length > 0
    );
  },
  IsTeamLeader: (roles = "") => {
    return (
      [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(
        (i) => parseInt(i, 10) === Role_TeamLeader
      ).length > 0
    );
  },
  IsNormalUser: (roles = "") => {
    return (
      [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(
        (i) => parseInt(i, 10) === Role_NormalUser
      ).length > 0
    );
  },
  IsTranslator: (roles = "") => {
    return (
      [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(
        (i) => parseInt(i, 10) === Role_Translator
      ).length > 0
    );
  },
  IsDriver: (roles = "") => {
    return (
      [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(
        (i) => parseInt(i, 10) === Role_Driver
      ).length > 0
    );
  },
  IsOperatorOrMarketer: (roles = "") => {
    return (
      [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(
        (i) => parseInt(i, 10) === Role_OpratorOrMarketer
      ).length > 0
    );
  },
  IsReseller: (roles = "") => {
    return (
      [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(
        (i) => parseInt(i, 10) === Role_Reseller
      ).length > 0
    );
  },
  Roles: () => {
    return User.GetUserInfo(CFW_ROLES);
  },
  Email: () => {
    return User.GetUserInfo(CFW_EMAIL) || "";
  },
  ContactNumber: () => {
    return User.GetUserInfo(CFW_CONTACT_NUMBER) || "";
  },
  AccessToken: () => {
    const accessToken =
      User.GetUserInfo(CFW_ACCESSTOKEN) || GetStorage(CFW_ACCESSTOKEN);
    if (accessToken) {
      SetStorage(CFW_ACCESSTOKEN, accessToken);
    }
    return accessToken || "";
  },
  FullName: () => {
    return User.GetUserInfo(CFW_FullName) || "";
  },
  PublisherId: () => {
    return User.GetUserInfo(CFW_PUBLISHERID) || "";
  },
  Points: () => {
    return parseInt(User.GetUserInfo(CFW_POINTS) || "0", 10);
  },
  UserGUID: () => {
    return User.GetUserInfo(CFW_USERGUID) || "";
  },
  CountryId: () => {
    return User.GetUserInfo(CFW_CountryId) || "";
  },
  FacebookId: () => {
    return (
      GetStorage(CFW_FACEBOOKID) ??
      GetStateField(
        CFW_SOCIAL_INTEGRATION,
        "FacebookId",
        User.GetUserInfo(CFW_FACEBOOKID)
      ) ??
      ""
    );
  },
  LinkedInId: () => {
    return (
      GetStorage(CFW_LINKEDINID) ??
      GetStateField(
        CFW_SOCIAL_INTEGRATION,
        "LinkedInId",
        User.GetUserInfo(CFW_LINKEDINID)
      ) ??
      ""
    );
  },
  GoogleId: () => {
    return (
      GetStorage(CFW_GOOGLEID) ??
      GetStateField(
        CFW_SOCIAL_INTEGRATION,
        "GoogleId",
        User.GetUserInfo(CFW_GOOGLEID)
      ) ??
      ""
    );
  },
  TwitterId: () => {
    return (
      GetStorage(CFW_TWITTERID) ??
      GetStateField(
        CFW_SOCIAL_INTEGRATION,
        "TwitterId",
        User.GetUserInfo(CFW_TWITTERID)
      ) ??
      ""
    );
  },
  ParentPublisherId: () => {
    return User.GetUserInfo(CFW_PARENTPUBLISHERID) || "";
  },
};
