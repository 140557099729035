export const DynamicOptionsData = [
  {
    DynamicOptionsId: 79,
    DynamicOptionsName: "30/18/27/00 (Super Fixed)",
    DynamicOptionTypeId: 13,
    DynamicOptionTypeName: "Energy Service Class",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T10:00:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "30/18/27/00 (Super Fixed)",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570615235.webp",
  },
  {
    DynamicOptionsId: 81,
    DynamicOptionsName: "35/37 (Super Fixed)",
    DynamicOptionTypeId: 13,
    DynamicOptionTypeName: "Energy Service Class",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T10:02:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "35/37 (Super Fixed)",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570615320.webp",
  },
  {
    DynamicOptionsId: 121,
    DynamicOptionsName: "Advertising sa Negosyo",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 17,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:53:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Advertising sa Negosyo\r\n",
    Picture: "-1644414790.webp",
  },
  {
    DynamicOptionsId: 156,
    DynamicOptionsName: "Agham",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 6,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:28:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/1362430453860551",
    Picture: "-1644463675.webp",
  },
  {
    DynamicOptionsId: 40,
    DynamicOptionsName: "Animals",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2024-03-07T22:30:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Animals",
    Picture: "40-1668380046.webp",
  },
  {
    DynamicOptionsId: 191,
    DynamicOptionsName: "Anumang Mga Produkto/Serbisyo",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 9,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:13:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Anumang Mga Produkto/Serbisyo",
    Picture: "-1644466363.webp",
  },
  {
    DynamicOptionsId: 99,
    DynamicOptionsName: "Any Products/Services",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 9,
    ModifiedBy: 1,
    ModifiedDate: "2020-10-17T16:26:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Any business that has products or services",
    Picture: "-1602951930.webp",
  },
  {
    DynamicOptionsId: 41,
    DynamicOptionsName: "Arts",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:28:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Arts",
    Picture: "41-1635740892.webp",
  },
  {
    DynamicOptionsId: 5,
    DynamicOptionsName: "Arts & Design",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:35:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/2160199680927807",
    Picture: "5-1635741314.webp",
  },
  {
    DynamicOptionsId: 1,
    DynamicOptionsName: "Associate Degree",
    DynamicOptionTypeId: 1,
    DynamicOptionTypeName: "Education Level",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2018-06-10T16:38:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: null,
  },
  {
    DynamicOptionsId: 14,
    DynamicOptionsName: "Audio Equipments",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 13,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T17:30:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Audio Equipments",
    Picture: "-1610386205.webp",
  },
  {
    DynamicOptionsId: 87,
    DynamicOptionsName: "Automotive & Equpiments",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 16,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:59:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Automotive & Equpiments",
    Picture: "-1610386435.webp",
  },
  {
    DynamicOptionsId: 160,
    DynamicOptionsName: "Automotive at Parts nito",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 16,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:47:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Automotive, Kagamitan, at Parts nito",
    Picture: "-1644464789.webp",
  },
  {
    DynamicOptionsId: 143,
    DynamicOptionsName: "Aviation",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 17,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:21:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/294543517872840",
    Picture: "-1644463263.webp",
  },
  {
    DynamicOptionsId: 39,
    DynamicOptionsName: "Aviation",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 17,
    ModifiedBy: 1,
    ModifiedDate: "2022-10-21T19:20:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/294543517872840",
    Picture: "39-1666380026.webp",
  },
  {
    DynamicOptionsId: 112,
    DynamicOptionsName: "Baby Products",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 31,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T19:02:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Baby Products",
    Picture: "-1610391690.webp",
  },
  {
    DynamicOptionsId: 2,
    DynamicOptionsName: "Bachelor Degree",
    DynamicOptionTypeId: 1,
    DynamicOptionTypeName: "Education Level",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2018-04-27T09:01:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: null,
  },
  {
    DynamicOptionsId: 94,
    DynamicOptionsName: "Bars",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2020-10-17T16:16:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "This can be used in a bar",
    Picture: "-1602951374.webp",
  },
  {
    DynamicOptionsId: 192,
    DynamicOptionsName: "Bars",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:13:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Bars",
    Picture: "-1644466388.webp",
  },
  {
    DynamicOptionsId: 24,
    DynamicOptionsName: "Beginner",
    DynamicOptionTypeId: 4,
    DynamicOptionTypeName: "Work Experience Level",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2018-06-17T14:42:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: null,
  },
  {
    DynamicOptionsId: 61,
    DynamicOptionsName: "Beverages",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2019-04-18T11:31:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1555587060.webp",
  },
  {
    DynamicOptionsId: 62,
    DynamicOptionsName: "Billiards",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2019-04-18T11:32:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1555587105.webp",
  },
  {
    DynamicOptionsId: 163,
    DynamicOptionsName: "Bilyaran",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:48:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Bilyaran",
    Picture: "-1644464878.webp",
  },
  {
    DynamicOptionsId: 67,
    DynamicOptionsName: "Business Advertising",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 17,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:29:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Business Advertising",
    Picture: "67-1635740928.webp",
  },
  {
    DynamicOptionsId: 21,
    DynamicOptionsName: "Business, Sales and Marketing",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 8,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:36:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/286342815282470",
    Picture: "21-1635741360.webp",
  },
  {
    DynamicOptionsId: 145,
    DynamicOptionsName: "Call Center o BPO",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:22:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/UrTechCityCallCenterBPOO",
    Picture: "-1644463326.webp",
  },
  {
    DynamicOptionsId: 17,
    DynamicOptionsName: "Call Center or BPO",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:36:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/UrTechCityCallCenterBPOO",
    Picture: "17-1635741368.webp",
  },
  {
    DynamicOptionsId: 114,
    DynamicOptionsName: "Camera/CCTV & Accessories",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 33,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T19:28:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Camera/CCTV & Accessories",
    Picture: "-1610393240.webp",
  },
  {
    DynamicOptionsId: 164,
    DynamicOptionsName: "Camera/CCTV at Accessories",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 33,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:48:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Camera/CCTV at Accessories\r\n",
    Picture: "-1644464906.webp",
  },
  {
    DynamicOptionsId: 6,
    DynamicOptionsName: "Cash",
    DynamicOptionTypeId: 16,
    DynamicOptionTypeName: "PaymentOptions",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2020-12-11T16:26:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Cash",
    Picture: "-1607700445.webp",
  },
  {
    DynamicOptionsId: 65,
    DynamicOptionsName: "Cigarettes",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 5,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T14:03:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Cigarettes",
    Picture: "65-1610373799.webp",
  },
  {
    DynamicOptionsId: 165,
    DynamicOptionsName: "Cigarettes",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 5,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:49:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Cigarettes",
    Picture: "-1644464929.webp",
  },
  {
    DynamicOptionsId: 42,
    DynamicOptionsName: "Cities",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:35:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Cities",
    Picture: "42-1635741284.webp",
  },
  {
    DynamicOptionsId: 117,
    DynamicOptionsName: "Cock Fighting",
    DynamicOptionTypeId: 17,
    DynamicOptionTypeName: "PogoHomeListOfBets",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2021-04-13T08:54:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "SabongGame?prod=urtechpogo",
    Picture: "-1618304030.webp",
  },
  {
    DynamicOptionsId: 116,
    DynamicOptionsName: "Color Game",
    DynamicOptionTypeId: 17,
    DynamicOptionTypeName: "PogoHomeListOfBets",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2021-04-13T08:53:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "ColorGame?prod=urtechpogo",
    Picture: "-1618303951.webp",
  },
  {
    DynamicOptionsId: 43,
    DynamicOptionsName: "Comedy",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:29:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Comedy",
    Picture: "43-1635740940.webp",
  },
  {
    DynamicOptionsId: 88,
    DynamicOptionsName: "Computer Parts & Accessories",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 17,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:57:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Computer Parts & Accessories",
    Picture: "88-1610391407.webp",
  },
  {
    DynamicOptionsId: 68,
    DynamicOptionsName: "Connecticut",
    DynamicOptionTypeId: 10,
    DynamicOptionTypeName: "United States",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T09:43:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Connecticut",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570614213.webp",
  },
  {
    DynamicOptionsId: 74,
    DynamicOptionsName: "Connecticut Light & Power",
    DynamicOptionTypeId: 11,
    DynamicOptionTypeName: "Energy Source",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T09:52:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Connecticut Light & Power",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570614519.webp",
  },
  {
    DynamicOptionsId: 16,
    DynamicOptionsName: "Construction",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:36:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/934325826751846",
    Picture: "16-1635741377.webp",
  },
  {
    DynamicOptionsId: 44,
    DynamicOptionsName: "Crime",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 5,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:29:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Crime",
    Picture: "44-1635740952.webp",
  },
  {
    DynamicOptionsId: 82,
    DynamicOptionsName:
      "Default (Super Fixed) or No Service Class or Commercial Default (Super Fixed)",
    DynamicOptionTypeId: 13,
    DynamicOptionTypeName: "Energy Service Class",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-11T15:28:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description:
      "Default (Super Fixed) or No Service Class or Commercial Default (Super Fixed)",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570615401.webp",
  },
  {
    DynamicOptionsId: 45,
    DynamicOptionsName: "Disasters or Calamities",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 6,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:29:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Disasters or Calamities",
    Picture: "45-1635740967.webp",
  },
  {
    DynamicOptionsId: 4,
    DynamicOptionsName: "Doctoral Degree",
    DynamicOptionTypeId: 1,
    DynamicOptionTypeName: "Education Level",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2018-04-28T04:06:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: null,
  },
  {
    DynamicOptionsId: 104,
    DynamicOptionsName: "Dryers & Parts",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 23,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:31:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Dryers & Parts",
    Picture: "-1610389850.webp",
  },
  {
    DynamicOptionsId: 22,
    DynamicOptionsName: "Education and Tutorial",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 9,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:37:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/2087116801321639",
    Picture: "22-1635741390.webp",
  },
  {
    DynamicOptionsId: 147,
    DynamicOptionsName: "Edukasyon at Tutorial",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 9,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:23:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/2087116801321639",
    Picture: "-1644463381.webp",
  },
  {
    DynamicOptionsId: 26,
    DynamicOptionsName: "Experienced",
    DynamicOptionTypeId: 4,
    DynamicOptionTypeName: "Work Experience Level",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2018-06-17T14:44:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: null,
  },
  {
    DynamicOptionsId: 27,
    DynamicOptionsName: "Expert",
    DynamicOptionTypeId: 4,
    DynamicOptionTypeName: "Work Experience Level",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2018-06-17T14:45:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: null,
  },
  {
    DynamicOptionsId: 100,
    DynamicOptionsName: "Eye Wear",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 20,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:02:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Eye Wear",
    Picture: "-1610388101.webp",
  },
  {
    DynamicOptionsId: 105,
    DynamicOptionsName: "Facial Care & Accessories",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 25,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:41:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Facial Care & Accessories",
    Picture: "-1610390225.webp",
  },
  {
    DynamicOptionsId: 60,
    DynamicOptionsName: "Fashion and Entertainment",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 23,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:58:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Fashion and Entertainment",
    Picture: "60-1635740990.webp",
  },
  {
    DynamicOptionsId: 28,
    DynamicOptionsName: "Fashion and Entertainment",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 11,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:37:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/220120438668854",
    Picture: "28-1635741402.webp",
  },
  {
    DynamicOptionsId: 148,
    DynamicOptionsName: "Fashion at Libangan",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 11,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:24:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/220120438668854",
    Picture: "-1644463411.webp",
  },
  {
    DynamicOptionsId: 126,
    DynamicOptionsName: "Fashion at Libangan",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 23,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:59:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Fashion at Libangan\r\n",
    Picture: "-1644414970.webp",
  },
  {
    DynamicOptionsId: 98,
    DynamicOptionsName: "Fastfood chain",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 8,
    ModifiedBy: 1,
    ModifiedDate: "2020-10-17T16:22:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "This can be used in a fast food chain",
    Picture: "-1602951731.webp",
  },
  {
    DynamicOptionsId: 193,
    DynamicOptionsName: "Fastfood chain",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 8,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:14:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Fastfood chain",
    Picture: "-1644466423.webp",
  },
  {
    DynamicOptionsId: 201,
    DynamicOptionsName: "Female",
    DynamicOptionTypeId: 18,
    DynamicOptionTypeName: "Gender",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2024-11-29T15:06:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Female",
    Picture: null,
  },
  {
    DynamicOptionsId: 46,
    DynamicOptionsName: "Foods",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 7,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:30:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Foods",
    Picture: "46-1635741006.webp",
  },
  {
    DynamicOptionsId: 63,
    DynamicOptionsName: "Foods",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2019-04-18T11:32:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1555587131.webp",
  },
  {
    DynamicOptionsId: 30,
    DynamicOptionsName: "Foods and Beverages",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 13,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:37:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/295683801190080",
    Picture: "30-1635741430.webp",
  },
  {
    DynamicOptionsId: 101,
    DynamicOptionsName: "Foot Wear",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 21,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:04:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Foot Wear",
    Picture: "-1610388214.webp",
  },
  {
    DynamicOptionsId: 92,
    DynamicOptionsName: "Fruit or Vegetable Store",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2020-10-17T16:15:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "this can be used in a fruit store",
    Picture: "-1602951183.webp",
  },
  {
    DynamicOptionsId: 109,
    DynamicOptionsName: "Garden Tools & Accessories",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 28,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:52:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Garden Tools & Accessories",
    Picture: "-1610391134.webp",
  },
  {
    DynamicOptionsId: 8,
    DynamicOptionsName: "G-Cash",
    DynamicOptionTypeId: 16,
    DynamicOptionTypeName: "PaymentOptions",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2020-12-11T15:28:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "G-Cash",
    Picture: "-1607700484.webp",
  },
  {
    DynamicOptionsId: 47,
    DynamicOptionsName: "Governments",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 8,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:31:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Governments",
    Picture: "47-1635741031.webp",
  },
  {
    DynamicOptionsId: 91,
    DynamicOptionsName: "Grocery Store",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2020-10-17T16:12:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "This can be used in a grocery store",
    Picture: "-1602951116.webp",
  },
  {
    DynamicOptionsId: 84,
    DynamicOptionsName: "GSD (Super Fixed)",
    DynamicOptionTypeId: 13,
    DynamicOptionTypeName: "Energy Service Class",
    SortId: 6,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-11T15:22:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "GSD (Super Fixed)",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570807316.webp",
  },
  {
    DynamicOptionsId: 85,
    DynamicOptionsName: "GST (Super Fixed)",
    DynamicOptionTypeId: 13,
    DynamicOptionTypeName: "Energy Service Class",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-11T15:23:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "GST (Super Fixed)",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570807360.webp",
  },
  {
    DynamicOptionsId: 12,
    DynamicOptionsName: "Gym Equipments",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 10,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T17:27:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Gym Equipments",
    Picture: "-1610386011.webp",
  },
  {
    DynamicOptionsId: 106,
    DynamicOptionsName: "Hair Care & Accessories",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 24,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:40:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Hair Care & Accessories",
    Picture: "-1610390427.webp",
  },
  {
    DynamicOptionsId: 48,
    DynamicOptionsName: "Health",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 9,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:31:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Health",
    Picture: "48-1635741042.webp",
  },
  {
    DynamicOptionsId: 33,
    DynamicOptionsName: "Hired",
    DynamicOptionTypeId: 6,
    DynamicOptionTypeName: "ClientEmploymentStatus",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2018-07-14T11:46:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: null,
  },
  {
    DynamicOptionsId: 181,
    DynamicOptionsName: "Ibang Gamit o Serbisyo",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 6,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:05:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Ibang Gamit o Serbisyo",
    Picture: "-1644465888.webp",
  },
  {
    DynamicOptionsId: 155,
    DynamicOptionsName: "Ibang Trabaho",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 15,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:27:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/307866239791366",
    Picture: "-1644463646.webp",
  },
  {
    DynamicOptionsId: 69,
    DynamicOptionsName: "Illinois",
    DynamicOptionTypeId: 10,
    DynamicOptionTypeName: "United States",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T08:25:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Illinois",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570609525.webp",
  },
  {
    DynamicOptionsId: 23,
    DynamicOptionsName: "Information Technology",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 10,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:37:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/181755190767671",
    Picture: "23-1635741442.webp",
  },
  {
    DynamicOptionsId: 25,
    DynamicOptionsName: "Intermediate",
    DynamicOptionTypeId: 4,
    DynamicOptionTypeName: "Work Experience Level",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2018-06-17T14:43:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: null,
  },
  {
    DynamicOptionsId: 90,
    DynamicOptionsName: "Jackets",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 19,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T17:59:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Jackets",
    Picture: "-1610387956.webp",
  },
  {
    DynamicOptionsId: 175,
    DynamicOptionsName: "Jackets",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 19,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:02:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Jackets",
    Picture: "-1644465695.webp",
  },
  {
    DynamicOptionsId: 37,
    DynamicOptionsName: "Job Portal offers more types of jobs",
    DynamicOptionTypeId: 7,
    DynamicOptionTypeName: "DailyTimeRecordBackgrounds",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:33:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description:
      "IT has part-time, full-time, freelance, remote, off-shore and on-shore jobs",
    Picture: "37-1635741190.webp",
  },
  {
    DynamicOptionsId: 36,
    DynamicOptionsName: "Job Portal provides you with multiple solutions",
    DynamicOptionTypeId: 7,
    DynamicOptionTypeName: "DailyTimeRecordBackgrounds",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:33:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description:
      "It provides Jobs, people, employment records, task logging, and a daily time record",
    Picture: "36-1635741198.webp",
  },
  {
    DynamicOptionsId: 138,
    DynamicOptionsName: "Kalawakan",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 18,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T14:02:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Kalawakan",
    Picture: "-1644415315.webp",
  },
  {
    DynamicOptionsId: 133,
    DynamicOptionsName: "Kalikasan",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 12,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T14:00:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Kalikasan",
    Picture: "-1644415175.webp",
  },
  {
    DynamicOptionsId: 129,
    DynamicOptionsName: "Kalusugan",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 9,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:57:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Kalusugan",
    Picture: "-1644415045.webp",
  },
  {
    DynamicOptionsId: 184,
    DynamicOptionsName: "Kamiseta",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 18,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:06:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Kamiseta",
    Picture: "-1644465975.webp",
  },
  {
    DynamicOptionsId: 123,
    DynamicOptionsName: "Komedya",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:54:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Komedya",
    Picture: "-1644414853.webp",
  },
  {
    DynamicOptionsId: 146,
    DynamicOptionsName: "Konstruksyon",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:23:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/934325826751846",
    Picture: "-1644463353.webp",
  },
  {
    DynamicOptionsId: 124,
    DynamicOptionsName: "Krimen",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 5,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:55:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Krimen",
    Picture: "-1644414876.webp",
  },
  {
    DynamicOptionsId: 139,
    DynamicOptionsName: "Laro",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 19,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T14:02:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Laro",
    Picture: "-1644415347.webp",
  },
  {
    DynamicOptionsId: 95,
    DynamicOptionsName: "Laundry Shop",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 5,
    ModifiedBy: 1,
    ModifiedDate: "2020-10-17T16:17:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "This can be used in a Laundry Shop",
    Picture: "-1602951445.webp",
  },
  {
    DynamicOptionsId: 196,
    DynamicOptionsName: "Laundry Shop",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 5,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:18:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Laundry Shop",
    Picture: "-1644466667.webp",
  },
  {
    DynamicOptionsId: 29,
    DynamicOptionsName: "Maids, Helpers and Baby Sitters",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 12,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:38:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/218654418806603",
    Picture: "29-1635741454.webp",
  },
  {
    DynamicOptionsId: 200,
    DynamicOptionsName: "Male",
    DynamicOptionTypeId: 18,
    DynamicOptionTypeName: "Gender",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2024-11-29T15:05:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Male",
    Picture: null,
  },
  {
    DynamicOptionsId: 197,
    DynamicOptionsName: "Maliliit na Tindahan",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:18:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Maliliit na Tindahan",
    Picture: "-1644466700.webp",
  },
  {
    DynamicOptionsId: 176,
    DynamicOptionsName: "Masahe at Spa",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 9,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:02:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Masahe at Spa",
    Picture: "-1644465719.webp",
  },
  {
    DynamicOptionsId: 70,
    DynamicOptionsName: "Massachusetts",
    DynamicOptionTypeId: 10,
    DynamicOptionTypeName: "United States",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T08:43:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Massachusetts",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570610571.webp",
  },
  {
    DynamicOptionsId: 11,
    DynamicOptionsName: "Massage and Spa",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 9,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T17:23:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Massage and Spa",
    Picture: "-1610385793.webp",
  },
  {
    DynamicOptionsId: 3,
    DynamicOptionsName: "Master Degree",
    DynamicOptionTypeId: 1,
    DynamicOptionTypeName: "Education Level",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2018-04-27T09:01:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: null,
  },
  {
    DynamicOptionsId: 20,
    DynamicOptionsName: "Math",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 7,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:38:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/309338316294220",
    Picture: "20-1635741463.webp",
  },
  {
    DynamicOptionsId: 152,
    DynamicOptionsName: "Math",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 7,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:26:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/309338316294220",
    Picture: "-1644463539.webp",
  },
  {
    DynamicOptionsId: 86,
    DynamicOptionsName: "Medical Equipments",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 14,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T17:32:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Medical Equipments",
    Picture: "-1610386345.webp",
  },
  {
    DynamicOptionsId: 18,
    DynamicOptionsName: "Medicine and Health Care",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 5,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:38:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/246880972606160",
    Picture: "18-1635741489.webp",
  },
  {
    DynamicOptionsId: 10,
    DynamicOptionsName: "Medicines",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 8,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T17:22:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Medicines",
    Picture: "-1610385696.webp",
  },
  {
    DynamicOptionsId: 153,
    DynamicOptionsName: "Medisina at Pangangalaga sa Kalusugan",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 5,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:26:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/309338316294220",
    Picture: "-1644463570.webp",
  },
  {
    DynamicOptionsId: 183,
    DynamicOptionsName: "Mga Alagang Hayop at Accessory",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 27,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:06:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Alagang Hayop at Accessory",
    Picture: "-1644465946.webp",
  },
  {
    DynamicOptionsId: 166,
    DynamicOptionsName: "Mga Bahagi at Accessory ng Computer",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 17,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:49:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Bahagi at Accessory ng Computer\r\n",
    Picture: "-1644464954.webp",
  },
  {
    DynamicOptionsId: 189,
    DynamicOptionsName: "Mga Bitamina/Supplement",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 7,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:09:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Bitamina/Supplement",
    Picture: "-1644466113.webp",
  },
  {
    DynamicOptionsId: 167,
    DynamicOptionsName: "Mga Dryer at Bahagi",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 23,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:50:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Dryer at Bahagi\r\n",
    Picture: "-1644464989.webp",
  },
  {
    DynamicOptionsId: 178,
    DynamicOptionsName: "Mga Gamot",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 8,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:03:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Gamot",
    Picture: "-1644465774.webp",
  },
  {
    DynamicOptionsId: 119,
    DynamicOptionsName: "Mga Hayop",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:52:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Hayop",
    Picture: "-1644414728.webp",
  },
  {
    DynamicOptionsId: 180,
    DynamicOptionsName: "Mga Instrumentong Musika",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 32,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:04:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Instrumentong Musika",
    Picture: "-1644465845.webp",
  },
  {
    DynamicOptionsId: 162,
    DynamicOptionsName: "Mga Inumin",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:47:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Inumin",
    Picture: "-1644464845.webp",
  },
  {
    DynamicOptionsId: 173,
    DynamicOptionsName: "Mga Kagamitan sa Gym",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 10,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:54:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Kagamitan sa Gym",
    Picture: "-1644465226.webp",
  },
  {
    DynamicOptionsId: 159,
    DynamicOptionsName: "Mga Kagamitang Audio",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 13,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:45:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Kagamitang Audio\r\n",
    Picture: "-1644464719.webp",
  },
  {
    DynamicOptionsId: 177,
    DynamicOptionsName: "Mga Kagamitang Medikal",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 14,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:02:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Kagamitang Medikal",
    Picture: "-1644465743.webp",
  },
  {
    DynamicOptionsId: 125,
    DynamicOptionsName: "Mga Kalamidad",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 6,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:55:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Kalamidad",
    Picture: "-1644414902.webp",
  },
  {
    DynamicOptionsId: 151,
    DynamicOptionsName: "Mga Kasambahay, Katulong at Mga Baby Sitter",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 12,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:25:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/218654418806603",
    Picture: "-1644463506.webp",
  },
  {
    DynamicOptionsId: 122,
    DynamicOptionsName: "Mga lungsod",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:54:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga lungsod\r\n",
    Picture: "-1644414824.webp",
  },
  {
    DynamicOptionsId: 158,
    DynamicOptionsName: "Mga manunulat",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:29:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/681731942207377",
    Picture: "-1644463733.webp",
  },
  {
    DynamicOptionsId: 179,
    DynamicOptionsName: "Mga Mobile Device at Accessory",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 30,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:03:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Mobile Device at Accessory",
    Picture: "-1644465801.webp",
  },
  {
    DynamicOptionsId: 170,
    DynamicOptionsName: "Mga Pagkain",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:52:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Pagkain",
    Picture: "-1644465089.webp",
  },
  {
    DynamicOptionsId: 149,
    DynamicOptionsName: "Mga Pagkain at Inumin",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 13,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:24:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/295683801190080",
    Picture: "-1644463441.webp",
  },
  {
    DynamicOptionsId: 128,
    DynamicOptionsName: "Mga pamahalaan",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 8,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:57:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga pamahalaan\r\n",
    Picture: "-1644415025.webp",
  },
  {
    DynamicOptionsId: 190,
    DynamicOptionsName: "Mga Panglaba at Piyesa",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 22,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:09:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Panglaba at Piyesa",
    Picture: "-1644466135.webp",
  },
  {
    DynamicOptionsId: 161,
    DynamicOptionsName: "Mga Produkto ng Sanggol",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 31,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:47:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Produkto ng Sanggol\r\n",
    Picture: "-1644464816.webp",
  },
  {
    DynamicOptionsId: 136,
    DynamicOptionsName: "Mga relasyon",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 15,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T14:01:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga relasyon\r\n",
    Picture: "-1644415264.webp",
  },
  {
    DynamicOptionsId: 198,
    DynamicOptionsName: "Mga Restawran",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 7,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:19:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Restawran",
    Picture: "-1644466725.webp",
  },
  {
    DynamicOptionsId: 130,
    DynamicOptionsName: "Mga Review ng Pelikula",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 22,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:58:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Review ng Pelikula\r\n",
    Picture: "-1644415100.webp",
  },
  {
    DynamicOptionsId: 186,
    DynamicOptionsName: "Mga Sports Wear at Accessories",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 29,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:07:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Sports Wear at Accessories",
    Picture: "-1644466045.webp",
  },
  {
    DynamicOptionsId: 134,
    DynamicOptionsName: "Mga tao",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 13,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T14:00:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga tao\r\n",
    Picture: "-1644415220.webp",
  },
  {
    DynamicOptionsId: 172,
    DynamicOptionsName: "Mga Tool at Accessory sa Hardin",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 28,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:53:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Mga Tool at Accessory sa Hardin",
    Picture: "-1644465196.webp",
  },
  {
    DynamicOptionsId: 93,
    DynamicOptionsName: "Mini Store",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2020-10-17T16:14:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "This can be used in a mini-store",
    Picture: "-1602951229.webp",
  },
  {
    DynamicOptionsId: 132,
    DynamicOptionsName: "Misteryo",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 11,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:59:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Misteryo",
    Picture: "-1644415154.webp",
  },
  {
    DynamicOptionsId: 111,
    DynamicOptionsName: "Mobile Devices & Accessories",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 30,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:59:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Mobile Devices & Accessories",
    Picture: "-1610391517.webp",
  },
  {
    DynamicOptionsId: 118,
    DynamicOptionsName: "Movie Reviews",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 22,
    ModifiedBy: 1,
    ModifiedDate: "2022-01-29T08:36:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description:
      "This is where you can see some movie recommendations and reviews.",
    Picture: "-1643445348.webp",
  },
  {
    DynamicOptionsId: 49,
    DynamicOptionsName: "Music",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 10,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:31:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Music",
    Picture: "49-1635741056.webp",
  },
  {
    DynamicOptionsId: 113,
    DynamicOptionsName: "Music Instruments",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 32,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T19:11:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Music Instruments",
    Picture: "-1610392277.webp",
  },
  {
    DynamicOptionsId: 131,
    DynamicOptionsName: "Musika",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 10,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:59:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Musika",
    Picture: "-1644415136.webp",
  },
  {
    DynamicOptionsId: 50,
    DynamicOptionsName: "Mystery",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 11,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:31:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Mystery",
    Picture: "50-1635741069.webp",
  },
  {
    DynamicOptionsId: 51,
    DynamicOptionsName: "Nature",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 12,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:31:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Nature",
    Picture: "51-1635741083.webp",
  },
  {
    DynamicOptionsId: 38,
    DynamicOptionsName: "Naval",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 16,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:38:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/376370123193891",
    Picture: "38-1635741499.webp",
  },
  {
    DynamicOptionsId: 154,
    DynamicOptionsName: "Naval o Seaman",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 16,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:27:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/376370123193891",
    Picture: "-1644463612.webp",
  },
  {
    DynamicOptionsId: 144,
    DynamicOptionsName: "Negosyo, Pagbebenta at Marketing",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 10,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:22:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/286342815282470",
    Picture: "-1644463293.webp",
  },
  {
    DynamicOptionsId: 71,
    DynamicOptionsName: "New Jersey",
    DynamicOptionTypeId: 10,
    DynamicOptionTypeName: "United States",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T08:47:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "New Jersey",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570610830.webp",
  },
  {
    DynamicOptionsId: 72,
    DynamicOptionsName: "New York",
    DynamicOptionTypeId: 10,
    DynamicOptionTypeName: "United States",
    SortId: 5,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T09:42:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "New York",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570614125.webp",
  },
  {
    DynamicOptionsId: 115,
    DynamicOptionsName: "Online Lottery",
    DynamicOptionTypeId: 17,
    DynamicOptionTypeName: "PogoHomeListOfBets",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2021-08-16T14:25:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "LotteryGameList/{0}",
    Picture: "-1618303835.webp",
  },
  {
    DynamicOptionsId: 66,
    DynamicOptionsName: "Others",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 6,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T14:11:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Others",
    Picture: "66-1610374249.webp",
  },
  {
    DynamicOptionsId: 32,
    DynamicOptionsName: "Others",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 15,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:39:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/307866239791366",
    Picture: "32-1635741511.webp",
  },
  {
    DynamicOptionsId: 127,
    DynamicOptionsName: "Pagkain",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 7,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:57:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Pagkain",
    Picture: "-1644414993.webp",
  },
  {
    DynamicOptionsId: 141,
    DynamicOptionsName: "Paglalakbay o Bakasyunan",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 21,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T14:03:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Paglalakbay o Bakasyunan",
    Picture: "-1644415388.webp",
  },
  {
    DynamicOptionsId: 171,
    DynamicOptionsName: "Pagsuot ng Paa",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 21,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:53:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Pagsuot ng Paa",
    Picture: "-1644465174.webp",
  },
  {
    DynamicOptionsId: 185,
    DynamicOptionsName: "Pangangalaga sa Balat",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 26,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:07:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Pangangalaga sa Balat",
    Picture: "-1644466002.webp",
  },
  {
    DynamicOptionsId: 174,
    DynamicOptionsName: "Pangangalaga sa Buhok at Mga Accessory",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 24,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:54:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Pangangalaga sa Buhok at Mga Accessory\r\n",
    Picture: "-1644465254.webp",
  },
  {
    DynamicOptionsId: 169,
    DynamicOptionsName: "Pangangalaga sa Mukha at Mga Accessory",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 25,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:51:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Pangangalaga sa Mukha at Mga Accessory\r\n",
    Picture: "-1644465057.webp",
  },
  {
    DynamicOptionsId: 182,
    DynamicOptionsName: "Pantalon",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 21,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:05:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Pantalon",
    Picture: "-1644465915.webp",
  },
  {
    DynamicOptionsId: 102,
    DynamicOptionsName: "Pants",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 21,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:05:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Pants",
    Picture: "-1610388309.webp",
  },
  {
    DynamicOptionsId: 7,
    DynamicOptionsName: "Paypal, Debit or Credit Card",
    DynamicOptionTypeId: 16,
    DynamicOptionTypeName: "PaymentOptions",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2020-12-20T17:41:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Paypal, Debit or Credit Card",
    Picture: "-1607700467.webp",
  },
  {
    DynamicOptionsId: 73,
    DynamicOptionsName: "Pennsylvania",
    DynamicOptionTypeId: 10,
    DynamicOptionTypeName: "United States",
    SortId: 6,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T09:45:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Pennsylvania",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570614310.webp",
  },
  {
    DynamicOptionsId: 52,
    DynamicOptionsName: "People",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 13,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:32:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "People",
    Picture: "52-1635741094.webp",
  },
  {
    DynamicOptionsId: 108,
    DynamicOptionsName: "Pets & Accessories",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 27,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:51:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Pets & Accessories",
    Picture: "-1610391048.webp",
  },
  {
    DynamicOptionsId: 135,
    DynamicOptionsName: "Programming",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 14,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T14:01:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Programming",
    Picture: "-1644415247.webp",
  },
  {
    DynamicOptionsId: 53,
    DynamicOptionsName: "Programming",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 14,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:32:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Programming",
    Picture: "53-1635741104.webp",
  },
  {
    DynamicOptionsId: 54,
    DynamicOptionsName: "Relationships",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 15,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:32:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Relationships",
    Picture: "54-1635741115.webp",
  },
  {
    DynamicOptionsId: 55,
    DynamicOptionsName: "Religion",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 16,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:32:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Religion",
    Picture: "55-1635741132.webp",
  },
  {
    DynamicOptionsId: 137,
    DynamicOptionsName: "Relihiyon",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 16,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T14:01:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Relihiyon",
    Picture: "-1644415285.webp",
  },
  {
    DynamicOptionsId: 34,
    DynamicOptionsName: "Resigned",
    DynamicOptionTypeId: 6,
    DynamicOptionTypeName: "ClientEmploymentStatus",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2018-07-14T11:46:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: null,
  },
  {
    DynamicOptionsId: 97,
    DynamicOptionsName: "Restaurants",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 7,
    ModifiedBy: 1,
    ModifiedDate: "2020-10-17T16:20:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "This can be used in a restaurant",
    Picture: "-1602951612.webp",
  },
  {
    DynamicOptionsId: 19,
    DynamicOptionsName: "Science",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 6,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:39:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/1362430453860551",
    Picture: "19-1635741534.webp",
  },
  {
    DynamicOptionsId: 89,
    DynamicOptionsName: "Shirts",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 18,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T17:56:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Shirts",
    Picture: "-1610387763.webp",
  },
  {
    DynamicOptionsId: 120,
    DynamicOptionsName: "Sining",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T13:53:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Sining",
    Picture: "-1644414760.webp",
  },
  {
    DynamicOptionsId: 142,
    DynamicOptionsName: "Sining at Disenyo",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:21:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/2160199680927807",
    Picture: "-1644463228.webp",
  },
  {
    DynamicOptionsId: 107,
    DynamicOptionsName: "Skin Care",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 26,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:49:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Skin Care",
    Picture: "-1610390940.webp",
  },
  {
    DynamicOptionsId: 76,
    DynamicOptionsName: "Small and Medium Business",
    DynamicOptionTypeId: 14,
    DynamicOptionTypeName: "Energy Business Type",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T09:56:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Small and Medium Business",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570614980.webp",
  },
  {
    DynamicOptionsId: 78,
    DynamicOptionsName:
      "Small Office Home Office - National Green Energy(SOSHO)",
    DynamicOptionTypeId: 14,
    DynamicOptionTypeName: "Energy Business Type",
    SortId: 3,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T09:59:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Small Office Home Office - National Green Energy(SOSHO)",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570615150.webp",
  },
  {
    DynamicOptionsId: 77,
    DynamicOptionsName: "Small Office Home Office(SOSHO)",
    DynamicOptionTypeId: 14,
    DynamicOptionTypeName: "Energy Business Type",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T09:58:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Small Office Home Office(SOSHO)",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570615012.webp",
  },
  {
    DynamicOptionsId: 80,
    DynamicOptionsName: "SOHO (Tax ID Required)",
    DynamicOptionTypeId: 13,
    DynamicOptionTypeName: "Energy Service Class",
    SortId: 7,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-11T15:23:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "SOHO (Tax ID Required)",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570615259.webp",
  },
  {
    DynamicOptionsId: 83,
    DynamicOptionsName: "SOHO (Tax ID Required) (Fixed National Green E)",
    DynamicOptionTypeId: 13,
    DynamicOptionTypeName: "Energy Service Class",
    SortId: 5,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-11T15:20:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "SOHO (Tax ID Required) (Fixed National Green E)",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570807222.webp",
  },
  {
    DynamicOptionsId: 199,
    DynamicOptionsName: "Spa o Masahe",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 6,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:19:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Spa o Masahe",
    Picture: "-1644466752.webp",
  },
  {
    DynamicOptionsId: 96,
    DynamicOptionsName: "Spa or Massage",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 6,
    ModifiedBy: 1,
    ModifiedDate: "2020-10-17T16:19:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "This can be used in a spa or massage parlour",
    Picture: "-1602951519.webp",
  },
  {
    DynamicOptionsId: 56,
    DynamicOptionsName: "Space",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 18,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:32:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Space",
    Picture: "56-1635741143.webp",
  },
  {
    DynamicOptionsId: 57,
    DynamicOptionsName: "Sports",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 19,
    ModifiedBy: 1,
    ModifiedDate: "2019-02-26T02:17:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Sports",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1551147424.webp",
  },
  {
    DynamicOptionsId: 110,
    DynamicOptionsName: "Sports Wears & Accessories",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 29,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:54:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Sports Wears & Accessories",
    Picture: "-1610391236.webp",
  },
  {
    DynamicOptionsId: 168,
    DynamicOptionsName: "Suot sa Mata",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 20,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:50:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Suot sa Mata",
    Picture: "-1644465022.webp",
  },
  {
    DynamicOptionsId: 58,
    DynamicOptionsName: "Technology",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 20,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:33:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Technology",
    Picture: "58-1635741153.webp",
  },
  {
    DynamicOptionsId: 140,
    DynamicOptionsName: "Teknolohiya",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 20,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-09T14:03:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Teknolohiya",
    Picture: "-1644415366.webp",
  },
  {
    DynamicOptionsId: 150,
    DynamicOptionsName: "Teknolohiya ng Impormasyon",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 10,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:25:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/181755190767671",
    Picture: "-1644463472.webp",
  },
  {
    DynamicOptionsId: 187,
    DynamicOptionsName: "Telebisyon at Monitor",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 12,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:08:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Telebisyon at Monitor",
    Picture: "-1644466070.webp",
  },
  {
    DynamicOptionsId: 13,
    DynamicOptionsName: "Television and Monitors",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 12,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T17:28:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Television and Monitors",
    Picture: "-1610386106.webp",
  },
  {
    DynamicOptionsId: 194,
    DynamicOptionsName: "Tindahan ng Prutas o Gulay",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:14:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Tindahan ng Prutas o Gulay",
    Picture: "-1644466452.webp",
  },
  {
    DynamicOptionsId: 195,
    DynamicOptionsName: "Tindahan o Grocery",
    DynamicOptionTypeId: 15,
    DynamicOptionTypeName: "POSStoreUseSample",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:15:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Tindahan o Grocery",
    Picture: "-1644466489.webp",
  },
  {
    DynamicOptionsId: 157,
    DynamicOptionsName: "Transportasyon",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 14,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T03:28:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "https://www.facebook.com/groups/2124827537772234",
    Picture: "-1644463704.webp",
  },
  {
    DynamicOptionsId: 31,
    DynamicOptionsName: "Transportation",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 14,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:39:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/2124827537772234",
    Picture: "31-1635741552.webp",
  },
  {
    DynamicOptionsId: 59,
    DynamicOptionsName: "Travel or Vacation",
    DynamicOptionTypeId: 8,
    DynamicOptionTypeName: "BlogCategories",
    SortId: 21,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:33:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Travel or Vacation",
    Picture: "59-1635741163.webp",
  },
  {
    DynamicOptionsId: 75,
    DynamicOptionsName: "United Illuminating",
    DynamicOptionTypeId: 11,
    DynamicOptionTypeName: "Energy Source",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2019-10-09T09:53:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "United Illuminating",
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1570614763.webp",
  },
  {
    DynamicOptionsId: 35,
    DynamicOptionsName:
      "UrTechCity is there to give you the software solution you need",
    DynamicOptionTypeId: 7,
    DynamicOptionTypeName: "DailyTimeRecordBackgrounds",
    SortId: 1,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:33:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description:
      "We can guarantee a risk-free agreement if you acquire the solution from us",
    Picture: "35-1635741206.webp",
  },
  {
    DynamicOptionsId: 64,
    DynamicOptionsName: "Videoke",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2019-04-18T11:33:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: null,
    Picture: "b7dd361f-7c04-4a4f-8b41-302cada7e8b0-1555587187.webp",
  },
  {
    DynamicOptionsId: 188,
    DynamicOptionsName: "Videoke",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 4,
    ModifiedBy: 1,
    ModifiedDate: "2022-02-10T04:08:00.000Z",
    LanguageCode: "tl",
    LanguageName: "Tagalog",
    Description: "Videoke",
    Picture: "-1644466090.webp",
  },
  {
    DynamicOptionsId: 9,
    DynamicOptionsName: "Vitamins/Supplements",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 7,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T17:55:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Vitamins/Supplements",
    Picture: "-1610385648.webp",
  },
  {
    DynamicOptionsId: 103,
    DynamicOptionsName: "Washers & Parts",
    DynamicOptionTypeId: 9,
    DynamicOptionTypeName: "POSItemType",
    SortId: 22,
    ModifiedBy: 1,
    ModifiedDate: "2021-01-11T18:20:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "Washers & Parts",
    Picture: "-1610389192.webp",
  },
  {
    DynamicOptionsId: 15,
    DynamicOptionsName: "Writers",
    DynamicOptionTypeId: 5,
    DynamicOptionTypeName: "Field",
    SortId: 2,
    ModifiedBy: 1,
    ModifiedDate: "2021-11-01T04:39:00.000Z",
    LanguageCode: "en",
    LanguageName: "English",
    Description: "https://www.facebook.com/groups/681731942207377",
    Picture: "15-1635741566.webp",
  },
];
