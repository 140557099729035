import React, { Fragment, useEffect } from "react";
import {
  CFW_CLIENT_COUNTRY_DECLINED,
  DIALOGHOLDER,
  Response_CountryData,
} from "../generic/CFW.js";
import { GetVisitorCountry } from "../generic/Common.js";
import { MaintainDialog } from "../generic/PFW.js";
import { GetStorage, SetStorage } from "../generic/SFW.js";
import { SetState } from "../generic/StoreToolkit.js";
import { useQuery } from "@tanstack/react-query";
import { User } from "../generic/User.js";
const CountryPermission = () => {
  const { data: countryList } = useQuery({
    queryKey: [Response_CountryData],
  });
  SetState(Response_CountryData, countryList);
  const GetCountryVisitor = async () => {
    const permissionStatus = await navigator?.permissions?.query({
      name: "geolocation",
    });
    if (
      permissionStatus.state.toUpperCase() === "PROMPT" &&
      !GetStorage(CFW_CLIENT_COUNTRY_DECLINED)
    ) {
      const dialogParam = { ...MaintainDialog };
      dialogParam.msg = "689";
      dialogParam.title = "645";
      window.ncb = () => {
        SetStorage(CFW_CLIENT_COUNTRY_DECLINED, "1");
      };
      window.pcb = () => {
        GetVisitorCountry(true);
      };
      dialogParam.shownb = true;
      SetState(DIALOGHOLDER, dialogParam);
    } else if (permissionStatus.state.toUpperCase() === "GRANTED") {
      GetVisitorCountry(true);
    }
  };
  useEffect(() => {
    document.addEventListener("readystatechange", (event) => {
      if (event.target.readyState === "complete") {
        if (navigator?.geolocation && !User.SelectedCountry()) {
          GetCountryVisitor();
        }
      }
    });
    return function cleanup() {};
  }, []);
  return <Fragment />;
};
export default CountryPermission;
