import React, { lazy } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import {
  PRODUCTCODES_JOBPORTAL,
  PRODUCTCODES_UPUBLISHME,
  PRODUCTCODES_URTECHCITY,
  PRODUCTCODES_URTECHPOGO,
  PRODUCTCODES_URTECHPOS,
  PRODUCTCODES_URTECHSHOP,
} from "../../../common/js/generic/CFW.js";
import {
  ConstructPath,
  GetProductAndLanguage,
} from "../../../common/js/generic/Common.js";
import { User } from "../../../common/js/generic/User.js";
const Home = lazy(() =>
  import(/* webpackChunkName: "Home" */ "../../Components/Home/page.js")
);
const ProductList = lazy(() =>
  import(
    /* webpackChunkName: "ProductList" */ "../../../common/js/controls/ProductList.js"
  )
);
const LinkedInCallBack = lazy(() =>
  import(
    /* webpackChunkName: "LinkedInCallBack" */ "../../Components/LinkedInCallBack/page.js"
  )
);
const AddPoints = lazy(() =>
  import(
    /* webpackChunkName: "AddPoints" */ "../../Components/AddPoints/page.js"
  )
);
const AdsenseEarnings = lazy(() =>
  import(
    /* webpackChunkName: "AdsenseEarnings" */ "../../Components/AdsenseEarnings/page.js"
  )
);
const Categories = lazy(() =>
  import(
    /* webpackChunkName: "Categories" */ "../../Components/Categories/page.js"
  )
);
const ClientProfile = lazy(() =>
  import(
    /* webpackChunkName: "ClientProfile" */ "../../Components/ClientProfile/page.js"
  )
);
const CompanyProfile = lazy(() =>
  import(
    /* webpackChunkName: "CompanyProfile" */ "../../Components/CompanyProfile/page.js"
  )
);
const ConfirmPayment = lazy(() =>
  import(
    /* webpackChunkName: "ConfirmPayment" */ "../../Components/ConfirmPayment/page.js"
  )
);
const ContactUs = lazy(() =>
  import(
    /* webpackChunkName: "ContactUs" */ "../../Components/ContactUs/page.js"
  )
);
const EmailBlaster = lazy(() =>
  import(
    /* webpackChunkName: "EmailBlaster" */ "../../Components/EmailBlaster/page.js"
  )
);
const Employees = lazy(() =>
  import(
    /* webpackChunkName: "Employees" */ "../../Components/Employees/page.js"
  )
);
const OurServicesList = lazy(() =>
  import(
    /* webpackChunkName: "OurServicesList" */ "../../Components/Home/OurServicesList.js"
  )
);
const MaintainPayment = lazy(() =>
  import(
    /* webpackChunkName: "MaintainPayment" */ "../../Components/MaintainPayment/page.js"
  )
);
const Messages = lazy(() =>
  import(/* webpackChunkName: "Messages" */ "../../Components/Messages/page.js")
);
const NoMatch = lazy(() =>
  import(/* webpackChunkName: "NoMatch" */ "../../Components/NoMatch/page.js")
);
const OurClients = lazy(() =>
  import(
    /* webpackChunkName: "OurClients" */ "../../Components/OurClients/page.js"
  )
);
const OurPeople = lazy(() =>
  import(
    /* webpackChunkName: "OurPeople" */ "../../Components/OurPeople/page.js"
  )
);
const Payments = lazy(() =>
  import(/* webpackChunkName: "Payments" */ "../../Components/Payments/page.js")
);
const PrivacyPolicy = lazy(() =>
  import(
    /* webpackChunkName: "PrivacyPolicy" */ "../../Components/PrivacyPolicy/page.js"
  )
);
const ResetPassword = lazy(() =>
  import(
    /* webpackChunkName: "ResetPassword" */ "../../Components/ResetPassword/page.js"
  )
);
const Services = lazy(() =>
  import(/* webpackChunkName: "Services" */ "../../Components/Services/page.js")
);
const TranslationType = lazy(() =>
  import(
    /* webpackChunkName: "TranslationType" */ "../Company/TranslationType/page.js"
  )
);
const UserGuide = lazy(() =>
  import(
    /* webpackChunkName: "UserGuide" */ "../../Components/UserGuide/page.js"
  )
);
const Banners = lazy(() =>
  import(/* webpackChunkName: "Banners" */ "../Company/Banners/page.js")
);
const ClientLogos = lazy(() =>
  import(/* webpackChunkName: "ClientLogos" */ "../Company/ClientLogos/page.js")
);
const DynamicOptionType = lazy(() =>
  import(
    /* webpackChunkName: "DynamicOptionType" */ "../Company/DynamicOptionType/page.js"
  )
);
const DynamicOptions = lazy(() =>
  import(
    /* webpackChunkName: "DynamicOptions" */ "../Company/DynamicOptions/page.js"
  )
);
const OurServices = lazy(() =>
  import(/* webpackChunkName: "OurServices" */ "../Company/OurServices/page.js")
);
const Products = lazy(() =>
  import(/* webpackChunkName: "Products" */ "../Company/Products/page.js")
);
const RecommendUsList = lazy(() =>
  import(
    /* webpackChunkName: "RecommendUsList" */ "../Company/RecommendUsList/page.js"
  )
);
const SiteMap = lazy(() =>
  import(/* webpackChunkName: "SiteMap" */ "../Company/SiteMap/page.js")
);
const UserStatusRole = lazy(() =>
  import(
    /* webpackChunkName: "UserStatusRole" */ "../Company/UserStatusRole/page.js"
  )
);
const DailyTimeRecord = lazy(() =>
  import(
    /* webpackChunkName: "DailyTimeRecord" */ "../JobPortal/DailyTimeRecord/page.js"
  )
);
const Education = lazy(() =>
  import(/* webpackChunkName: "Education" */ "../JobPortal/Education/page.js")
);
const EmployeeTaskLog = lazy(() =>
  import(
    /* webpackChunkName: "EmployeeTaskLog" */ "../JobPortal/EmployeeTaskLog/page.js"
  )
);
const FindJobs = lazy(() =>
  import(/* webpackChunkName: "FindJobs" */ "../JobPortal/FindJobs/page.js")
);
const FindProfile = lazy(() =>
  import(
    /* webpackChunkName: "FindProfile" */ "../JobPortal/FindProfile/page.js"
  )
);
const JobProfile = lazy(() =>
  import(/* webpackChunkName: "JobProfile" */ "../JobPortal/JobProfile/page.js")
);
const JobsApplied = lazy(() =>
  import(
    /* webpackChunkName: "JobsApplied" */ "../JobPortal/JobsApplied/page.js"
  )
);
const OtherCredentials = lazy(() =>
  import(
    /* webpackChunkName: "OtherCredentials" */ "../JobPortal/OtherCredentials/page.js"
  )
);
const Portfolios = lazy(() =>
  import(/* webpackChunkName: "Portfolios" */ "../JobPortal/Portfolios/page.js")
);
const JobPostsSkills = lazy(() =>
  import(
    /* webpackChunkName: "JobPostsSkills" */ "../JobPortal/PostJobs/jobPostsSkills.js"
  )
);
const PostJobs = lazy(() =>
  import(/* webpackChunkName: "PostJobs" */ "../JobPortal/PostJobs/page.js")
);
const TaskLog = lazy(() =>
  import(/* webpackChunkName: "TaskLog" */ "../JobPortal/TaskLog/page.js")
);
const UserLanguage = lazy(() =>
  import(
    /* webpackChunkName: "UserLanguage" */ "../JobPortal/UserLanguage/page.js"
  )
);
const UserSkills = lazy(() =>
  import(/* webpackChunkName: "UserSkills" */ "../JobPortal/UserSkills/page.js")
);
const ViewBusinessProfile = lazy(() =>
  import(
    /* webpackChunkName: "ViewBusinessProfile" */ "../JobPortal/ViewBusinessProfile/page.js"
  )
);
const ViewJobPost = lazy(() =>
  import(
    /* webpackChunkName: "ViewJobPost" */ "../JobPortal/ViewJobPost/page.js"
  )
);
const ViewMyCV = lazy(() =>
  import(/* webpackChunkName: "ViewMyCV" */ "../JobPortal/ViewMyCV/page.js")
);
const WorkExperience = lazy(() =>
  import(
    /* webpackChunkName: "WorkExperience" */ "../JobPortal/WorkExperience/page.js"
  )
);
const LotCart = lazy(() =>
  import(/* webpackChunkName: "LotCart" */ "../POGO/LotCart/page.js")
);
const LotPayments = lazy(() =>
  import(/* webpackChunkName: "LotPayments" */ "../POGO/LotPayments/page.js")
);
const LotteryGameList = lazy(() =>
  import(
    /* webpackChunkName: "LotteryGameList" */ "../POGO/LotteryGameList/page.js"
  )
);
const MaintainLotDraw = lazy(() =>
  import(
    /* webpackChunkName: "MaintainLotDraw" */ "../POGO/MaintainLotDraw/page.js"
  )
);
const MaintainLotteryGames = lazy(() =>
  import(
    /* webpackChunkName: "MaintainLotteryGames" */ "../POGO/MaintainLotteryGames/page.js"
  )
);
const Expenses = lazy(() =>
  import(/* webpackChunkName: "Expenses" */ "../POS/Expenses/page.js")
);
const Inventory = lazy(() =>
  import(/* webpackChunkName: "Inventory" */ "../POS/Inventory/page.js")
);
const Items = lazy(() =>
  import(/* webpackChunkName: "Items" */ "../POS/Items/page.js")
);
const MyCart = lazy(() =>
  import(/* webpackChunkName: "MyCart" */ "../POS/MyCart/page.js")
);
const POS = lazy(() =>
  import(/* webpackChunkName: "POS" */ "../POS/POS/page.js")
);
const Packages = lazy(() =>
  import(/* webpackChunkName: "Packages" */ "../POS/Packages/page.js")
);
const ResellerPackageItems = lazy(() =>
  import(
    /* webpackChunkName: "ResellerPackageItems" */ "../POS/ResellerPackageItems/page.js"
  )
);
const Sales = lazy(() =>
  import(/* webpackChunkName: "Sales" */ "../POS/Sales/page.js")
);
const Suppliers = lazy(() =>
  import(/* webpackChunkName: "Suppliers" */ "../POS/Suppliers/page.js")
);
const ViewItem = lazy(() =>
  import(/* webpackChunkName: "ViewItem" */ "../POS/ViewItem/page.js")
);
const ViewOrder = lazy(() =>
  import(/* webpackChunkName: "ViewOrder" */ "../POS/ViewOrder/page.js")
);
const SearchShops = lazy(() =>
  import(/* webpackChunkName: "SearchShops" */ "../Shopper/SearchShops/page.js")
);
const FindContent = lazy(() =>
  import(
    /* webpackChunkName: "FindContent" */ "../UPublishMe/FindContent/page.js"
  )
);
const MaintainAllContents = lazy(() =>
  import(
    /* webpackChunkName: "MaintainAllContents" */ "../UPublishMe/MaintainAllContents/page.js"
  )
);
const MaintainContents = lazy(() =>
  import(
    /* webpackChunkName: "MaintainContents" */ "../UPublishMe/MaintainContents/page.js"
  )
);
const ViewContent = lazy(() =>
  import(
    /* webpackChunkName: "ViewContent" */ "../UPublishMe/ViewContent/page.js"
  )
);
const SignIn = lazy(() =>
  import(/* webpackChunkName: "SignIn" */ "../../Components/SignIn/page.js")
);
const UpdateProfile = lazy(() =>
  import(
    /* webpackChunkName: "UpdateProfile" */ "../../Components/UpdateProfile/page.js"
  )
);

const PageSelector = ({ roles }) => {
  const location = useLocation();
  const { languageCode, productCode } = GetProductAndLanguage(
    location.pathname
  );
  const collectedRoutes = [
    {
      path: ConstructPath(
        languageCode,
        productCode,
        "auth/linkedin/callback",
        true
      ),
      element: <LinkedInCallBack />,
    },
  ];
  const getRouter = (paramPath, paramElement) => {
    let param = productCode.toLowerCase();
    if (paramPath === "*") {
      collectedRoutes.push({ path: paramPath, element: paramElement });
    } else {
      [
        `/${paramPath}`,
        `/${param}/${paramPath}`,
        `/${param}/${languageCode}/${paramPath}`,
      ].forEach((urlLink) => {
        collectedRoutes.push({ path: urlLink, element: paramElement });
      });
    }
  };
  getRouter("signin", <SignIn />);
  getRouter("", <Home />);
  getRouter("Home", <Home />);
  getRouter("resetpassword", <ResetPassword />);
  getRouter("companyprofile", <CompanyProfile />);
  getRouter("privacypolicy", <PrivacyPolicy />);
  getRouter("ourclients", <OurClients />);
  getRouter("ourpeople", <OurPeople />);
  getRouter("contactus", <ContactUs />);
  getRouter("services/:serviceId", <Services />);
  getRouter("ourserviceslist", <OurServicesList />);
  getRouter("productlist", <ProductList />);
  getRouter("UserGuide", <UserGuide />);
  getRouter("message", <Messages />);
  getRouter("updateprofile", <UpdateProfile />);
  getRouter("Categories/:categoryId", <Categories />);
  getRouter("ViewItem/:ResellerPackageItemGUID", <ViewItem />);
  getRouter("ViewBusinessProfile/:clientid", <ViewBusinessProfile />);
  if (productCode === PRODUCTCODES_URTECHCITY && User.IsTranslator(roles)) {
    getRouter("TranslationType", <TranslationType />);
  }
  if (productCode === PRODUCTCODES_URTECHCITY && User.IsSuperAdmin(roles)) {
    getRouter("SiteMap", <SiteMap />);
  }
  if (User.IsSuperAdmin(roles) || User.IsAdmin(roles)) {
    getRouter("EmailBlaster", <EmailBlaster />);
  }
  if (
    User.IsSuperAdmin(roles) ||
    User.IsAgency(roles) ||
    User.IsAdmin(roles) ||
    User.IsCompanyOwner(roles)
  ) {
    getRouter("ClientProfile", <ClientProfile />);
  }
  if (User.IsCompanyOwner(roles) || User.IsAgency(roles)) {
    getRouter("Employees", <Employees />);
  }
  if (
    productCode === PRODUCTCODES_URTECHCITY &&
    (User.IsAdmin(roles) || User.IsSuperAdmin(roles))
  ) {
    getRouter("banners", <Banners />);
    getRouter("clientLogos", <ClientLogos />);
    getRouter("DynamicOptionType", <DynamicOptionType />);
    getRouter("DynamicOptions", <DynamicOptions />);
    getRouter("OurServices", <OurServices />);
    getRouter("Products", <Products />);
    getRouter("UserStatusRole", <UserStatusRole />);
  }
  if (
    productCode === PRODUCTCODES_URTECHPOS &&
    (User.IsAdmin(roles) || User.IsAgency(roles) || User.IsCompanyOwner(roles))
  ) {
    getRouter("Suppliers", <Suppliers />);
    getRouter("Items", <Items />);
    getRouter("Inventory", <Inventory />);
    getRouter("Packages", <Packages />);
    getRouter("Expenses", <Expenses />);
    getRouter("Sales", <Sales />);
  }
  if (productCode === PRODUCTCODES_URTECHPOS && User.IsReseller(roles)) {
    getRouter("ResellerPackageItems", <ResellerPackageItems />);
  }
  if (
    (productCode === PRODUCTCODES_URTECHPOS ||
      productCode === PRODUCTCODES_URTECHSHOP) &&
    User.IsReseller(roles)
  ) {
    getRouter("ViewOrder", <ViewOrder />);
  }
  if (
    productCode === PRODUCTCODES_URTECHPOS &&
    (User.IsTeamLeader(roles) ||
      User.IsAdmin(roles) ||
      User.IsAgency(roles) ||
      User.IsCompanyOwner(roles))
  ) {
    getRouter("POS", <POS />);
  }
  if (User.IsLoggedIn(roles)) {
    getRouter("RecommendUsList", <RecommendUsList />);
    getRouter("MyCart", <MyCart />);
    getRouter("Payments", <Payments />);
    getRouter("AddPoints", <AddPoints />);
    getRouter("ConfirmPayment/:paymentTypeId", <ConfirmPayment />);
    getRouter(
      "DailyTimeRecord/:clientId",
      <DailyTimeRecord languageCode={languageCode} productCode={productCode} />
    );
    getRouter(
      "DailyTimeRecord",
      <DailyTimeRecord languageCode={languageCode} productCode={productCode} />
    );
    getRouter("AdsenseEarnings", <AdsenseEarnings />);
  }
  if (productCode === PRODUCTCODES_UPUBLISHME) {
    getRouter("FindContent/:categoryId", <FindContent />);
    getRouter("FindContent", <FindContent />);
    getRouter("ViewContent/:contentGUID", <ViewContent />);
    if (User.IsLoggedIn(roles)) {
      getRouter("MaintainContents", <MaintainContents />);
    }
  }
  if (productCode === PRODUCTCODES_UPUBLISHME && User.IsSuperAdmin(roles)) {
    getRouter("MaintainAllContents", <MaintainAllContents />);
  }
  if (User.IsSuperAdmin(roles)) {
    getRouter("MaintainPayment", <MaintainPayment />);
  }
  if (
    productCode === PRODUCTCODES_JOBPORTAL &&
    User.IsLoggedIn(roles) &&
    (User.IsNormalUser(roles) || User.IsTeamLeader(roles))
  ) {
    getRouter("JobProfile", <JobProfile />);
    getRouter("UserSkills", <UserSkills />);
    getRouter("UserLanguage", <UserLanguage />);
    getRouter("Portfolios", <Portfolios />);
    getRouter("OtherCredentials", <OtherCredentials />);
    getRouter("Education", <Education />);
    getRouter("JobsApplied", <JobsApplied />);
    getRouter("WorkExperience", <WorkExperience />);
    getRouter("TaskLog", <TaskLog languageCode={languageCode} />);
  }
  if (productCode === PRODUCTCODES_JOBPORTAL) {
    getRouter("ViewMyCV/:userguid", <ViewMyCV />);
    getRouter("ViewJobPost/:postId", <ViewJobPost />);
    getRouter("FindJobs", <FindJobs />);
    getRouter("JobProfile", <JobProfile />);
  }
  if (
    productCode === PRODUCTCODES_JOBPORTAL &&
    (User.IsCompanyOwner(roles) || User.IsAgency(roles))
  ) {
    getRouter("PostJobs", <PostJobs />);
    getRouter("JobPostsSkills/:postId", <JobPostsSkills />);
    getRouter("FindProfile", <FindProfile />);
    getRouter(
      "EmployeeTaskLog",
      <EmployeeTaskLog languageCode={languageCode} productCode={productCode} />
    );
  }
  if (productCode === PRODUCTCODES_URTECHSHOP) {
    getRouter("SearchShops", <SearchShops />);
  }
  if (productCode === PRODUCTCODES_URTECHPOGO && User.IsSuperAdmin(roles)) {
    getRouter("MaintainLotteryGames", <MaintainLotteryGames />);
    getRouter("MaintainLotDraw", <MaintainLotDraw />);
  }
  if (productCode === PRODUCTCODES_URTECHPOGO) {
    getRouter("LotCart", <LotCart />);
    getRouter("LotPayments", <LotPayments />);
    getRouter("LotteryGameList/:countryid", <LotteryGameList />);
  }
  getRouter("*", <NoMatch />);
  const RouteCollection = () => useRoutes(collectedRoutes);
  return <RouteCollection />;
};
export default PageSelector;
