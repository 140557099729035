import React, { Fragment } from "react";
import { API_URL } from "../../../common/data/GlobalData.js";
import { DataText } from "../controls/DataEntry/DataEntry.js";
import ImageCard from "../controls/ImageCard.js";
import Label from "../controls/Label.js";
import {
  DIALOGHOLDER,
  PROCESS_DELETE,
  PROCESS_EDIT,
  Response_FileDowloadData,
} from "./CFW.js";
import { Clone, ExtractItemState, GUID, MatchingFieldsOnly } from "./Common.js";
import { MaintainDialog, MaintainFileDownload } from "./PFW.js";
import { Encrypt, FileUpload, GetConfig } from "./SFW.js";
import { GetState, GetStateField, SetState } from "./StoreToolkit.js";

const FileData = GUID();
export const Folders = {
  ContentVideo: "ContentVideo",
  ContentPicture: "ContentPicture",
  ContentSound: "ContentSound",
  SiteMapImage: "SiteMapImage",

  ContentDocument: "ContentDocument",
  SiteMap: "SiteMap",
  ProfilePic: "ProfilePic",
  ItemPic: "ItemPic",
  ResellerPackageItemPic: "ResellerPackageItemPic",
  BannerPic: "BannerPic",
  DynamicOptionPic: "DynamicOptionPic",
  POGOPic: "POGOPic",
  ProductPic: "ProductPic",
  ClientProfileFiles: "ClientProfileFiles",
  EducationFiles: "EducationFiles",
  JobPostFiles: "JobPostFiles",
  OtherCredentialFiles: "OtherCredentialFiles",
  PortfolioFiles: "PortfolioFiles",
  JobProfileFiles: "JobProfileFiles",
  ClientLogoPic: "ClientLogoPic",
  ServicePic: "ServicePic",
};
export const UploadFileUrl = (
  blobFile,
  fileName,
  uploadUrl,
  headers,
  postEvt
) => {
  if (blobFile && fileName && uploadUrl) {
    headers.encryptedUrl = Encrypt(uploadUrl);
    const data = { blobFile, fileName, headers };
    FileUpload(
      [API_URL, "UploadFileUrl"].join(""),
      data,
      FileData,
      postEvt,
      headers
    );
  }
};
const UploadFile = (blobFile, folder, fileName, postEvt) => {
  if (blobFile && fileName) {
    const data = { blobFile, fileName };
    FileUpload(
      [API_URL, "UploadFile/", fileName, "/", folder].join(""),
      data,
      FileData,
      postEvt
    );
  }
};
const Download = (curUrl, fileName, attachmentType) => {
  const fileDownLoadParam = Clone(MaintainFileDownload);
  fileDownLoadParam.FileName = fileName;
  fileDownLoadParam.Url = curUrl;
  fileDownLoadParam.AttachmentTypeId = attachmentType;
  SetState(Response_FileDowloadData, fileDownLoadParam);
};
export const GetAttachmentIcon = ({
  state,
  name,
  item,
  isreadonly = false,
  stateList,
  getUrlPathEvt,
  param,
}) => {
  let ext;
  const imageUrlPath =
    typeof item.UrlPathOrFileName === "string" &&
    ![2, 6].includes(parseInt(item.AttachmentTypeId, 0))
      ? getUrlPathEvt(item.UrlPathOrFileName)
      : item.UrlPathOrFileName?.fileName ?? item.UrlPathOrFileName;

  const indexedDBFile = GetState(imageUrlPath);
  const urlPathOrFileName =
    item.UrlPathOrFileName?.fileName ?? item.UrlPathOrFileName;
  if ([2, 6].includes(item.AttachmentTypeId)) {
    ext = urlPathOrFileName
      .substring(0, urlPathOrFileName.indexOf(":"))
      .toUpperCase();
  } else {
    if (item.UrlPathOrFileName?.blob) {
      switch (parseInt(item.AttachmentTypeId, 0)) {
        case 1:
          ext = "Video";
          break;
        case 3:
          ext = "Sound";
          break;
        case 4:
          ext = "Image";
          break;
        case 5:
          ext = "Document";
          break;
        case 7:
          ext = "Pdf";
          break;
        default:
          ext = "Others";
          break;
      }
    } else {
      ext = urlPathOrFileName
        .substring(urlPathOrFileName.lastIndexOf(".") + 1)
        .toUpperCase();
    }
  }
  const DoAction = (curVal) => {
    SetState(state, MatchingFieldsOnly(item, param));
    const processEvt = () => {
      const { currentList } = ExtractItemState(stateList, "_id", item?._id);
      item.Process = item?.Process ?? PROCESS_EDIT;
      item.AttachmentName = GetStateField(state, "AttachmentName");
      currentList.push(item);
      SetState(stateList, currentList);
    };
    const deleteEvent = () => {
      const { currentList } = ExtractItemState(stateList, "_id", item?._id);
      item.Process = PROCESS_DELETE;
      currentList.push(item);
      SetState(stateList, currentList);
    };
    const dialogParam = { ...MaintainDialog };
    switch (curVal) {
      case 0:
        Download(
          indexedDBFile ?? imageUrlPath ?? item.UrlPathOrFileName,
          item.AttachmentName,
          item.AttachmentTypeId
        );
        break;
      case 1:
        window.children = (
          <DataText
            state={state}
            name={name}
            tran="438"
            className="form-control underlineInput"
            maxLength="200"
            required
            defaultValue={item.AttachmentName}
          />
        );
        window.pcb = processEvt;
        dialogParam.shownb = true;
        SetState(DIALOGHOLDER, dialogParam);
        break;
      case 2:
        dialogParam.msg = "432";
        dialogParam.title = "32";
        window.pcb = deleteEvent;
        dialogParam.shownb = true;
        SetState(DIALOGHOLDER, dialogParam);
        break;
      default:
        break;
    }
  };
  return (
    <ImageCard
      key={GUID()}
      attachmenttype={item.AttachmentTypeId}
      attachmentname={item.AttachmentName}
      type="attachment"
      usedirectimage="1"
      className="fileItem fileIcon breakAll contextMenu"
      title={item.AttachmentName}
    >
      <nav style={{ padding: "0px 7px" }}>
        <ul>
          <li style={{ borderRadius: "8px" }}>
            <button
              className="parent-button-context"
              style={{ minWidth: "120px" }}
            >
              {ext}
            </button>
            <ul style={{ backgroundColor: "#ffffff" }}>
              <li>
                <label
                  className="breakWord"
                  style={{ width: "140px", display: "inline-block" }}
                >
                  {item.AttachmentName}
                </label>
              </li>
              <li>
                <a
                  tabIndex="o"
                  className="download button-icon"
                  onClick={() => {
                    DoAction(0);
                  }}
                >
                  <Label tranVal="105" />
                </a>
              </li>
              {isreadonly ? (
                <Fragment />
              ) : (
                <Fragment>
                  <li>
                    <a
                      tabIndex="o"
                      className="pencil button-icon"
                      onClick={() => {
                        DoAction(1);
                      }}
                    >
                      <Label tranVal="438" />
                    </a>
                  </li>
                  <li>
                    <a
                      tabIndex="o"
                      className="trash button-icon"
                      onClick={() => {
                        DoAction(2);
                      }}
                    >
                      <Label tranVal="50" />
                    </a>
                  </li>
                </Fragment>
              )}
            </ul>
          </li>
        </ul>
      </nav>
    </ImageCard>
  );
};
export const ConvertBase64ToWebP = async (base64, fileName, is1152 = false) => {
  if (typeof window === "undefined" || !base64 || !fileName) {
    return;
  }
  return await new Promise(function (resolve) {
    let rawImage = new Image();
    rawImage.addEventListener("load", function () {
      resolve(rawImage);
    });
    rawImage.src = base64;
  }).then((rawImage) => {
    return new Promise(function (resolve) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let ratio = 1;
      const curWidth = is1152 ? 1152 : 800;
      const curHeight = is1152 ? 864 : 600;
      if (rawImage.width > curWidth || rawImage.height > curHeight) {
        ratio = Math.min(
          curWidth / rawImage.width,
          curHeight / rawImage.height
        );
      }
      rawImage.width = parseInt(rawImage.width * ratio);
      rawImage.height = parseInt(rawImage.height * ratio);
      canvas.width = rawImage.width;
      canvas.height = rawImage.height;
      ctx.drawImage(rawImage, 0, 0, rawImage.width, rawImage.height);
      canvas.toBlob(function (blob) {
        resolve(blob);
      }, "image/webp");
    }).then((blob) => {
      return {
        blob,
        fileName: `${fileName.substring(0, fileName.lastIndexOf("."))}.webp`,
      };
    });
  });
};
export const ConvertBase64ToPng = async (
  base64,
  fileName,
  shouldCompress = true
) => {
  if (typeof window === "undefined" || !base64 || !fileName) {
    return;
  }
  return await new Promise((resolve) => {
    let rawImage = new Image();
    rawImage.addEventListener("load", () => {
      resolve(rawImage);
    });
    rawImage.src = base64;
  }).then((rawImage) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (shouldCompress) {
        const curWidth = 1200;
        const curHeight = 630;
        const maxSize =
          rawImage.width > rawImage.height
            ? Math.abs(curWidth - rawImage.width)
            : Math.abs(curHeight - rawImage.height);
        rawImage.height = rawImage.height + maxSize;
        rawImage.width = rawImage.width + maxSize;
        canvas.width = curWidth;
        canvas.height = curHeight;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const dy = parseInt((canvas.height - rawImage.height) / 2, 10);
        const dx = parseInt((canvas.width - rawImage.width) / 2, 10);
        ctx.drawImage(rawImage, dx, dy, rawImage.width, rawImage.height);
      } else {
        canvas.width = rawImage.width;
        canvas.height = rawImage.height;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(rawImage, 0, 0, rawImage.width, rawImage.height);
      }
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    }).then((blob) => {
      return {
        blob,
        fileName: `${fileName.substring(0, fileName.lastIndexOf("."))}.png`,
      };
    });
  });
};
export const GetFileName = (userGUID, fileName) => {
  return [
    userGUID,
    "-",
    Math.floor(Date.now() / 1000),
    ".",
    fileName.substring(fileName.lastIndexOf(".") + 1),
  ].join("");
};
export const Base64toBlob = (base64Data, contentType, fileName) => {
  if (base64Data instanceof Blob) {
    return Blob;
  } else {
    contentType = [
      contentType,
      "/",
      fileName.substring(fileName.lastIndexOf(".") + 1),
    ].join("");
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
};
export const ConvertFileToBase64 = async (file) => {
  const toBase64 = (p) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(p);
      reader.onload = () =>
        resolve({ base64: reader.result, name: file.name, size: file.size });
      reader.onerror = () => reject(null);
    });
  const rslts = await toBase64(file);
  return rslts;
};
export const ConvertUrlToBlob = (paramUrl, postEvent) => {
  ConvertUrlToBase64(paramUrl, (base64Results) => {
    const results = {
      blob: Base64toBlob(
        base64Results.base64.substring(base64Results.base64.indexOf(",") + 1),
        "image",
        base64Results.name
      ),
      fileName: base64Results.name,
      base64: base64Results.base64,
    };
    postEvent(results);
  });
};
export const ConvertUrlToBase64 = (url, postEvt, fName = null) => {
  if (typeof window === "undefined") {
    return;
  }
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.addEventListener("load", async () => {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL("image/png");
    postEvt({
      base64: dataURL,
      name: fName ? fName : url.substring(url.lastIndexOf("/") + 1),
      size: 1000,
    });
  });
  img.src = url;
};
export const GetJobProfileFiles = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.JobProfileFiles, "/", filename].join(
      ""
    );
  } else {
    return filename;
  }
};
export const GetContentPictureFiles = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.ContentPicture, "/", filename].join(
      ""
    );
  } else {
    return filename;
  }
};
export const GetSiteMapImage = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.includes(GetConfig("FileUrl"))) {
    return filename;
  }
  return [GetConfig("FileUrl"), Folders.SiteMapImage, "/", filename].join("");
};
export const GetPortfolioFiles = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.PortfolioFiles, "/", filename].join(
      ""
    );
  } else {
    return filename;
  }
};
export const GetOtherCredentialFiles = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [
      GetConfig("FileUrl"),
      Folders.OtherCredentialFiles,
      "/",
      filename,
    ].join("");
  } else {
    return filename;
  }
};
export const GetJobPostFiles = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.JobPostFiles, "/", filename].join("");
  } else {
    return filename;
  }
};
export const GetEducationFiles = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.EducationFiles, "/", filename].join(
      ""
    );
  } else {
    return filename;
  }
};
export const GetClientProfileFiles = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [
      GetConfig("FileUrl"),
      Folders.ClientProfileFiles,
      "/",
      filename,
    ].join("");
  } else {
    return filename;
  }
};
export const GetResellerPackageItemPic = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [
      GetConfig("FileUrl"),
      Folders.ResellerPackageItemPic,
      "/",
      filename,
    ].join("");
  } else {
    return filename;
  }
};
export const GetItemPic = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.ItemPic, "/", filename].join("");
  } else {
    return filename;
  }
};
export const GetProfilePic = (filename) => {
  if (!filename) {
    return GetConfig("NoProfilePic");
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.ProfilePic, "/", filename].join("");
  } else {
    return filename;
  }
};
export const GetBannerPic = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.BannerPic, "/", filename].join("");
  } else {
    return filename;
  }
};
export const GetDynamicOptionPic = (filename) => {
  if (!filename) {
    return filename;
  } else if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.DynamicOptionPic, "/", filename].join(
      ""
    );
  } else {
    return filename;
  }
};
export const GetPOGOPic = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.POGOPic, "/", filename].join("");
  } else {
    return filename;
  }
};
export const GetServicePic = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.ServicePic, "/", filename].join("");
  } else {
    return filename;
  }
};
export const GetProductPic = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.ProductPic, "/", filename].join("");
  } else {
    return filename;
  }
};
export const GetClientLogoPic = (filename) => {
  if (!filename) {
    return filename;
  }
  if (filename.length < 100) {
    if (filename.includes(GetConfig("FileUrl"))) {
      return filename;
    }
    return [GetConfig("FileUrl"), Folders.ClientLogoPic, "/", filename].join(
      ""
    );
  } else {
    return filename;
  }
};
export const UploadContentSound = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ContentSound, fileName, postEvt);
};
export const UploadContentDocument = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ContentDocument, fileName, postEvt);
};
export const UploadSiteMap = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.SiteMap, fileName, postEvt);
};
export const UploadContentVideo = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ContentVideo, fileName, postEvt);
};
export const UploadContentPicture = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ContentPicture, fileName, postEvt);
};
export const UploadSiteMapImage = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.SiteMapImage, fileName, postEvt);
};
export const UploadProfilePic = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ProfilePic, fileName, postEvt);
};
export const UploadBannerPic = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.BannerPic, fileName, postEvt);
};
export const UploadDynamicOptionPic = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.DynamicOptionPic, fileName, postEvt);
};
export const UploadItemPic = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ItemPic, fileName, postEvt);
};
export const UploadResellerPackageItemPic = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ResellerPackageItemPic, fileName, postEvt);
};
export const UploadProductPic = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ProductPic, fileName, postEvt);
};
export const UploadPOGOPic = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.POGOPic, fileName, postEvt);
};
export const UploadServicePic = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ServicePic, fileName, postEvt);
};
export const UploadClientProfileFiles = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ClientProfileFiles, fileName, postEvt);
};
export const UploadEducationFiles = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.EducationFiles, fileName, postEvt);
};
export const UploadJobPostFiles = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.JobPostFiles, fileName, postEvt);
};
export const UploadOtherCredentialFiles = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.OtherCredentialFiles, fileName, postEvt);
};
export const UploadPortfolioFiles = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.PortfolioFiles, fileName, postEvt);
};
export const UploadJobProfileFile = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.JobProfileFiles, fileName, postEvt);
};
export const UploadClientLogoPic = (blobFile, fileName, postEvt) => {
  UploadFile(blobFile, Folders.ClientLogoPic, fileName, postEvt);
};
