import { Fragment, default as React, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CFW_SCROLL_COUNTER,
  CFW_SCROLL_COUNTER_PROCESSED,
} from "../generic/CFW.js";
import { GetResults } from "../generic/SFW.js";
import { GetState, RemoveState, SetState } from "../generic/StoreToolkit.js";
export const ScrollEvents = () => {
  const RemoveScrollStorages = () => {
    RemoveState(CFW_SCROLL_COUNTER);
    RemoveState(CFW_SCROLL_COUNTER_PROCESSED);
    window.removeEventListener("scroll", IncrementCounter, false);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    RemoveScrollStorages();
    window.addEventListener("scroll", IncrementCounter);
    return function cleanup() {};
  }, []);
  const IncrementCounter = () => {
    const counterProcessed = GetState(CFW_SCROLL_COUNTER_PROCESSED, 0);
    let currentCounter = GetState(CFW_SCROLL_COUNTER, 0);
    if (!currentCounter || counterProcessed + 10 >= currentCounter) {
      currentCounter++;
      SetState(CFW_SCROLL_COUNTER, currentCounter);
    }
  };
  return <Fragment />;
};
export const ScrollSuspense = ({ scrollcounter, children, style }) => {
  const counter = useSelector((state) => GetResults(state[CFW_SCROLL_COUNTER]));
  const RenderLoader = () => <div style={style} className="loading" />;
  useEffect(() => {
    if (scrollcounter > GetState(CFW_SCROLL_COUNTER_PROCESSED, 0)) {
      SetState(CFW_SCROLL_COUNTER_PROCESSED, Math.max(scrollcounter));
    }
    return function cleanup() {};
  }, [scrollcounter]);
  return counter >= scrollcounter ? (
    <Fragment>{children}</Fragment>
  ) : (
    <RenderLoader />
  );
};
