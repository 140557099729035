import React, { lazy, memo, Suspense } from "react";
const ShowResponsiveAds = lazy(() =>
  import(/* webpackChunkName: "ShowResponsiveAds" */ "./ShowResponsiveAds.js")
);
const ResponsiveAds = ({ slot = null, format = "auto", adsIdx = 0 }) => {
  return (
    <Suspense fallback={<div className="loading responsiveAds" />}>
      <ShowResponsiveAds slot={slot} format={format} adsIdx={adsIdx} />
    </Suspense>
  );
};
export default memo(ResponsiveAds);
