import { CFW_ACCESSTOKEN, CFW_CLIENT_COUNTRY, CFW_CONTACT_NUMBER, CFW_CountryId, CFW_EMAIL, CFW_FACEBOOKID, CFW_FullName, CFW_GOOGLEID, CFW_LINKEDINID, CFW_LOGINFO, CFW_PARENTPUBLISHERID, CFW_POINTS, CFW_PUBLISHERID, CFW_ROLES, CFW_TWITTERID, CFW_USERGUID, Role_Admin, Role_Agency, Role_CompanyOwner, Role_Driver, Role_NormalUser, Role_OpratorOrMarketer, Role_Reseller, Role_SuperAdmin, Role_TeamLeader, Role_Translator } from "./CFW.js";
import { GetStorage, SetStorage, Decrypt } from "./SFW.js";
import Cookies from "js-cookie";
export const User = {
  GetUserInfo: val => {
    let usr = Cookies.get(CFW_LOGINFO);
    if (usr) {
      try {
        usr = JSON.parse(Decrypt(usr));
      } catch (e) {
        return null;
      }
      if (!usr) {
        return null;
      }
      return usr[val];
    }
    return null;
  },
  SelectedCountry: function (countryVal) {
    if (countryVal === void 0) {
      countryVal = null;
    }
    const selectedCountry = countryVal ?? Cookies.get(CFW_CLIENT_COUNTRY);
    if (selectedCountry) {
      return JSON.parse(Decrypt(selectedCountry));
    }
    return null;
  },
  IsLoggedIn: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return Cookies.get(CFW_LOGINFO) || (roles ?? "") ? true : false;
  },
  IsAdmin: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(i => parseInt(i, 10) === Role_Admin).length > 0;
  },
  IsSuperAdmin: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(i => parseInt(i, 10) === Role_SuperAdmin).length > 0;
  },
  IsCompanyOwner: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(i => parseInt(i, 10) === Role_CompanyOwner).length > 0;
  },
  IsAgency: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(i => parseInt(i, 10) === Role_Agency).length > 0;
  },
  IsTeamLeader: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(i => parseInt(i, 10) === Role_TeamLeader).length > 0;
  },
  IsNormalUser: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(i => parseInt(i, 10) === Role_NormalUser).length > 0;
  },
  IsTranslator: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(i => parseInt(i, 10) === Role_Translator).length > 0;
  },
  IsDriver: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(i => parseInt(i, 10) === Role_Driver).length > 0;
  },
  IsOperatorOrMarketer: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(i => parseInt(i, 10) === Role_OpratorOrMarketer).length > 0;
  },
  IsReseller: function (roles) {
    if (roles === void 0) {
      roles = "";
    }
    return [...(User.GetUserInfo(CFW_ROLES) || (roles ?? "")).split(",")].filter(i => parseInt(i, 10) === Role_Reseller).length > 0;
  },
  Roles: () => {
    return User.GetUserInfo(CFW_ROLES);
  },
  Email: () => {
    return User.GetUserInfo(CFW_EMAIL) || "";
  },
  ContactNumber: () => {
    return User.GetUserInfo(CFW_CONTACT_NUMBER) || "";
  },
  AccessToken: () => {
    const accessToken = User.GetUserInfo(CFW_ACCESSTOKEN) || GetStorage(CFW_ACCESSTOKEN);
    if (accessToken) {
      SetStorage(CFW_ACCESSTOKEN, accessToken);
    }
    return accessToken || "";
  },
  FullName: () => {
    return User.GetUserInfo(CFW_FullName) || "";
  },
  PublisherId: () => {
    return User.GetUserInfo(CFW_PUBLISHERID) || "";
  },
  Points: () => {
    return parseInt(User.GetUserInfo(CFW_POINTS) || "0", 10);
  },
  UserGUID: () => {
    return User.GetUserInfo(CFW_USERGUID) || "";
  },
  CountryId: () => {
    return User.GetUserInfo(CFW_CountryId) || "";
  },
  FacebookId: () => {
    return User.GetUserInfo(CFW_FACEBOOKID) ?? GetStorage(CFW_FACEBOOKID);
  },
  LinkedInId: () => {
    return User.GetUserInfo(CFW_LINKEDINID) ?? GetStorage(CFW_LINKEDINID);
  },
  GoogleId: () => {
    return User.GetUserInfo(CFW_GOOGLEID) ?? GetStorage(CFW_GOOGLEID);
  },
  TwitterId: () => {
    return User.GetUserInfo(CFW_TWITTERID) ?? GetStorage(CFW_TWITTERID);
  },
  ParentPublisherId: () => {
    return User.GetUserInfo(CFW_PARENTPUBLISHERID) || "";
  }
};