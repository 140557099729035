export const InitializeIndexedDBWorker = () => {
  const worker = new Worker(/* webpackChunkName: "IndexedDBWorker" */new URL("/IndexedDBWorker.js", import.meta.url), {
    type: "module",
    credentials: "same-origin"
  });
  const IndexedDBPostMessage = function (action, body, successEvent, errorEvent) {
    if (body === void 0) {
      body = null;
    }
    if (successEvent === void 0) {
      successEvent = () => {};
    }
    if (errorEvent === void 0) {
      errorEvent = () => {};
    }
    worker.onmessage = results => {
      if (successEvent) {
        successEvent(results.data);
      }
    };
    worker.onerror = results => {
      if (errorEvent) {
        errorEvent(results);
      }
    };
    worker.onmessageerror = results => {
      if (errorEvent) {
        errorEvent(results);
      }
    };
    worker.postMessage({
      action,
      body
    });
  };
  return IndexedDBPostMessage;
};