const ConfigData = {
  "Xmt|Fix": "ywzj",
  "Yns~RHJFunPj~": "q~j<w5sp57p>i>xyt=wrm}|lugn>=s9y}k{pzi7{:whsin}>",
  LttlqjNi: ":58:;6=65=5528l5fuwg;s8=yu<wgr>=n8th65nkiplh;3fuux3lttlqjzxjwhtsyjsy3htr",
  "LttlqjFunPj~": "FNfX~H[I}Ts;55hohjihhHLpyWFZ[=H|<Kpdg5",
  "LttlqjFixjsxjPj~": "FNfX~Iq[fLuvyGt5:pzZF[S8xtw6{2}qim;:tV",
  RfuWtzyj: "334zwyjhmhny~3stij",
  UwtizhyHtijx: "ZWYJHMHNY^1OTGUTWYFQ1ZUZGQNXMRJ1ZYWFHPSYWFHJRJ1ZWYJHMUTX1ZWYJHMXMTU1ZWYJHMUTLT",
  "Htrufs~FixjsxjNi": "=7;5558>==;8=;=5",
  "Htrufs~FiXqty": "9=<<<8;:::1965<:7=8;:18<<:7;=68:1686<:;6:8;",
  "Htrufs~FiXqtyRzqynuqj}": "7=77769=>9",
  "Yf|pYtNi": {
    js5: ";;kfkf9>j:>=7i;h<gg;h9j746n>7;8gpi",
    yq5: ";;kfkf9>j:>=7i;h<gg;h9j746n>7klvo{",
    ym5: ";;kfkf9>j:>=7i;h<gg;h9j746n>7knfjv",
    js6: ";;kfkf9>j:>=7i;h<gg;h9j746nls>zg>8",
    yq6: ";;kfkf9>j:>=7i;h<gg;h9j746nlu>go5t",
    ym6: ";;kfkf9>j:>=7i;h<gg;h9j746nlufv78=",
    js7: ";;kfkf9>j:>=7i;h<gg;h9j746nlugqffu",
    yq7: ";;kfkf9>j:>=7i;h<gg;h9j746nluh9fih",
    ym7: ";;kfkf9>j:>=7i;h<gg;h9j746nluhlo6r",
    js8: ";;kfkf9>j:>=7i;h<gg;h9j746nlukql=y",
    yq8: ";;kfkf9>j:>=7i;h<gg;h9j746nlul8:>v",
    ym8: ";;kfkf9>j:>=7i;h<gg;h9j746nlumgtow",
    js9: ";;kfkf9>j:>=7i;h<gg;h9j746nluijk9q",
    yq9: ";;kfkf9>j:>=7i;h<gg;h9j746nluj89r<",
    ym9: ";;kfkf9>j:>=7i;h<gg;h9j746nlujj:us",
    "js:": ";;kfkf9>j:>=7i;h<gg;h9j746nlumqrxo",
    "yq:": ";;kfkf9>j:>=7i;h<gg;h9j746nlun{>wm",
    "ym:": ";;kfkf9>j:>=7i;h<gg;h9j746nlurxu6y"
  },
  "Yf|pYtFunPj~": "957gk=hf9=>jj7:>fif=gg=ij=6i:<ikggi7;<5g",
  KfhjgttpFuuNi: "78=96>686>9>:7:",
  KfhjgttpFuuXjhwjy: "g9g8=58697k89ii8h57h6>9<;587>kk;",
  "|||KfhjgttpFuuNi": "75;7<:><=<86:=55",
  "|||KfhjgttpFuuXjhwjy": "9=j59h9<gh75:876:;=:685:;kgki7hj",
  "^fmttHtsxzrjwPj~": "io5~Orp>VY_gYmSjoN7j]_UOrV>\\[iwT[iJ_L>m[5:5Y]uOg\\SMgqSVX5yOsR>^7>zh8[y_]O_\\S~_]VrjI5:_V22",
  "^fmttHtsxzrjwXjhwjy": "5gi<j<;<>jfj:8hi:%:ffhj:97h;fk;ij58i9>khk",
  QnspjiNsHqnjsyNi: "=;8zjv6moontki",
  QnspjiNsHtsxzrjwXjhwjy: "}nyX^jiXyyRtFGw}",
  "Y|nyyjwT|sjw": "6;=9=6><7",
  "Y|nyyjwYtpjs": "6;=9=6><72T]7soPJuUHyi5K{=[>yF{LgZ<vTx9MJHOgKx[Hv",
  "Y|nyyjwYtpjsXjhwjy": "uFPWrr5:JX9FQ=R6~pHM<y^\\syIh}XvI~wIF\\OIsG9kkt",
  "Y|nyyjwHtsxzrjwPj~": "gHWmp[jQpv{f||_iL|gPrzMPw",
  "Y|nyyjwHtsxzrjwXjhwjy": "i:6UZ>i}FIL5mI||y~nNp|FX[z}pYi;oO{[sUtRF;PhFX\\l==",
  HttpnjItrfns: "qthfqmtxy",
  StUwtknqjUnh: "ifyf?nrflj4x{l0}rq@gfxj;91UI>9g\\|lir[~h7q{go5nRX9|NnGqgrS{_Lqz_5nf]S{QYl9SYpyRXN4Ul5PUHJyQXGM_\\:qhrK5g8N;NJKpg7OqNJqxgM[iMOmiL>~NIJ9QoFzRH|lZ6_MNJ[9hL>~iHGVgM[sQZqzNH9lZ6_MNK_qhsSug79;NI^zRIFlVs[ugLVlRHplNH5yUl5PUHKJY5SZ\\[GKNMS7_~GV[ZORXZRlNn5{Q6hV~={WKWJNKS\\W~F}QoJ{Q5[TNnFnfMW5hIt{Q8i8i~:8R~:{hrh{W8OmhLmu^8R{Z6_MQJzRX>J[JV{h8_sRYJz_MWpNo9SHo}irhlir[~h7q{go5nRX9}NnGu_I5nV7K|^[=}NnG9g\\}zh5nfMW5hIt{Q8i8i~:8R~:{hrh{RoF|RH>irhnNMmygL:Tsmxf\\:wUXOtiMW|Tn={i8i8QshQr>~_~=}TYp:Q8mxf\\:wNnG9UXN|hMlnNMp>NoG|jHNSHlplirqqi5O{jI5nRHF|NIR6RHFSYFnNMS5j\\}qUXOqgrKngLZy^rKof7i~g8[z_Iuz_]hlRHF|NIR6RHFSYF<NnG9g\\|;h8Gm^7Z>NsG~_]Sqhs_qNo9SHo}sUl5PHY}|^]WtNLV>Np5}SZxRYh}QoJ8R7RTH9:RYVxRH|8RH95SoRyRlzRJ9QIh|QoV7R~59SX96TI_IRoV6QoV7R~|TH9RYlxRoR6QoJ|SX||QIJ8SX||h~58RH95SoZxRlzRJ9QYh|QoV7SX|9SX96TIhSHlpOVJ|SH96RZxRYR~Qol6SX|}R^zRIl5QIJ8RX9}SRxRYh6QIJ8RX9}SS;Nn=0IVtOUMGmiLll_I5nYYV}Qop|TX|RIJzTIZVV}Qol:S~|~TYlzTYh}QIV}Qol9SX|RIJzRIV}QIV}Qop|TX|RIJzTIZYIV}Qop|TX|RIJzTIZjnN{Ul5PHY}|^]WtNLV>Np5RIlzRIl6QIR|SH9}RIWIRF9QoJ~R~|RIRzRJ6QIR|TH9|TYlxRop9Qo^QIR|TH9|TIZxRF5QoJ|SJ|RIlzRIl6QIR|SH9}RIW;Nn=0IVtOUMGmiLll_I5nYYR|S~9:RZxRop9QoR:S7RyRX9RIZyTINzRV~QYJ~QoF6TX5}RIZzTIF6QYp5QoR6Rn5}RoFzSoZ8^FxRH5}RX96TIVxRYVzS^}QYR9QoZ9SH|}SH98SoJSHlpOh~5TH96TI^yRYVzS^}QYR9QoZ9Sn5}SH98SoKoQYl}QoR:SX|}SH97TX5:Rn99RIRxRhzTIF6QYp5QoR|R~|}RYhzTYl~^~5|QoJ~R~|7QoZ5S~5|QoJ9QI^zTIp}QYFzRoF~QI^zRYR}IVtOH\\R|QoF|SX|}QoV~SH||QoF}RX|5QoF6TH||QoF}RX|9Qo^6R\\R|QIFxRYpzSYp~QIR:QoV:Sn|}RRzRIlxRpzSIp7^J}R~95TI^xRH|}RRzRIlyRpzSIp7QIJR~9|TH5TX95TY^SHlpO^FyRn9:SYJxRH9|RINySX9|RIRxRH9|RIZySn9TYqIRF9QoF7Rn|RIVzSYh6QIR|TH9|RYlxRFQo^7SH|RIhzTYR6QIN:TH9TYi;Nn=0IVt=Q7h0IVt=_9SHo|{_9SHo}sUl5PUH>sUl5PULh0IVt=Q7h0IVt=_9SHo|{_9SHo}sUl5PUH>sUl5PULh0IVt=Q7h0IVt=_9SHo|{_9SHo}sUl5PUH>sUl5PULh0IVt=Q7h0IVt=_9SHo|{_9SHo}sUl5PUH>sUl5PULh0IVt=Q7h0IVt=_9SHo|{_9SHo}sUl5PUH>sUl5PULh0IVt=Q7h0IVt=Q8S7_9SHlBB",
  StGfhplwtzsiUnh: "ifyf?nrflj4x{l0}rq@gfxj;91UI>9g\\|lir[~h7q{go5nRX9|NnGqgrS{_Lqz_5nf]S{QYl9SYpyRXN4Ult=NX5yNJiqgr[~^]W{hotlV\\W{^rZlX\\}xi]S5hrK5g8NlRY^zRH9|QHGY[phlW]m|g8O5NKGxi\\hyX\\9lQnGY[phl[r[~h7q{gotlSn9|RHGHi\\qx_HF|PXFlQX50Ho|mWJ>I[KqVWXGirhlZK[HYJqINHNyQ~>]R5R{Q5WZWHGY[phlRX9}Q~>KYnNlNrm5iMF;Q~>8i8hziRzg8OsQ5i~^]Gtf\\SQ6S\\W~=}QoJ{WKWJQ8S7_J}QrW5_HN0Ho}irhlir[~h7q{go5nRX9}NnGu_I5nYLK:_]OkRXNljL6xgsR>Nrm5iMF;Q~>8i8hziRzg8OsQN|RIF{h8_sNnG9g\\}zhu9gLqzf5nfMW5hIt{Q8i8i~:8R~:{hrh{RYp:TX>9gLqzf~NljI5nRMG9NnG:UXN|hMlnHlpli7qpiLl>NoJ5hMlnNLmqf\\itiI5nRYW|jHNlirqqi5O{jI5nQYJlQYRlRY^lRY^nNMS5j\\}qUXOqgrKngLZy^rKof7i~g8[z_Iuz_]hlRHF|NIJ5NIJ5T~NljL6xTsS|^\\SqUXO|hr[_]O7_XN0Ho}|^]WtNLV>Np5}Rn|~Qo[NTX9:RImoQYFzRoF7QYFzSYl}QYFzSZ7QYJyRX95RIlyR\\lyR7RyRH97SYNxRH5}QoN|Rn||QoV}TX5}QoV|TH|}XIOoQYJzRYF5QIFyRn||Qol:Sn5~QIO7SrR|QIJzRYF5QIFzTIp7QINxRn|~HlqtRYGoRX9}RIVxRH|~QYFzTIp7QINyRs^ySpR}SH|QoR:Sn|}R~9}RIVxRn96QIJ~QINzS]tlYYhxRYFzS\\RyRX97SYhxRH5QYJzRV5QYRyR7R|QYJzSoZ8QIJzRVQYRxR~5hRxRX9SIRxR~|HlqIRYFxTX9}SY^xTH97SYhxRYFzSX|8QIJ|Qo[;NJ58QIZzS\\RyRX9}RIVxRH5~QIFzTIp7QYNxRrR|QIJzRYF5QIFzTIp7QINxRn|~^JzRYF5QIFxRn5|Qol:Sn|~QYOITX|7QoR:Sn|9QoJ|SH|6QoZxS~|6Qo[;Nn=0Ho|{h8_sUlBB",
  KnqjZwq: "myyux?44zwyjhmhny~3htr4fun4knqj4",
  FuuSfrj: "ZwYjhmHny~",
  "Rfns\\jgZwq": "myyux?44zwyjhmhny~3htr",
  "Xjw{nhjZwq": "myyux?44zwyjhmhny~3htr4fun4",
  KnqjUfym: "4zwyjhmhny~2xytwflj4ZwYjhmHny~Zuqtfix4",
  LttlqjHfuyhmf: {
    "XnyjPj~": ";QhP]\\xrFFFFFUz=lHrnfu]N\\m\\HfGX>mkiRKTp~",
    "XjhwjyPj~": ";QhP]\\xrFFFFFJ[OId26OSzRJGwTFML^h{kupL6"
  },
  "^tzyzgj": {
    "FUNPj~": "FNfX~H[I}Ts;55hohjihhHLpyWFZ[=H|<Kpdg5",
    TFZYM7dHQNJSYdNI: ":58:;6=65=552slyumiy>8<wttkv<uv:w7vnrm;;8tq<n3fuux3lttlqjzxjwhtsyjsy3htr",
    TFZYM7dXHTUJX: "myyux?44|||3lttlqjfunx3htr4fzym4~tzyzgj3ktwhj2xxq%myyux?44|||3lttlqjfunx3htr4fzym4~tzyzgj3zuqtfi%myyux?44|||3lttlqjfunx3htr4fzym4~tzyzgj3zuqtfi%myyux?44|||3lttlqjfunx3htr4fzym4~tzyzgjufwysjw%myyux?44|||3lttlqjfunx3htr4fzym4~tzyzgj3wjfitsq~",
    HMFSSJQdNI: "ZH76f6L{ZoR\\;d2^HfT;xx|"
  },
  LwtzuQtyytHqnjsyNi: "7",
  LHfxm: {
    "ZWYJHMHNY^": {
      FuuNi: "Wts~xQ]~tLmWinJsLvh~R]mW>tLpx;sf",
      FuuXjhwjy: "><9777==9>8<<gf:gi7:9:>8>6<ji;;99i9;6797j<i9j=i;;<j5=8<=g;5<ii8=",
      XmtwyHtij: "76:=;6=>",
      Htij: "77:;:;6=>"
    },
    ZWYJHMUTX: {
      FuuNi: "}vP}xl~Gvk9WYQ}sQn~jkQsvL]xwg>",
      FuuXjhwjy: ";g5i9j;87fi:=;<7i;>h9h575g7ij7hji:f9hgi>=9fkk5=9ji<ii:f57<>5h>i5",
      XmtwyHtij: "76:=5958",
      Htij: "77:;:5958"
    },
    ZWYJHMXMTU: {
      FuuNi: ";Jt<KG:Gfjyv:hi;jgYGsQyPLJPJKog<",
      FuuXjhwjy: "<jh<:j:8k:;h6=g7;9;jg;6:8i7kh<=<9kgkgiikh5;=h68>78==k5hg68g5h9ki",
      XmtwyHtij: "76:=79<9",
      Htij: "77:;:79<9"
    },
    OTGUTWYFQ: {
      FuuNi: "Li}GHQ~jJlHRQhPvWPYjvpHLjii;Hjui",
      FuuXjhwjy: "75=>76ihg9:i;kkf:76ffg>>i599;h;=7j:;i:><=8>=fi:<;jk>9j8f<j;87966",
      XmtwyHtij: "76:=>65:",
      Htij: "77:;:>65:"
    },
    ZUZGQNXMRJ: {
      FuuNi: "v<fWH<w><oK:gYg;jon>~FKR<gLHgoo",
      FuuXjhwjy: "66kjh:8>5<f<;8;f75=98h:h<;57<g8j9<667i6<;;7;=>f;:i67h:;kf;f:gg:j",
      XmtwyHtij: "76:==:89",
      Htij: "77:;:=:89"
    }
  },
  "Uf~ufq": {
    HqnjsyNi: "F[qzUqqNUfJLFtHF^]XUfhPORNy9;sFis_U=~j]U]lmqF<SGkrK<YoQvss~8u|F|G>79}TZlj>{<jsh",
    HqnjsyXjhwjy: "JG8[xhMQuZWIwT^hIqR67Yth5mXLXovWNsYxvQLwP<T6v|5MM7~gVPy9tjRgN}{YyqQ7G77OohJv{Rl"
  },
  Yjqjlwfr: {
    FunNi: "6:66=678",
    FunMfxm: "kg:g7>g99jk:gi<ij=g8h8h57fkjg77>",
    GtyYtpjs: ";87896:795?FFKKn99;|XuX7Y5vRt5\\^zvGy2Ir=>NHFF|",
    HmfyNi: "EZwYjhmHny~dOtgUtwyfqdGty"
  }
};
export default ConfigData;