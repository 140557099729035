import { configureStore } from "@reduxjs/toolkit";
import { BANNER_CURRENT_INDEX, CFW_ACTIVATE_SOCIAL_BUTTONS, CFW_ADSENSE_READY, CFW_BANNER_REFRESHER, CFW_CURRENT_URL, CFW_ERROR, CFW_HANDLE_SEO_PROCESS, CFW_IS_MOBILE, CFW_LOAD_MENU, CFW_SCROLL_COUNTER, CFW_SCROLL_COUNTER_PROCESSED, CFW_TOTAL_CASH, CFW_TOTAL_STATE, DIALOGHOLDER, FILTERHOLDER, Response_SelectedTranslation, Response_TranslationData, Response_TranslationItem, Response_SiteMapUrlData, Response_ApplicationProposalData, PROCESS_UPDATE, PROCESS_DELETE, CFW_CURRENT_CANONICAL_URL } from "./CFW.js";
import { Decrypt } from "./SFW.js";
import { Clone, HasProperty } from "./Common.js";
const InitialState = {
  isDelete: 0,
  params: {},
  responseData: {},
  error: "",
  type: ""
};
export const persistConfig = {
  blacklist: [Response_SiteMapUrlData, Response_TranslationItem, CFW_ERROR, CFW_CURRENT_CANONICAL_URL, CFW_ADSENSE_READY, CFW_SCROLL_COUNTER, CFW_SCROLL_COUNTER_PROCESSED, CFW_ACTIVATE_SOCIAL_BUTTONS, CFW_LOAD_MENU, "@@INIT", DIALOGHOLDER, FILTERHOLDER, Response_SelectedTranslation, CFW_IS_MOBILE, CFW_BANNER_REFRESHER, BANNER_CURRENT_INDEX, CFW_TOTAL_STATE, CFW_TOTAL_CASH, CFW_HANDLE_SEO_PROCESS, CFW_CURRENT_URL],
  blacklistContains: [Response_TranslationItem, Response_ApplicationProposalData]
};
export const golbaVariables = {};
export const RehydrationState = results => {
  try {
    if (results) {
      Object.keys(results).forEach(keyName => {
        if (HasProperty(results[keyName]?.responseData, "Results")) {
          SetState(Decrypt(keyName), results[keyName].responseData.Results);
        } else if (HasProperty(results[keyName], "responseData")) {
          SetState(Decrypt(keyName), results[keyName]?.responseData);
        } else if (HasProperty(results, keyName)) {
          SetState(Decrypt(keyName), results[keyName]);
        }
      });
    }
  } catch (error) {
    console.log("Rehydration State Error", error.Message);
  }
};
const persistMiddleware = persistConfig => store => next => action => {
  const result = next(action);
  const blacklistPersist = persistConfig.blacklist || [];
  const blacklistContainsPersist = persistConfig.blacklistContains || [];
  if (action.isDelete === 2 || !blacklistPersist.includes(result.type) && !blacklistContainsPersist.find(item => item.includes(result.type))) {
    if (action.type) {
      switch (action.isDelete) {
        case 0:
          window.IndexedDBPostMessage(PROCESS_UPDATE, {
            key: result.type,
            value: result
          });
          break;
        case 1:
          window.IndexedDBPostMessage(PROCESS_DELETE, {
            key: result.type
          });
          break;
        case 2:
          window.DictionaryState = {};
          window.IndexedDBPostMessage(action.type);
          break;
      }
    }
  }
  return result;
};
const APIReducer = function (state, action) {
  if (state === void 0) {
    state = InitialState;
  }
  if (typeof window === "undefined") {
    return {};
  } else if (!window?.DictionaryState) {
    window.DictionaryState = {};
  }
  const results = {
    isDelete: action.isDelete,
    params: action.params,
    responseData: action.responseData,
    error: action.error || ""
  };
  if (action.type) {
    switch (action.isDelete) {
      case 0:
        window.DictionaryState[action.type] = results;
        break;
      case 1:
        delete window.DictionaryState[action.type];
        break;
      case 2:
        window.DictionaryState = {};
        break;
    }
  } else {
    console.log(state);
  }
  return JSON.parse(JSON.stringify(window.DictionaryState));
};
const excludeSerializer = ["responseData.Results.0.PackageDetails.$$typeof", "responseData.Results.0.PackageDetails.type", "responseData.Results.0.UrlPathOrFileName.blob", "responseData.Results.UrlPathOrFileName.blob", "params.blobFile", "responseData.Results.Base64File.blob", "responseData.Results.FileName.blob", "responseData.Results.Picture.blob", DIALOGHOLDER, FILTERHOLDER];
export const Store = configureStore({
  reducer: APIReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
        ignoredActionPaths: excludeSerializer,
        ignoredPaths: excludeSerializer
      }
    }).concat(persistMiddleware(persistConfig));
  }
});
export const GetStateField = function (pKey, pField, defaultValue) {
  if (defaultValue === void 0) {
    defaultValue = null;
  }
  const currentData = typeof pKey === "string" ? GetState(pKey) : pKey;
  if (currentData) {
    return currentData[pField] ?? defaultValue;
  }
  return defaultValue;
};
export const SetRawState = param => {
  Store.dispatch(Clone(param));
};
export const GetState = function (pKey, defaultValue) {
  if (defaultValue === void 0) {
    defaultValue = null;
  }
  if (typeof window === "undefined") {
    return defaultValue;
  } else if (!window?.DictionaryState) {
    window.DictionaryState = {};
  }
  if (!window?.DictionaryState[pKey] && window?.DictionaryState[pKey] !== 0) {
    return defaultValue;
  }
  const translationState = window.DictionaryState[pKey];
  if (HasProperty(translationState, "responseData") && translationState.responseData && HasProperty(translationState.responseData, "Results")) {
    return translationState.responseData.Results ?? defaultValue;
  } else if (HasProperty(translationState, "responseData")) {
    return translationState.responseData ?? defaultValue;
  }
  return translationState ?? defaultValue;
};
export const RemoveState = pKey => {
  if (typeof window === "undefined") {
    return;
  } else if (!window?.DictionaryState) {
    window.DictionaryState = {};
  }
  if (typeof window !== "undefined" && window?.DictionaryState[pKey]) {
    const results = {
      params: null,
      responseData: {
        Results: null,
        Message: null,
        Token: null
      },
      type: pKey,
      isDelete: 1
    };
    SetRawState(results);
  }
};
export const SetState = function (storeName, pVal, secondsDelay) {
  if (secondsDelay === void 0) {
    secondsDelay = null;
  }
  const results = {
    params: null,
    responseData: {
      Results: pVal,
      Message: null,
      Token: null
    },
    type: storeName,
    isDelete: 0
  };
  if (JSON.stringify(pVal) !== JSON.stringify(GetState(storeName))) {
    if (!secondsDelay) {
      SetRawState(results);
    } else {
      setTimeout(() => {
        SetRawState(results);
      }, secondsDelay * 1000);
    }
  }
};
export const ValildateEntries = function (stateErrorList, stateToStoreErrorMessages, exceptForFields) {
  if (exceptForFields === void 0) {
    exceptForFields = [];
  }
  const errorCollection = GetState(stateErrorList, null);
  if (window.children) {
    delete window.children;
  }
  if (errorCollection) {
    const errors = Object.keys(errorCollection).filter(item => errorCollection[item] && !exceptForFields.includes(item)).map(item => `${errorCollection[item]}`);
    SetState(stateToStoreErrorMessages, errors?.length ? `<div class="note"><h1>${window.initScripts[Response_TranslationData]["696"]}:</h1>${errors.join("")}</div>` : null);
    return !errors?.length;
  }
  return true;
};
export const ClearEntries = (state, freshState) => {
  SetState(state, {
    ...freshState
  });
};
export const UpdateStateField = function (storeName, pField, pVal, secondsDelay) {
  if (secondsDelay === void 0) {
    secondsDelay = null;
  }
  const currentData = {
    ...GetState(storeName)
  };
  if (currentData && JSON.stringify(currentData[pField]) !== JSON.stringify(pVal)) {
    currentData[pField] = pVal;
  }
  SetState(storeName, currentData, secondsDelay);
};