import { hydrateRoot } from "react-dom/client";
import App from "./App.js";
import {
  GetProductAndLanguage,
  GetSelectedCountry,
  GUID,
  IsMobile,
} from "./common/js/generic/Common.js";
import registerServiceWorker from "./serviceWorkerRegistration.js";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import {
  CFW_DROPDOWN_COUNTRY,
  CFW_IS_MOBILE,
  CFW_REFRESHER,
  Response_SelectedTranslation,
} from "./common/js/generic/CFW.js";
import { CleanUrl, Clone } from "./common/js/generic/Common.js";
import { MaintainTranslations } from "./common/js/generic/PFW.js";
import {
  GetState,
  RemoveState,
  SetState,
} from "./common/js/generic/StoreToolkit.js";
import { User } from "./common/js/generic/User.js";
const { languageCode, productCode } = GetProductAndLanguage(
  window.location.pathname
);
if (window.children) {
  delete window.children;
}
if (window.selectedTranslation) {
  delete window.selectedTranslation;
}
if (window.pcb) {
  delete window.pcb;
}
if (window.ncb) {
  delete window.ncb;
}
if (window.recommendIndex) {
  delete window.recommendIndex;
}
if (window.uploadEvent) {
  delete window.uploadEvent;
}
if (window?.processing) {
  delete window.processing;
}
registerServiceWorker(languageCode, productCode);
const currentUrl = CleanUrl(
  `https://urtechcity.com${window.location.pathname}${
    window.location.search ?? ""
  }`
);
const contextMenu = (e) => {
  if (e.target.hasAttribute("translated")) {
    const param = Clone(MaintainTranslations);
    const translationTypeId = parseInt(e.target.getAttribute("translated"), 10);
    param.Filter.TranslationTypeId = translationTypeId;
    param.Filter.LanguageCode = languageCode;
    param.TranslationTypeId = translationTypeId;
    param.LanguageCode = languageCode;
    RemoveState(`${CFW_REFRESHER}-translation`);
    window.selectedTranslation = param;
    SetState(Response_SelectedTranslation, GUID());
  }
  e.preventDefault();
};
const windowWidth = () => {
  const isMobile = GetState(CFW_IS_MOBILE);
  if (isMobile !== IsMobile()) {
    SetState(CFW_IS_MOBILE, IsMobile());
  }
};
if (User.IsTranslator()) {
  window.removeEventListener("contextmenu", contextMenu, false);
  window.addEventListener("contextmenu", contextMenu);
}
window.removeEventListener("resize", windowWidth, false);
window.addEventListener("resize", windowWidth);
window["__react-beautiful-dnd-disable-dev-warnings"] =
  window.location.host.includes("urtechcity.com");
if (!GetState(CFW_DROPDOWN_COUNTRY)) {
  SetState(CFW_DROPDOWN_COUNTRY, GetSelectedCountry());
}
hydrateRoot(
  document,
  <React.StrictMode>
    <BrowserRouter future={{ v7_startTransition: true }}>
      <App
        initData={window.initScripts}
        currentUrl={currentUrl}
        siteMapData={window.siteMapData}
        css={window.cssFiles}
        roles={User.Roles()}
      />
    </BrowserRouter>
  </React.StrictMode>
);
import("web-vitals").then(({ onCLS, onFID, onFCP, onLCP, onTTFB, onINP }) => {
  onCLS(console.log);
  onFID(console.log);
  onLCP(console.log);
  onINP(console.log);
});
