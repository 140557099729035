import React, { useEffect, useRef, useState } from "react";
import { AddScriptsWithCallBack, Waiter } from "../generic/Common.js";
import { GetConfig } from "../generic/SFW.js";
import { useQuery } from "@tanstack/react-query";
import { Response_TranslationData } from "../generic/CFW.js";
let currentMap;
const InitializeGoogle = (onLoad) => {
  AddScriptsWithCallBack(
    `https://maps.googleapis.com/maps/api/js?key=${GetConfig("GoogleApiKey")}`,
    "googleMapId",
    {
      async: "",
    },
    onLoad
  );
};

export const GetLocationFromLatLong = async (lat, lng) => {
  return new Promise((resolve) => {
    const onLoad = () => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          if (results) {
            const result = {};
            results[0].address_components.forEach((item) => {
              result[item.types[0]] = {
                code: item.short_name,
                name: item.long_name,
              };
            });
            resolve(result);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
    };
    InitializeGoogle(onLoad);
  });
};

const GoogleMap = ({
  className,
  options = {
    center: { lat: 1, lng: 1 },
    zoom: 2,
  },
  onMount,
  onMountProps,
}) => {
  const { data: tranList } = useQuery({
    queryKey: [Response_TranslationData],
  });
  const ref = useRef();
  const [map, setMap] = useState();
  useEffect(() => {
    // The Google Maps API modifies the options object passed to
    // the Map constructor in place by adding a mapTypeId with default
    // value 'roadmap'. { ...options } prevents this by creating a copy.
    const onLoad = async () => {
      while (!window?.google?.maps) {
        await Waiter(1000);
      }
      if (ref.current) {
        currentMap = new window.google.maps.Map(ref.current, {
          ...options,
        });
        setMap(currentMap);
      }
    };
    InitializeGoogle(onLoad);
  }, [options, ref?.current]);
  if (map && typeof onMount === "function") {
    onMount(map, onMountProps);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geocoder = new window.google.maps.Geocoder();
          GeoCodeLatLng(
            position.coords.latitude,
            position.coords.longitude,
            geocoder,
            map,
            tranList
          );
        },
        () => {}
      );
    }
  }
  return (
    <div
      id="map"
      style={{ height: "60vh", margin: "1em 0", borderRadius: "0.2em" }}
      {...{ ref, className }}
    />
  );
};
export const SetCenter = (lat, lng) => {
  currentMap.setCenter(new window.google.maps.LatLng(lat, lng));
};
const GeoCodeLatLng = (lat, long, geocoder, map, tranList) => {
  const latlng = {
    lat: lat,
    lng: long,
  };
  geocoder.geocode({ location: latlng }, (results, status) => {
    if (status === "OK") {
      if (results[0]) {
        const marker = new window.google.maps.Marker({
          position: latlng,
          map: map,
          label: "You are here",
          title: results[0].formatted_address,
        });
        const infowindow = new window.google.maps.InfoWindow({
          content: `<div><b>${tranList["42"]}:</b>&nbsp;${results[0].formatted_address}</b></div>`,
        });
        marker.addListener("click", () => {
          infowindow.open(map, marker);
        });
        marker.setMap(map);
        map.setCenter(new window.google.maps.LatLng(latlng.lat, latlng.lng));
      } else {
        window.alert("No results found");
      }
    } else {
      window.alert("Geocoder failed due to: " + status);
    }
  });
};
export default GoogleMap;
