import ConfigData from "../../../common/data/ConfigData.js";
import { UnregisterServiceWorkers } from "../../../serviceWorkerRegistration.js";
import { FileExtensions_Document, FileExtensions_Image, FileExtensions_PDF, FileExtensions_Sound, FileExtensions_Video } from "./CFW.js";
import { GetProductAndLanguage, GUID, HasProperty, Omit } from "./Common.js";
import { InitializeCustomWorker } from "./InitializeCustomWorker.js";
import { GetState, SetRawState, SetState } from "./StoreToolkit.js";
import { User } from "./User.js";
const DictionaryState = {};
export const SetSessionKey = (sessId, val) => {
  window.sessionStorage.setItem(Encrypt(sessId), val);
};
export const SetSession = (sessId, val) => {
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem(Encrypt(sessId), Encrypt((val ?? "").toString()));
  }
};
export const SetStorage = (sessId, val) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(Encrypt(sessId), Encrypt((val ?? "").toString()));
  }
};
export const Encrypt = input => {
  if (!input && input !== 0) {
    return "";
  }
  let rslts = [];
  for (const s of input.split("")) {
    let y = s.charCodeAt(0);
    y = String.fromCharCode(y + 5);
    rslts.push(y);
  }
  return rslts.join("");
};
export const Decrypt = input => {
  if (!input) {
    return "";
  }
  let rslts = [];
  for (const s of input.split("")) {
    let y = s.charCodeAt(0);
    y = String.fromCharCode(y - 5);
    rslts.push(y);
  }
  return rslts.join("");
};
export const GetSessionKey = function (sessId, defaultVal) {
  if (defaultVal === void 0) {
    defaultVal = null;
  }
  let val = window.sessionStorage.getItem(Encrypt(sessId));
  return !val ? defaultVal : val;
};
export const GetSession = function (sessId, defaultVal) {
  if (defaultVal === void 0) {
    defaultVal = null;
  }
  let val = typeof window === "undefined" ? defaultVal : window.sessionStorage.getItem(Encrypt(sessId));
  return !val ? defaultVal : Decrypt(val);
};
export const GetStorage = function (sessId, defaultVal) {
  if (defaultVal === void 0) {
    defaultVal = null;
  }
  let val = typeof window === "undefined" ? defaultVal : window.localStorage.getItem(Encrypt(sessId));
  return !val ? null : Decrypt(val);
};
export const RemoveSession = sessId => {
  if (typeof window !== "undefined") {
    window.sessionStorage.removeItem(Encrypt(sessId));
  }
};
export const RemoveStorage = sessId => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(Encrypt(sessId));
  }
};
export const GetFirstResult = pState => {
  if (!pState) {
    return null;
  }
  if (pState?.responseData?.Results?.length) {
    return pState.responseData.Results[0];
  }
  return null;
};
export const GetResults = pState => {
  if (!pState) {
    return null;
  }
  if (pState?.responseData?.Results) {
    return pState.responseData.Results;
  }
  return null;
};
export const GetRawState = pKey => {
  if (!DictionaryState[pKey] && DictionaryState[pKey] !== 0) {
    return "";
  }
  return DictionaryState[pKey];
};
export const GetConfig = val => {
  val = val.split(".");
  if (val.length === 1 ? ConfigData[Encrypt(val[0])] : val.length === 2 ? ConfigData[Encrypt(val[0])][Encrypt(val[1])] : ConfigData[Encrypt(val[0])][Encrypt(val[1])][Encrypt(val[2])]) {
    return Decrypt(val.length === 1 ? ConfigData[Encrypt(val[0])] : val.length === 2 ? ConfigData[Encrypt(val[0])][Encrypt(val[1])] : ConfigData[Encrypt(val[0])][Encrypt(val[1])][Encrypt(val[2])]);
  }
  return "";
};
export const GetImageCustomWorker = (paramUrl, postEvent) => {
  const postWorker = InitializeCustomWorker(response => {
    postEvent(response);
  }, error => {
    console.log("Looks like there was a problem:", error);
  });
  let contentType = paramUrl.substring(paramUrl.lastIndexOf(".") + 1).toLowerCase();
  if (FileExtensions_Video.includes(contentType)) {
    contentType = `video/${contentType}`;
  } else if (FileExtensions_Sound.includes(contentType)) {
    contentType = `audio/${contentType}`;
  } else if (FileExtensions_Image.includes(contentType)) {
    contentType = `image/${contentType}`;
  } else if (FileExtensions_Document.includes(contentType) || FileExtensions_PDF.includes(contentType)) {
    contentType = `application/${contentType}`;
  } else {
    contentType = "none";
  }
  const requestOptions = {
    header: {
      "Content-Type": contentType
    },
    url: paramUrl,
    method: "GET"
  };
  const payload = postWorker.payload(paramUrl, requestOptions, -1);
  postWorker.worker.postMessage(payload);
};
export const FileUpload = (action, pData, storeName, postEvents, headers) => {
  const {
    productCode,
    languageCode
  } = GetProductAndLanguage(window.location.pathname);
  let requestOptions = {
    crossDomain: true,
    method: "POST",
    headers: {
      encType: "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Lang: languageCode,
      App: productCode,
      ...headers
    },
    body: pData
  };
  const responseType = GetResponseHeader(headers?.ResponseType);
  const postWorker = InitializeCustomWorker(response => {
    if (typeof response !== "string" && !response.ok) {
      throw Error(response.statusText);
    }
    SetRawState({
      params: pData,
      responseData: response,
      type: storeName,
      isDelete: 0
    });
    if (postEvents) {
      if (typeof postEvents === "function") {
        postEvents();
      } else {
        SetState(postEvents, GUID());
      }
    }
  }, error => {
    SetRawState({
      params: null,
      error: error,
      type: storeName,
      isDelete: 0
    });
    console.log("Looks like there was a problem:", error);
  });
  const payload = postWorker.payload(action, requestOptions, responseType);
  postWorker.worker.postMessage(payload);
};
export const PostDataPlain = function (action, pData, pForm, storeName, postEvents, additionalOptions, headers) {
  if (additionalOptions === void 0) {
    additionalOptions = null;
  }
  if (headers === void 0) {
    headers = null;
  }
  MaintainDataPlain("POST", action, pData, pForm, storeName, postEvents, additionalOptions, headers);
};
const GetResponseHeader = function (responseType) {
  if (responseType === void 0) {
    responseType = 0;
  }
  switch (responseType) {
    case "text":
      return 1;
    case "blob":
      return 2;
    default:
      return 0;
  }
};
const MaintainDataPlain = function (method, action, pData, pForm, storeName, executeIndicator, additionalOptions, headers) {
  if (additionalOptions === void 0) {
    additionalOptions = null;
  }
  if (headers === void 0) {
    headers = null;
  }
  const {
    productCode,
    languageCode
  } = GetProductAndLanguage(window.location.pathname);
  const responseType = GetResponseHeader(headers?.ResponseType);
  let requestOptions = {
    method,
    headers: headers,
    ...additionalOptions,
    body: pData,
    form: pForm
  };
  if (!headers) {
    requestOptions = Omit(requestOptions, ["headers"]);
  }
  if (!pData) {
    requestOptions = Omit(requestOptions, ["body"]);
  }
  if (!pForm) {
    requestOptions = Omit(requestOptions, ["form"]);
  }
  let data = pData ? pData : pForm;
  const postWorker = InitializeCustomWorker(responseParam => {
    const response = responseParam;
    if (typeof response !== "string" && !response.ok) {
      throw Error(response.statusText);
    }
    if ((response.Message ?? "").toLowerCase() === "Failed to authenticate token.".toLowerCase()) {
      UnregisterServiceWorkers(languageCode, productCode, true);
    } else {
      if (storeName) {
        SetRawState({
          params: data,
          responseData: response,
          type: storeName,
          isDelete: 0
        });
      }
      if (executeIndicator) {
        if (typeof executeIndicator === "function") {
          executeIndicator();
        } else {
          SetState(executeIndicator, GUID());
        }
      }
    }
  }, error => {
    SetRawState({
      params: data,
      error: error,
      type: storeName,
      isDelete: 0
    });
    console.log("Looks like there was a problem:", error.message);
  });
  const payload = postWorker.payload(action, requestOptions, responseType);
  postWorker.worker.postMessage(payload);
};
export const PostData = function (action, pData, storeName, executeIndicator, contentType, additionalHeaders, fetchOnlyIfNotExists) {
  if (executeIndicator === void 0) {
    executeIndicator = null;
  }
  if (contentType === void 0) {
    contentType = "application/json";
  }
  if (additionalHeaders === void 0) {
    additionalHeaders = {};
  }
  if (fetchOnlyIfNotExists === void 0) {
    fetchOnlyIfNotExists = false;
  }
  const {
    productCode,
    languageCode
  } = GetProductAndLanguage(window.location.pathname);
  if (fetchOnlyIfNotExists && GetState(storeName)) {
    if (executeIndicator) {
      executeIndicator();
    }
    return;
  }
  const responseType = GetResponseHeader(additionalHeaders?.ResponseType);
  let requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": pData ? contentType ? contentType : "application/json" : "text/plain",
      "Access-Control-Allow-Origin": "*",
      Lang: languageCode,
      "x-access-token": User.AccessToken(),
      ...additionalHeaders,
      App: productCode.toLowerCase()
    },
    body: contentType === "multipart/form-data" ? pData : pData ? JSON.stringify(pData) : null
  };
  const postWorker = InitializeCustomWorker(responseAsJson => {
    if ((responseAsJson.Message ?? "").toLowerCase() === "Failed to authenticate token.".toLowerCase()) {
      UnregisterServiceWorkers(languageCode, productCode, true);
    } else {
      if (storeName) {
        SetRawState({
          params: pData,
          responseData: responseAsJson,
          type: storeName,
          isDelete: 0
        });
      }
      if (executeIndicator) {
        if (typeof executeIndicator === "function") {
          executeIndicator();
        } else {
          SetState(executeIndicator, GUID());
        }
      }
    }
  }, error => {
    SetRawState({
      params: pData,
      error: error,
      type: storeName,
      isDelete: 0
    });
    console.log("Looks like there was a problem:", error);
  });
  const payload = postWorker.payload(action, requestOptions, responseType);
  postWorker.worker.postMessage(payload);
};
export const PostDataIfStorageEmpty = function (action, pData, storeName, executeIndicator, forceRetrieve) {
  if (executeIndicator === void 0) {
    executeIndicator = null;
  }
  if (forceRetrieve === void 0) {
    forceRetrieve = false;
  }
  const indexedDBData = GetState(storeName);
  if (forceRetrieve || !indexedDBData?.length) {
    if (pData && HasProperty(pData, "PageIndex")) {
      pData["PageIndex"] = -1;
    }
    PostData(action, pData, storeName, executeIndicator);
  } else {
    if (executeIndicator) {
      SetState(executeIndicator, GUID());
    }
  }
};