import React, { Fragment, useEffect, useState } from "react";
import {
  CFW_CURRENTNUMBER,
  CFW_REFRESHER,
  DIALOGHOLDER,
  REGEXP_DECIMAL,
  Response_TranslationData,
} from "../../../../common/js/generic/CFW.js";
import {
  GetFormattedDate,
  GetProductAndLanguage,
  GUID,
  IsTrue,
  uniq,
} from "../../../../common/js/generic/Common.js";
import { MaintainDialog } from "../../../../common/js/generic/PFW.js";
import { GetResults } from "../../../../common/js/generic/SFW.js";
import {
  GetState,
  GetStateField,
  RemoveState,
  SetState,
  UpdateStateField,
} from "../../../../common/js/generic/StoreToolkit.js";
import { useLocation } from "react-router-dom";
import GoogleMap, { SetCenter } from "../GoogleMap.js";
import Label from "../Label.js";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
const CopyToClipboard = (value) => {
  navigator.clipboard.writeText(value);
};

export const ShowMap = (addressVal, tran) => {
  const entryLabel = isNaN(tran)
    ? tran
    : window.initScripts[Response_TranslationData][tran];
  const InitializeMap = (map, addressInfo) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: addressVal }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        let latitude = results[0].geometry.location.lat();
        let longitude = results[0].geometry.location.lng();
        const marker = new window.google.maps.Marker({
          map,
          position: { lat: latitude, lng: longitude },
          label: addressInfo.label,
          title: addressInfo.title,
        });
        const infowindow = new window.google.maps.InfoWindow({
          content: addressInfo.content,
        });
        SetCenter(latitude, longitude);
        marker.addListener("click", () => {
          infowindow.open(map, marker);
        });
      }
    });
  };
  const addressInfo = {
    title: addressVal,
    label: window.initScripts[Response_TranslationData]["443"],
    content: `<div><b>${window.initScripts[Response_TranslationData]["42"]}:</b>&nbsp;${addressVal}</b></div>`,
  };
  const mapProps = {
    options: {
      center: { lat: 1, lng: 1 },
      zoom: 12,
    },
    onMount: InitializeMap,
    onMountProps: addressInfo,
  };
  const dialogParam = { ...MaintainDialog };
  dialogParam.msg = `${entryLabel}: ${addressVal ?? ""}`;
  window.children = <GoogleMap {...mapProps} />;
  SetState(DIALOGHOLDER, dialogParam);
};
export const Options = ({ data }) => {
  const optionsValues = [];
  if (!data?.length) {
    return <Fragment />;
  }
  const hasOptionGroups = !!data[0].OptionGroup;
  if (hasOptionGroups) {
    const continents = [...new Set(data.map((i) => i.OptionGroup))];
    continents.forEach((og) => {
      const optionTypes = [];
      const filteredOption = data.filter((o) => o.OptionGroup === og);
      while (filteredOption?.length) {
        optionTypes.push(
          filteredOption.splice(0, Math.min(30, filteredOption.length))
        );
      }
      optionTypes.forEach((dataProp, idx) => {
        optionsValues.push(
          <optgroup
            key={`${idx}-${GUID()}`}
            label={`${og}${idx ? `-${idx.toString()}` : ""}`}
          >
            {dataProp.map((i, idx) => {
              return (
                <option key={idx} value={i.Key}>
                  {i.Value}
                </option>
              );
            })}
          </optgroup>
        );
      });
    });
  } else {
    data.forEach((i, idx) => {
      optionsValues.push(
        <option key={idx} value={i.Key}>
          {i.Value}
        </option>
      );
    });
  }
  return optionsValues;
};
export const CommonLabel = ({
  name,
  required,
  tran,
  copytoclipboard,
  state,
}) => {
  return (
    <Fragment>
      <label className={required ? "requiredLabel" : "normalLabel"}>
        <Label tranVal={tran} isPlain={true} />
      </label>
      {copytoclipboard && (
        <button
          type="button"
          onClick={() => {
            CopyToClipboard(GetStateField(state, name));
          }}
          className="copyText"
        >
          <Label tranVal={"248"} />
        </button>
      )}
    </Fragment>
  );
};
export const Measurement = ({ min, max, maxLength, curVal, type }) => {
  if (type === "checkbox") {
    return <Fragment />;
  }
  return (
    <Fragment>
      {min && max ? (
        <label className="lengthCtl">
          &nbsp;{min}-{max}
        </label>
      ) : (
        <Fragment />
      )}
      {min && !max ? (
        <label className="lengthCtl">
          &nbsp;{curVal}&#62;&#61;{min}
        </label>
      ) : (
        <Fragment />
      )}
      {max && !min ? (
        <label className="lengthCtl">
          &nbsp;{curVal}&lt;&#61;{max}
        </label>
      ) : (
        <Fragment />
      )}
      {maxLength ? (
        <label className="lengthCtl">
          <span>{(curVal || "").length}</span>/{maxLength}
        </label>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
export const ValidateRequiredOrPattern = ({
  curVal,
  type,
  state,
  name,
  tran,
  required,
  pattern = null,
  defaultErrorMessage = null,
  iscombinationunique = null,
  combinations = null,
  languageCode,
}) => {
  const { data: tranList } = useQuery({
    queryKey: [Response_TranslationData],
  });
  if (
    (!required && !pattern) ||
    (!required && ["decimal", "number"].includes(type))
  ) {
    return <Fragment />;
  }
  let errorMessage = "";
  if (pattern && curVal) {
    const isValid =
      type === "multi-number" ? true : new RegExp(pattern).test(curVal);
    if (
      isValid &&
      type === "multi-number" &&
      IsTrue(iscombinationunique) &&
      uniq(curVal.replace(/--/g, "-").split("-")).length !==
        parseInt(combinations, 10)
    ) {
      errorMessage = 561;
    } else if (!isValid) {
      if (defaultErrorMessage) {
        errorMessage = defaultErrorMessage;
      }
    }
  } else if (required) {
    switch (type) {
      case "select-one":
        if (!IsTrue(curVal)) {
          errorMessage = 404;
        }
        break;
      default:
        if (!curVal) {
          errorMessage = 404;
        }
        break;
    }
  }
  let errorTranLabel = tran || name;
  errorTranLabel = isNaN(errorTranLabel)
    ? errorTranLabel
    : tranList[errorTranLabel];
  let errorDescription = isNaN(errorMessage)
    ? errorMessage
    : tranList[errorMessage];
  const GenerateErrorMessage = () => {
    const currentError = errorMessage
      ? `<b style='color:#FF0000;'>${errorTranLabel}</b> - ${errorDescription}<br />`
      : null;
    useEffect(() => {
      if (currentError !== GetStateField(`${state}-error`, name)) {
        UpdateStateField(`${state}-error`, name, currentError);
      }
      return function cleanup() {};
    }, [curVal]);
    return <Fragment />;
  };
  return (
    <span
      style={{
        color: !errorMessage ? "#008000" : "#FF0000",
        fontSize: "1rem",
        marginRight: "3px",
      }}
    >
      <GenerateErrorMessage />
      {curVal ? (
        <Label tranVal={!errorMessage ? "407" : "408"} isPlain={true} />
      ) : (
        <Fragment />
      )}
      <br />
    </span>
  );
};
const GetCombination = (setCurVal, curVal, e) => {
  const values = curVal.split("-");
  values[parseInt(e.target.getAttribute("index"), 10)] = e.target.value ?? "XX";
  setCurVal(values.join("-"));
};
export const InitalAttribute = (
  type,
  curVal,
  setCurVal,
  defaultnone,
  style,
  onChange,
  required,
  state,
  name,
  min = null,
  max = null,
  maxLength = null,
  customAttribute = {}
) => {
  if (customAttribute.title) {
    customAttribute.title = customAttribute.title.replace(/<[^>]*>/g, "");
  }
  const attr = {
    ...customAttribute,
    "aria-label": defaultnone,
    autoComplete: "off",
    dataentry: "",
    type: type === "multi-number" ? "number" : type,
    min,
    max,
    maxLength,
  };
  if (style) {
    attr["style"] = { ...style };
  } else {
    const generatedStyle = {};
    switch (type) {
      case "select":
        generatedStyle.width = "100%";
        break;
      case "checkbox":
      case "radio":
        break;
      case "textarea":
        generatedStyle.height = "100px";
        generatedStyle.width = "99%";
        break;
      case "address":
        generatedStyle.height = "100px";
        generatedStyle.width = "99%";
        break;
      case "autocomplete-single":
        generatedStyle.display = "none";
        break;
      default:
        generatedStyle.width = "99%";
        break;
    }
    attr["style"] = { ...generatedStyle, ...attr["style"] };
  }
  switch (type) {
    case "autocomplete-single":
    case "autocomplete":
      attr["className"] = "autofillInput";
      break;
    case "date":
      attr["onChange"] = (e) => {
        setCurVal(e.target.value);
        if (onChange) {
          onChange(e);
        }
      };
      break;
    case "password":
      attr["autoComplete"] = "new-password";
      break;
    case "number":
    case "multi-number":
    case "decimal":
      attr["onKeyDown"] = (e) => {
        SetState(CFW_CURRENTNUMBER, e.target.value);
        if (
          [46, 8, 9, 27, 13, 110, 189].filter((i) => e.keyCode === i).length >
            0 ||
          (e.keyCode >= 35 && e.keyCode <= 40) ||
          (e.keyCode === 65 && (e.ctrlKey || e.metaKey))
        ) {
          if (e.keyCode === 46 || e.keyCode === 8) {
            SetState("BackOrDelete", "1");
          }
          return;
        } else if (
          (type === "number" || type === "multi-number") &&
          (e.keyCode === 16 ||
            e.keyCode === 69 ||
            e.keyCode === 107 ||
            e.shiftKey ||
            e.metaKey ||
            (e.keyCode === 190 &&
              (!max || (max && parseFloat(e.target.value) < e.target.max))))
        ) {
          e.preventDefault();
        } else if (
          e.keyCode === 16 ||
          e.keyCode === 69 ||
          e.keyCode === 107 ||
          e.shiftKey ||
          e.metaKey ||
          (e.keyCode === 190 &&
            curVal.split(".").length > 1 &&
            (!max || (max && parseFloat(e.target.value) < e.target.max))) ||
          (maxLength && parseInt(maxLength, 10) <= e.target.value.length)
        ) {
          e.preventDefault();
        } else if (
          !(
            String.fromCharCode(e.keyCode).match(REGEXP_DECIMAL) ||
            e.keyCode === 190
          )
        ) {
          e.preventDefault();
        } else if (max && max < e.target.value) {
          e.preventDefault();
        }
      };
      if (min || max) {
        attr["onKeyUp"] = (e) => {
          if (e.target.value) {
            if (
              (min &&
                parseInt(!e.target.value ? "0" : e.target.value) <
                  parseInt(min, 10)) ||
              (max &&
                parseInt(!e.target.value ? "0" : e.target.value) >
                  parseInt(max, 10))
            ) {
              const validValue =
                e.target.value > max
                  ? Math.min(max, GetState(CFW_CURRENTNUMBER))
                  : Math.max(min, GetState(CFW_CURRENTNUMBER));
              e.target.value = validValue;
              if (type !== "multi-number") {
                setCurVal(validValue);
              }
            }
          }
          if (type === "multi-number") {
            GetCombination(setCurVal, curVal, e);
            setTimeout(() => {
              e.target.focus();
            }, 100);
          }
        };
      }
      attr["onChange"] = (e) => {
        const val = e.target.value;
        if (
          e.target.validity.valid ||
          (!e.target.validity.valid && parseFloat(val).toString() !== "NaN") ||
          GetState("BackOrDelete") === "1"
        ) {
          if (type === "multi-number") {
            GetCombination(setCurVal, curVal, e);
          } else {
            setCurVal(val);
          }
        } else {
          e.preventDefault();
        }
        RemoveState("BackOrDelete");
        if (onChange) {
          onChange(e);
        }
      };
      break;
  }
  if (
    !attr["onChange"] &&
    !["html", "multicheckbox", "attachment", "radio", "select"].includes(type)
  ) {
    attr["onChange"] = (e) => {
      if (["checkbox", "radio"].includes(type)) {
        const value = type === "checkbox" ? e.target.checked : e.target.value;
        UpdateStateField(state, name, value);
        setCurVal(value);
      } else {
        setCurVal(e.target.value);
      }
      if (onChange) {
        onChange(e);
      }
    };
  }
  return attr;
};

export const DataSimple = ({
  type,
  state,
  name,
  ctlRef = null,
  tran = null,
  required = null,
  copytoclipboard = null,
  onChange = null,
  maxLength = null,
  style = null,
  defaultnone = "137",
  min = null,
  disabled = null,
  max = null,
  customAttribute = {},
  buttonLookUp = null,
  placeholder = null,
  capture = null,
}) => {
  const location = useLocation();
  const { languageCode } = GetProductAndLanguage(location.pathname);
  const { data: tranList } = useQuery({
    queryKey: [Response_TranslationData],
  });
  const defaultvalue = GetStateField(state, name) ?? min ?? "";
  const [curVal, setCurVal] = useState(defaultvalue);
  const [isStateReady, setIsStateReady] = useState();
  const attr = InitalAttribute(
    type,
    curVal,
    setCurVal,
    defaultnone,
    style,
    onChange,
    required,
    state,
    name,
    min,
    max,
    maxLength,
    customAttribute
  );

  useEffect(() => {
    if (!isStateReady) {
      setTimeout(() => {
        setIsStateReady(true);
      }, 500);
    }
    if (capture) {
      SetState(capture, curVal);
    }
    return function cleanup() {};
  }, [curVal]);
  attr["onBlur"] = () => {
    if (isStateReady) {
      UpdateStateField(state, name, curVal);
    }
  };
  if (ctlRef) {
    attr["ref"] = ctlRef;
  }
  if (disabled) {
    attr["disabled"] = disabled;
  }
  if (placeholder) {
    attr["placeholder"] = isNaN(placeholder)
      ? placeholder
      : tranList[placeholder];
  }
  switch (type) {
    case "checkbox":
      attr["defaultChecked"] = curVal;
      break;
    case "date":
      attr["valueasdate"] = curVal ? new Date(curVal) : "";
      attr["dateval"] = curVal ? GetFormattedDate(attr["valueasdate"]) : "";
      attr["defaultValue"] = attr["valueasdate"]
        ? attr["valueasdate"].toISOString().slice(0, 10)
        : "";
      break;
    default:
      attr["defaultValue"] = curVal;
      break;
  }
  const DetectCtlRefChange = () => {
    const stateRefresher = `${state}-${name}-${CFW_REFRESHER}`;
    const refresher = useSelector((state) => GetResults(state[stateRefresher]));
    useEffect(() => {
      const currentValue = GetStateField(state, name);
      if (["autocomplete-single"].includes(type)) {
        if (currentValue !== curVal) {
          setCurVal(currentValue);
        }
      }
      return function cleanup() {};
    }, [refresher]);
    return <Fragment />;
  };
  return (
    <Fragment>
      <br />
      {tran && (
        <CommonLabel
          name={name}
          state={state}
          required={required}
          tran={tran}
          copytoclipboard={copytoclipboard}
        />
      )}
      {buttonLookUp && (
        <button
          type="button"
          onClick={buttonLookUp}
          className={`${
            type.includes("autocomplete") ? "search" : "map"
          } miniButton`}
        />
      )}
      {type === "checkbox" && <br />}
      {["textarea", "address"].includes(type) ? (
        <textarea {...attr} />
      ) : (
        <input {...attr} />
      )}
      {((min || max || maxLength) && !["checkbox"].includes(type)) ||
      required ||
      attr["pattern"] ||
      !(!required && ["decimal", "number"].includes(type)) ? (
        <div>
          <Measurement
            min={min}
            max={max}
            curVal={curVal}
            maxLength={maxLength}
            type={type}
          />
          <ValidateRequiredOrPattern
            curVal={curVal}
            tran={tran}
            type={type}
            name={name}
            state={state}
            required={required}
            pattern={attr["pattern"]}
            defaultErrorMessage={attr["title"]}
            languageCode={languageCode}
          />
        </div>
      ) : (
        <Fragment />
      )}
      <br />
      {ctlRef?.current ? <DetectCtlRefChange /> : <Fragment />}
    </Fragment>
  );
};
