export const InitializeCustomWorker = (successEvent, errorEvent) => {
  const worker = new Worker(/* webpackChunkName: "Worker" */new URL("/Worker.js", import.meta.url), {
    type: "module",
    credentials: "same-origin"
  });
  worker.onmessage = results => {
    worker.terminate();
    if (successEvent) {
      successEvent(results.data);
    }
  };
  worker.onerror = results => {
    worker.terminate();
    if (errorEvent) {
      errorEvent(results);
    }
  };
  worker.onmessageerror = results => {
    worker.terminate();
    if (errorEvent) {
      errorEvent(results);
    }
  };
  return {
    worker,
    payload: function (action, requestOptions, responseType) {
      if (responseType === void 0) {
        responseType = 0;
      }
      return {
        action,
        requestOptions,
        responseType
      };
    }
  };
};