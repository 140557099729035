const CountriesData = [{
  CountryId: 235,
  CountryCode: "CV",
  CountryName: "Cabo Verde",
  ContinentName: "Africa",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "kea,pt",
  GoogleAdsCriteriaId: 2132
}, {
  CountryId: 245,
  CountryCode: "CD",
  CountryName: "Congo, Democratic Republic of the",
  ContinentName: "Africa",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "ln,lu,sw",
  GoogleAdsCriteriaId: 2180
}, {
  CountryId: 246,
  CountryCode: "CG",
  CountryName: "Congo, Republic of the",
  ContinentName: "Africa",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "ln,lu,sw",
  GoogleAdsCriteriaId: 2178
}, {
  CountryId: 248,
  CountryCode: "CI",
  CountryName: "Cote d'Ivoire",
  ContinentName: "Africa",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr",
  GoogleAdsCriteriaId: 2384
}, {
  CountryId: 331,
  CountryCode: "MZ",
  CountryName: "Mozambique",
  ContinentName: "Africa",
  CurrencySymbol: "MTn",
  PaypalCurrency: "MZN",
  LanguagesSpoken: "mgh,pt,seh",
  GoogleAdsCriteriaId: 2508
}, {
  CountryId: 332,
  CountryCode: "NA",
  CountryName: "Namibia",
  ContinentName: "Africa",
  CurrencySymbol: "N$",
  PaypalCurrency: "NAD",
  LanguagesSpoken: "en,naq",
  GoogleAdsCriteriaId: 2516
}, {
  CountryId: 338,
  CountryCode: "NE",
  CountryName: "Niger",
  ContinentName: "Africa",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr,ff,ha,twq,dje",
  GoogleAdsCriteriaId: 2562
}, {
  CountryId: 339,
  CountryCode: "NG",
  CountryName: "Nigeria",
  ContinentName: "Africa",
  CurrencySymbol: "₦",
  PaypalCurrency: "NGN",
  LanguagesSpoken: "en,ff,ha,ig,kaj,kcg,yo",
  GoogleAdsCriteriaId: 2566
}, {
  CountryId: 356,
  CountryCode: "RW",
  CountryName: "Rwanda",
  ContinentName: "Africa",
  CurrencySymbol: "₣",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,fr,rw",
  GoogleAdsCriteriaId: 2646
}, {
  CountryId: 362,
  CountryCode: "ST",
  CountryName: "Sao Tome and Principe",
  ContinentName: "Africa",
  CurrencySymbol: "Db",
  PaypalCurrency: "US",
  LanguagesSpoken: "pt",
  GoogleAdsCriteriaId: 2678
}, {
  CountryId: 364,
  CountryCode: "SN",
  CountryName: "Senegal",
  ContinentName: "Africa",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr,ff,dyo,wo",
  GoogleAdsCriteriaId: 2686
}, {
  CountryId: 366,
  CountryCode: "SC",
  CountryName: "Seychelles",
  ContinentName: "Africa",
  CurrencySymbol: "",
  PaypalCurrency: "SCR",
  LanguagesSpoken: "en,fr",
  GoogleAdsCriteriaId: 2690
}, {
  CountryId: 367,
  CountryCode: "SL",
  CountryName: "Sierra Leone",
  ContinentName: "Africa",
  CurrencySymbol: "Le",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,ff",
  GoogleAdsCriteriaId: 2694
}, {
  CountryId: 373,
  CountryCode: "SO",
  CountryName: "Somalia",
  ContinentName: "Africa",
  CurrencySymbol: "Sh",
  PaypalCurrency: "SOS",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2706
}, {
  CountryId: 374,
  CountryCode: "ZA",
  CountryName: "South Africa",
  ContinentName: "Africa",
  CurrencySymbol: "R",
  PaypalCurrency: "ZAR",
  LanguagesSpoken: "af,en,nso,nr,st,ss,ts,tn,ve,xh,zu",
  GoogleAdsCriteriaId: 2710
}, {
  CountryId: 376,
  CountryCode: "SD",
  CountryName: "South Sudan",
  ContinentName: "Africa",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar,en,nus",
  GoogleAdsCriteriaId: 2736
}, {
  CountryId: 379,
  CountryCode: "SD",
  CountryName: "Sudan",
  ContinentName: "Africa",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar,en",
  GoogleAdsCriteriaId: 2736
}, {
  CountryId: 381,
  CountryCode: "SZ",
  CountryName: "Swaziland",
  ContinentName: "Africa",
  CurrencySymbol: "L",
  PaypalCurrency: "US",
  LanguagesSpoken: "",
  GoogleAdsCriteriaId: 2748
}, {
  CountryId: 387,
  CountryCode: "TZ",
  CountryName: "Tanzania",
  ContinentName: "Africa",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "asa,bez,en,lag,jmc,kde,mas,rof,rwk,sbp,ksb,sw,vun",
  GoogleAdsCriteriaId: 2834
}, {
  CountryId: 389,
  CountryCode: "TL",
  CountryName: "Timor-Leste",
  ContinentName: "Africa",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "pt",
  GoogleAdsCriteriaId: 2626
}, {
  CountryId: 390,
  CountryCode: "TG",
  CountryName: "Togo",
  ContinentName: "Africa",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "ee,fr",
  GoogleAdsCriteriaId: 2768
}, {
  CountryId: 393,
  CountryCode: "TN",
  CountryName: "Tunisia",
  ContinentName: "Africa",
  CurrencySymbol: "د.ت",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2788
}, {
  CountryId: 397,
  CountryCode: "UG",
  CountryName: "Uganda",
  ContinentName: "Africa",
  CurrencySymbol: "Sh",
  PaypalCurrency: "US",
  LanguagesSpoken: "cgg,en,lg,nyn,xog,sw,teo",
  GoogleAdsCriteriaId: 2800
}, {
  CountryId: 407,
  CountryCode: "ZM",
  CountryName: "Zambia",
  ContinentName: "Africa",
  CurrencySymbol: "ZK",
  PaypalCurrency: "US",
  LanguagesSpoken: "bem,en",
  GoogleAdsCriteriaId: 2894
}, {
  CountryId: 408,
  CountryCode: "ZW",
  CountryName: "Zimbabwe",
  ContinentName: "Africa",
  CurrencySymbol: "$",
  PaypalCurrency: "ZWD",
  LanguagesSpoken: "en,nd,sn",
  GoogleAdsCriteriaId: 2716
}, {
  CountryId: 205,
  CountryCode: "AF",
  CountryName: "Afghanistan",
  ContinentName: "Asia",
  CurrencySymbol: "Af",
  PaypalCurrency: "AFN",
  LanguagesSpoken: "ps,af,uz",
  GoogleAdsCriteriaId: 2004
}, {
  CountryId: 207,
  CountryCode: "DZ",
  CountryName: "Algeria",
  ContinentName: "Asia",
  CurrencySymbol: "د.ج",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar,fr",
  GoogleAdsCriteriaId: 2012
}, {
  CountryId: 209,
  CountryCode: "AO",
  CountryName: "Angola",
  ContinentName: "Asia",
  CurrencySymbol: "Kz",
  PaypalCurrency: "US",
  LanguagesSpoken: "ln,pt",
  GoogleAdsCriteriaId: 2024
}, {
  CountryId: 218,
  CountryCode: "BH",
  CountryName: "Bahrain",
  ContinentName: "Asia",
  CurrencySymbol: "ب.د",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2048
}, {
  CountryId: 219,
  CountryCode: "BD",
  CountryName: "Bangladesh",
  ContinentName: "Asia",
  CurrencySymbol: "৳",
  PaypalCurrency: "US",
  LanguagesSpoken: "bn,en",
  GoogleAdsCriteriaId: 2050
}, {
  CountryId: 224,
  CountryCode: "BJ",
  CountryName: "Benin",
  ContinentName: "Asia",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr",
  GoogleAdsCriteriaId: 2204
}, {
  CountryId: 225,
  CountryCode: "BT",
  CountryName: "Bhutan",
  ContinentName: "Asia",
  CurrencySymbol: "Nu.",
  PaypalCurrency: "US",
  LanguagesSpoken: "dz",
  GoogleAdsCriteriaId: 2064
}, {
  CountryId: 228,
  CountryCode: "BW",
  CountryName: "Botswana",
  ContinentName: "Asia",
  CurrencySymbol: "P",
  PaypalCurrency: "BWP",
  LanguagesSpoken: "en,tn",
  GoogleAdsCriteriaId: 2072
}, {
  CountryId: 230,
  CountryCode: "BN",
  CountryName: "Brunei",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "BND",
  LanguagesSpoken: "ms",
  GoogleAdsCriteriaId: 2096
}, {
  CountryId: 232,
  CountryCode: "BF",
  CountryName: "Burkina Faso",
  ContinentName: "Asia",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr,ff",
  GoogleAdsCriteriaId: 2854
}, {
  CountryId: 233,
  CountryCode: "MM",
  CountryName: "Burma",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "my,en",
  GoogleAdsCriteriaId: 2104
}, {
  CountryId: 234,
  CountryCode: "BI",
  CountryName: "Burundi",
  ContinentName: "Asia",
  CurrencySymbol: "₣",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,fr",
  GoogleAdsCriteriaId: 2108
}, {
  CountryId: 236,
  CountryCode: "KH",
  CountryName: "Cambodia",
  ContinentName: "Asia",
  CurrencySymbol: "៛",
  PaypalCurrency: "KHR",
  LanguagesSpoken: "km",
  GoogleAdsCriteriaId: 2116
}, {
  CountryId: 237,
  CountryCode: "CM",
  CountryName: "Cameroon",
  ContinentName: "Asia",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "dua,en,ewo,fr,ff,kkj,nmg,mgo,mua,nnh,jgo,yav",
  GoogleAdsCriteriaId: 2120
}, {
  CountryId: 239,
  CountryCode: "CF",
  CountryName: "Central African Republic",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr,ln,sg",
  GoogleAdsCriteriaId: 2140
}, {
  CountryId: 240,
  CountryCode: "TD",
  CountryName: "Chad",
  ContinentName: "Asia",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar,fr",
  GoogleAdsCriteriaId: 2148
}, {
  CountryId: 242,
  CountryCode: "CN",
  CountryName: "China",
  ContinentName: "Asia",
  CurrencySymbol: "¥",
  PaypalCurrency: "CNY",
  LanguagesSpoken: "yue,zh,en,ii,bo,ug",
  GoogleAdsCriteriaId: 2156
}, {
  CountryId: 244,
  CountryCode: "KM",
  CountryName: "Comoros",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr",
  GoogleAdsCriteriaId: 2174
}, {
  CountryId: 255,
  CountryCode: "DJ",
  CountryName: "Djibouti",
  ContinentName: "Asia",
  CurrencySymbol: "₣",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar,fr,so",
  GoogleAdsCriteriaId: 2262
}, {
  CountryId: 260,
  CountryCode: "EG",
  CountryName: "Egypt",
  ContinentName: "Asia",
  CurrencySymbol: "£",
  PaypalCurrency: "EGP",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2818
}, {
  CountryId: 262,
  CountryCode: "GQ",
  CountryName: "Equatorial Guinea",
  ContinentName: "Asia",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr,pt,es",
  GoogleAdsCriteriaId: 2226
}, {
  CountryId: 263,
  CountryCode: "ER",
  CountryName: "Eritrea",
  ContinentName: "Asia",
  CurrencySymbol: "Nfk",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar,byn,en,gez,tig,ti",
  GoogleAdsCriteriaId: 2232
}, {
  CountryId: 265,
  CountryCode: "ET",
  CountryName: "Ethiopia",
  ContinentName: "Asia",
  CurrencySymbol: "Br",
  PaypalCurrency: "US",
  LanguagesSpoken: "am,gez,om,so,ti,wal",
  GoogleAdsCriteriaId: 2231
}, {
  CountryId: 269,
  CountryCode: "GA",
  CountryName: "Gabon",
  ContinentName: "Asia",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr",
  GoogleAdsCriteriaId: 2266
}, {
  CountryId: 270,
  CountryCode: "GM",
  CountryName: "Gambia, The",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,ff",
  GoogleAdsCriteriaId: 2270
}, {
  CountryId: 273,
  CountryCode: "GH",
  CountryName: "Ghana",
  ContinentName: "Asia",
  CurrencySymbol: "₵",
  PaypalCurrency: "GHS",
  LanguagesSpoken: "ak,en,ee,ff,gaa,ha",
  GoogleAdsCriteriaId: 2288
}, {
  CountryId: 277,
  CountryCode: "GN",
  CountryName: "Guinea",
  ContinentName: "Asia",
  CurrencySymbol: "₣",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr,ff,kpe,nqo",
  GoogleAdsCriteriaId: 2324
}, {
  CountryId: 278,
  CountryCode: "GW",
  CountryName: "Guinea-Bissau",
  ContinentName: "Asia",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "ff,pt",
  GoogleAdsCriteriaId: 2624
}, {
  CountryId: 283,
  CountryCode: "HK",
  CountryName: "Hong Kong",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "HKD",
  LanguagesSpoken: "yue,zh,en",
  GoogleAdsCriteriaId: 0
}, {
  CountryId: 286,
  CountryCode: "IN",
  CountryName: "India",
  ContinentName: "Asia",
  CurrencySymbol: "₨",
  PaypalCurrency: "INR",
  LanguagesSpoken: "as,bn,brx,ccp,en,gu,hi,kn,ks,kok,ml,mni,mr,ne,or,pa,sa,sat,te,ta,bo,ur",
  GoogleAdsCriteriaId: 2356
}, {
  CountryId: 287,
  CountryCode: "ID",
  CountryName: "Indonesia",
  ContinentName: "Asia",
  CurrencySymbol: "Rp",
  PaypalCurrency: "IDR",
  LanguagesSpoken: "en,id,jv",
  GoogleAdsCriteriaId: 2360
}, {
  CountryId: 288,
  CountryCode: "IR",
  CountryName: "Iran",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "IRR",
  LanguagesSpoken: "ckb,mzn,lrc,fa",
  GoogleAdsCriteriaId: 0
}, {
  CountryId: 289,
  CountryCode: "IQ",
  CountryName: "Iraq",
  ContinentName: "Asia",
  CurrencySymbol: "ع.د",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar,ckb,lrc,syr",
  GoogleAdsCriteriaId: 2368
}, {
  CountryId: 291,
  CountryCode: "IL",
  CountryName: "Israel",
  ContinentName: "Asia",
  CurrencySymbol: "₪",
  PaypalCurrency: "ILS",
  LanguagesSpoken: "ar,en,he",
  GoogleAdsCriteriaId: 2376
}, {
  CountryId: 294,
  CountryCode: "JP",
  CountryName: "Japan",
  ContinentName: "Asia",
  CurrencySymbol: "¥",
  PaypalCurrency: "JPY",
  LanguagesSpoken: "en,ja",
  GoogleAdsCriteriaId: 2392
}, {
  CountryId: 295,
  CountryCode: "JO",
  CountryName: "Jordan",
  ContinentName: "Asia",
  CurrencySymbol: "د.ا",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2400
}, {
  CountryId: 297,
  CountryCode: "KE",
  CountryName: "Kenya",
  ContinentName: "Asia",
  CurrencySymbol: "Sh",
  PaypalCurrency: "US",
  LanguagesSpoken: "ebu,en,guz,kln,kam,ki,luo,luy,mas,mer,om,saq,so,sq,dav,teo",
  GoogleAdsCriteriaId: 2404
}, {
  CountryId: 299,
  CountryCode: "KN",
  CountryName: "Korea, North",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "KPW",
  LanguagesSpoken: "en,ko",
  GoogleAdsCriteriaId: 2659
}, {
  CountryId: 300,
  CountryCode: "KR",
  CountryName: "Korea, South",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "KRW",
  LanguagesSpoken: "en,ko",
  GoogleAdsCriteriaId: 2410
}, {
  CountryId: 302,
  CountryCode: "KW",
  CountryName: "Kuwait",
  ContinentName: "Asia",
  CurrencySymbol: "د.ك",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2414
}, {
  CountryId: 303,
  CountryCode: "KG",
  CountryName: "Kyrgyzstan",
  ContinentName: "Asia",
  CurrencySymbol: "сом",
  PaypalCurrency: "KGS",
  LanguagesSpoken: "ky,ru",
  GoogleAdsCriteriaId: 2417
}, {
  CountryId: 304,
  CountryCode: "LA",
  CountryName: "Laos",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "LAK",
  LanguagesSpoken: "lo",
  GoogleAdsCriteriaId: 2418
}, {
  CountryId: 306,
  CountryCode: "LB",
  CountryName: "Lebanon",
  ContinentName: "Asia",
  CurrencySymbol: "£",
  PaypalCurrency: "LBP",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2422
}, {
  CountryId: 307,
  CountryCode: "LS",
  CountryName: "Lesotho",
  ContinentName: "Asia",
  CurrencySymbol: "M",
  PaypalCurrency: "US",
  LanguagesSpoken: "st",
  GoogleAdsCriteriaId: 2426
}, {
  CountryId: 308,
  CountryCode: "LR",
  CountryName: "Liberia",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "LRD",
  LanguagesSpoken: "en,ff,kpe,vai",
  GoogleAdsCriteriaId: 2430
}, {
  CountryId: 309,
  CountryCode: "LY",
  CountryName: "Libya",
  ContinentName: "Asia",
  CurrencySymbol: "ل.د",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2434
}, {
  CountryId: 313,
  CountryCode: "",
  CountryName: "Macau",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "zh,en,pt",
  GoogleAdsCriteriaId: 0
}, {
  CountryId: 315,
  CountryCode: "MG",
  CountryName: "Madagascar",
  ContinentName: "Asia",
  CurrencySymbol: "MK",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,fr,mg",
  GoogleAdsCriteriaId: 2450
}, {
  CountryId: 316,
  CountryCode: "MW",
  CountryName: "Malawi",
  ContinentName: "Asia",
  CurrencySymbol: "K",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,ny",
  GoogleAdsCriteriaId: 2454
}, {
  CountryId: 317,
  CountryCode: "MY",
  CountryName: "Malaysia",
  ContinentName: "Asia",
  CurrencySymbol: "RM",
  PaypalCurrency: "MYR",
  LanguagesSpoken: "en,ms,ta",
  GoogleAdsCriteriaId: 2458
}, {
  CountryId: 318,
  CountryCode: "MV",
  CountryName: "Maldives",
  ContinentName: "Asia",
  CurrencySymbol: "ރ.",
  PaypalCurrency: "US",
  LanguagesSpoken: "dn,ev",
  GoogleAdsCriteriaId: 2462
}, {
  CountryId: 319,
  CountryCode: "ML",
  CountryName: "Mali",
  ContinentName: "Asia",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "bm,fr,khq",
  GoogleAdsCriteriaId: 2466
}, {
  CountryId: 322,
  CountryCode: "MR",
  CountryName: "Mauritania",
  ContinentName: "Asia",
  CurrencySymbol: "UM",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar,fr,ff",
  GoogleAdsCriteriaId: 2478
}, {
  CountryId: 323,
  CountryCode: "MU",
  CountryName: "Mauritius",
  ContinentName: "Asia",
  CurrencySymbol: "₨",
  PaypalCurrency: "MUR",
  LanguagesSpoken: "en,fr,mfe",
  GoogleAdsCriteriaId: 2480
}, {
  CountryId: 328,
  CountryCode: "MN",
  CountryName: "Mongolia",
  ContinentName: "Asia",
  CurrencySymbol: "₮",
  PaypalCurrency: "MNT",
  LanguagesSpoken: "mn",
  GoogleAdsCriteriaId: 2496
}, {
  CountryId: 330,
  CountryCode: "MA",
  CountryName: "Morocco",
  ContinentName: "Asia",
  CurrencySymbol: "د.م.",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar,tzm,fr,zgh,shi",
  GoogleAdsCriteriaId: 2504
}, {
  CountryId: 334,
  CountryCode: "NP",
  CountryName: "Nepal",
  ContinentName: "Asia",
  CurrencySymbol: "₨",
  PaypalCurrency: "NPR",
  LanguagesSpoken: "ne",
  GoogleAdsCriteriaId: 2524
}, {
  CountryId: 340,
  CountryCode: "KR",
  CountryName: "North Korea",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "ko",
  GoogleAdsCriteriaId: 2410
}, {
  CountryId: 342,
  CountryCode: "OM",
  CountryName: "Oman",
  ContinentName: "Asia",
  CurrencySymbol: "ر.ع.",
  PaypalCurrency: "OMR",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2512
}, {
  CountryId: 343,
  CountryCode: "PK",
  CountryName: "Pakistan",
  ContinentName: "Asia",
  CurrencySymbol: "₨",
  PaypalCurrency: "PKR",
  LanguagesSpoken: "en,ps,pa,sd,ur",
  GoogleAdsCriteriaId: 2586
}, {
  CountryId: 345,
  CountryCode: "PS",
  CountryName: "Palestinian Territories",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 0
}, {
  CountryId: 350,
  CountryCode: "PH",
  CountryName: "Philippines",
  ContinentName: "Asia",
  CurrencySymbol: "₱",
  PaypalCurrency: "PHP",
  LanguagesSpoken: "tl,en",
  GoogleAdsCriteriaId: 2608
}, {
  CountryId: 353,
  CountryCode: "QA",
  CountryName: "Qatar",
  ContinentName: "Asia",
  CurrencySymbol: "ر.ق",
  PaypalCurrency: "QAR",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2634
}, {
  CountryId: 363,
  CountryCode: "SA",
  CountryName: "Saudi Arabia",
  ContinentName: "Asia",
  CurrencySymbol: "ر.س",
  PaypalCurrency: "SAR",
  LanguagesSpoken: "ar,en",
  GoogleAdsCriteriaId: 2682
}, {
  CountryId: 368,
  CountryCode: "SG",
  CountryName: "Singapore",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "SGD",
  LanguagesSpoken: "zh,en,ms,ta",
  GoogleAdsCriteriaId: 2702
}, {
  CountryId: 375,
  CountryCode: "KR",
  CountryName: "South Korea",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,ko",
  GoogleAdsCriteriaId: 2410
}, {
  CountryId: 378,
  CountryCode: "LK",
  CountryName: "Sri Lanka",
  ContinentName: "Asia",
  CurrencySymbol: "Rs",
  PaypalCurrency: "LKR",
  LanguagesSpoken: "si,ta",
  GoogleAdsCriteriaId: 2144
}, {
  CountryId: 384,
  CountryCode: "SY",
  CountryName: "Syria",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "SYP",
  LanguagesSpoken: "ar,fyr,sr",
  GoogleAdsCriteriaId: 0
}, {
  CountryId: 385,
  CountryCode: "TW",
  CountryName: "Taiwan",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "TWD",
  LanguagesSpoken: "zh,en,trv",
  GoogleAdsCriteriaId: 0
}, {
  CountryId: 386,
  CountryCode: "TJ",
  CountryName: "Tajikistan",
  ContinentName: "Asia",
  CurrencySymbol: "ЅМ",
  PaypalCurrency: "US",
  LanguagesSpoken: "tg",
  GoogleAdsCriteriaId: 2762
}, {
  CountryId: 388,
  CountryCode: "TH",
  CountryName: "Thailand",
  ContinentName: "Asia",
  CurrencySymbol: "฿",
  PaypalCurrency: "THB",
  LanguagesSpoken: "en,th",
  GoogleAdsCriteriaId: 2764
}, {
  CountryId: 395,
  CountryCode: "TM",
  CountryName: "Turkmenistan",
  ContinentName: "Asia",
  CurrencySymbol: "m",
  PaypalCurrency: "US",
  LanguagesSpoken: "tk",
  GoogleAdsCriteriaId: 2795
}, {
  CountryId: 399,
  CountryCode: "AE",
  CountryName: "United Arab Emirates",
  ContinentName: "Asia",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "ar,en",
  GoogleAdsCriteriaId: 2784
}, {
  CountryId: 402,
  CountryCode: "UZ",
  CountryName: "Uzbekistan",
  ContinentName: "Asia",
  CurrencySymbol: "",
  PaypalCurrency: "UZS",
  LanguagesSpoken: "uz",
  GoogleAdsCriteriaId: 2860
}, {
  CountryId: 405,
  CountryCode: "VN",
  CountryName: "Vietnam",
  ContinentName: "Asia",
  CurrencySymbol: "₫",
  PaypalCurrency: "VND",
  LanguagesSpoken: "vi",
  GoogleAdsCriteriaId: 2704
}, {
  CountryId: 406,
  CountryCode: "YE",
  CountryName: "Yemen",
  ContinentName: "Asia",
  CurrencySymbol: "﷼",
  PaypalCurrency: "YER",
  LanguagesSpoken: "ar",
  GoogleAdsCriteriaId: 2887
}, {
  CountryId: 214,
  CountryCode: "AU",
  CountryName: "Australia",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "$",
  PaypalCurrency: "AUD",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2036
}, {
  CountryId: 266,
  CountryCode: "FJ",
  CountryName: "Fiji",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "$",
  PaypalCurrency: "FJD",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2242
}, {
  CountryId: 298,
  CountryCode: "KI",
  CountryName: "Kiribati",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "L$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2296
}, {
  CountryId: 321,
  CountryCode: "MH",
  CountryName: "Marshall Islands",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2584
}, {
  CountryId: 325,
  CountryCode: "FM",
  CountryName: "Micronesia",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2583
}, {
  CountryId: 333,
  CountryCode: "NR",
  CountryName: "Nauru",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2520
}, {
  CountryId: 336,
  CountryCode: "NZ",
  CountryName: "New Zealand",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "$",
  PaypalCurrency: "NZD",
  LanguagesSpoken: "en,mi",
  GoogleAdsCriteriaId: 2554
}, {
  CountryId: 344,
  CountryCode: "PW",
  CountryName: "Palau",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2585
}, {
  CountryId: 347,
  CountryCode: "PG",
  CountryName: "Papua New Guinea",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "K",
  PaypalCurrency: "US",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2598
}, {
  CountryId: 360,
  CountryCode: "WS",
  CountryName: "Samoa",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "WS$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2882
}, {
  CountryId: 372,
  CountryCode: "SB",
  CountryName: "Solomon Islands",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "$",
  PaypalCurrency: "SBD",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2090
}, {
  CountryId: 391,
  CountryCode: "TO",
  CountryName: "Tonga",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "T$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,to",
  GoogleAdsCriteriaId: 2776
}, {
  CountryId: 396,
  CountryCode: "TV",
  CountryName: "Tuvalu",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "$",
  PaypalCurrency: "TVD",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2798
}, {
  CountryId: 403,
  CountryCode: "VU",
  CountryName: "Vanuatu",
  ContinentName: "Australia/Oceania",
  CurrencySymbol: "Vt",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,fr",
  GoogleAdsCriteriaId: 2548
}, {
  CountryId: 206,
  CountryCode: "AL",
  CountryName: "Albania",
  ContinentName: "Europe",
  CurrencySymbol: "L",
  PaypalCurrency: "ALL",
  LanguagesSpoken: "sq,en",
  GoogleAdsCriteriaId: 2008
}, {
  CountryId: 208,
  CountryCode: "AD",
  CountryName: "Andorra",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "US",
  LanguagesSpoken: "kab,fr,ar",
  GoogleAdsCriteriaId: 2020
}, {
  CountryId: 212,
  CountryCode: "AM",
  CountryName: "Armenia",
  ContinentName: "Europe",
  CurrencySymbol: "Դ",
  PaypalCurrency: "US",
  LanguagesSpoken: "hy",
  GoogleAdsCriteriaId: 2051
}, {
  CountryId: 215,
  CountryCode: "AT",
  CountryName: "Austria",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,de",
  GoogleAdsCriteriaId: 2040
}, {
  CountryId: 216,
  CountryCode: "AZ",
  CountryName: "Azerbaijan",
  ContinentName: "Europe",
  CurrencySymbol: "ман",
  PaypalCurrency: "US",
  LanguagesSpoken: "az",
  GoogleAdsCriteriaId: 2031
}, {
  CountryId: 221,
  CountryCode: "BY",
  CountryName: "Belarus",
  ContinentName: "Europe",
  CurrencySymbol: "Br",
  PaypalCurrency: "US",
  LanguagesSpoken: "ru,be",
  GoogleAdsCriteriaId: 2112
}, {
  CountryId: 222,
  CountryCode: "BE",
  CountryName: "Belgium",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "US",
  LanguagesSpoken: "nl,en",
  GoogleAdsCriteriaId: 2056
}, {
  CountryId: 227,
  CountryCode: "BA",
  CountryName: "Bosnia and Herzegovina",
  ContinentName: "Europe",
  CurrencySymbol: "КМ",
  PaypalCurrency: "BAM",
  LanguagesSpoken: "bs,hr,en,sr",
  GoogleAdsCriteriaId: 2070
}, {
  CountryId: 231,
  CountryCode: "BG",
  CountryName: "Bulgaria",
  ContinentName: "Europe",
  CurrencySymbol: "лв",
  PaypalCurrency: "BGN",
  LanguagesSpoken: "bg,en",
  GoogleAdsCriteriaId: 2100
}, {
  CountryId: 249,
  CountryCode: "HR",
  CountryName: "Croatia",
  ContinentName: "Europe",
  CurrencySymbol: "Kn",
  PaypalCurrency: "HRK",
  LanguagesSpoken: "hr",
  GoogleAdsCriteriaId: 2191
}, {
  CountryId: 252,
  CountryCode: "CY",
  CountryName: "Cyprus",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,el",
  GoogleAdsCriteriaId: 2196
}, {
  CountryId: 253,
  CountryCode: "CZ",
  CountryName: "Czechia",
  ContinentName: "Europe",
  CurrencySymbol: "$",
  PaypalCurrency: "CZK",
  LanguagesSpoken: "cs,en",
  GoogleAdsCriteriaId: 2203
}, {
  CountryId: 254,
  CountryCode: "DK",
  CountryName: "Denmark",
  ContinentName: "Europe",
  CurrencySymbol: "kr",
  PaypalCurrency: "DKK",
  LanguagesSpoken: "da,en",
  GoogleAdsCriteriaId: 2208
}, {
  CountryId: 258,
  CountryCode: "TL",
  CountryName: "East Timor (see Timor-Leste)",
  ContinentName: "Europe",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "pt",
  GoogleAdsCriteriaId: 2626
}, {
  CountryId: 264,
  CountryCode: "EE",
  CountryName: "Estonia",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,et",
  GoogleAdsCriteriaId: 2233
}, {
  CountryId: 267,
  CountryCode: "FI",
  CountryName: "Finland",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "en,fi,smn,se,sv",
  GoogleAdsCriteriaId: 2246
}, {
  CountryId: 268,
  CountryCode: "FR",
  CountryName: "France",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "br,ca,co,en,fr,oc,pt",
  GoogleAdsCriteriaId: 2250
}, {
  CountryId: 271,
  CountryCode: "GE",
  CountryName: "Georgia",
  ContinentName: "Europe",
  CurrencySymbol: "ლ",
  PaypalCurrency: "US",
  LanguagesSpoken: "ka,os",
  GoogleAdsCriteriaId: 2268
}, {
  CountryId: 272,
  CountryCode: "DE",
  CountryName: "Germany",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "en,de,nds,dsb,hsb",
  GoogleAdsCriteriaId: 2276
}, {
  CountryId: 274,
  CountryCode: "GR",
  CountryName: "Greece",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "en,el",
  GoogleAdsCriteriaId: 2300
}, {
  CountryId: 284,
  CountryCode: "HU",
  CountryName: "Hungary",
  ContinentName: "Europe",
  CurrencySymbol: "Ft",
  PaypalCurrency: "HUF",
  LanguagesSpoken: "en,hu",
  GoogleAdsCriteriaId: 2348
}, {
  CountryId: 285,
  CountryCode: "IS",
  CountryName: "Iceland",
  ContinentName: "Europe",
  CurrencySymbol: "Kr",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,is",
  GoogleAdsCriteriaId: 2352
}, {
  CountryId: 290,
  CountryCode: "IE",
  CountryName: "Ireland",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "en,ga",
  GoogleAdsCriteriaId: 2372
}, {
  CountryId: 292,
  CountryCode: "IT",
  CountryName: "Italy",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "ca,fur,de,it,sc,scn",
  GoogleAdsCriteriaId: 2380
}, {
  CountryId: 296,
  CountryCode: "KZ",
  CountryName: "Kazakhstan",
  ContinentName: "Europe",
  CurrencySymbol: "〒",
  PaypalCurrency: "KZT",
  LanguagesSpoken: "kk,ru",
  GoogleAdsCriteriaId: 2398
}, {
  CountryId: 301,
  CountryCode: "XK",
  CountryName: "Kosovo",
  ContinentName: "Europe",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "sq,sr",
  GoogleAdsCriteriaId: 0
}, {
  CountryId: 305,
  CountryCode: "LV",
  CountryName: "Latvia",
  ContinentName: "Europe",
  CurrencySymbol: "Ls",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,lv",
  GoogleAdsCriteriaId: 2428
}, {
  CountryId: 310,
  CountryCode: "LI",
  CountryName: "Liechtenstein",
  ContinentName: "Europe",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "de,gsw",
  GoogleAdsCriteriaId: 2438
}, {
  CountryId: 311,
  CountryCode: "LT",
  CountryName: "Lithuania",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "en,lt",
  GoogleAdsCriteriaId: 2440
}, {
  CountryId: 312,
  CountryCode: "LU",
  CountryName: "Luxembourg",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "fr,de,lb,pt",
  GoogleAdsCriteriaId: 2442
}, {
  CountryId: 314,
  CountryCode: "MK",
  CountryName: "Macedonia",
  ContinentName: "Europe",
  CurrencySymbol: "$",
  PaypalCurrency: "MKD",
  LanguagesSpoken: "sq,mk",
  GoogleAdsCriteriaId: 2807
}, {
  CountryId: 320,
  CountryCode: "MT",
  CountryName: "Malta",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "en,mt",
  GoogleAdsCriteriaId: 2470
}, {
  CountryId: 326,
  CountryCode: "MD",
  CountryName: "Moldova",
  ContinentName: "Europe",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "ro,ru",
  GoogleAdsCriteriaId: 2498
}, {
  CountryId: 327,
  CountryCode: "MC",
  CountryName: "Monaco",
  ContinentName: "Europe",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr",
  GoogleAdsCriteriaId: 2492
}, {
  CountryId: 329,
  CountryCode: "ME",
  CountryName: "Montenegro",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "en,sr",
  GoogleAdsCriteriaId: 2499
}, {
  CountryId: 335,
  CountryCode: "NL",
  CountryName: "Netherlands",
  ContinentName: "Europe",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "nl,en",
  GoogleAdsCriteriaId: 2528
}, {
  CountryId: 341,
  CountryCode: "NO",
  CountryName: "Norway",
  ContinentName: "Europe",
  CurrencySymbol: "kr",
  PaypalCurrency: "NOK",
  LanguagesSpoken: "en,sb,ne,nn",
  GoogleAdsCriteriaId: 2578
}, {
  CountryId: 351,
  CountryCode: "PL",
  CountryName: "Poland",
  ContinentName: "Europe",
  CurrencySymbol: "zł",
  PaypalCurrency: "PLN",
  LanguagesSpoken: "en,pl",
  GoogleAdsCriteriaId: 2616
}, {
  CountryId: 352,
  CountryCode: "PT",
  CountryName: "Portugal",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "en,pt",
  GoogleAdsCriteriaId: 2620
}, {
  CountryId: 354,
  CountryCode: "RO",
  CountryName: "Romania",
  ContinentName: "Europe",
  CurrencySymbol: "L",
  PaypalCurrency: "RON",
  LanguagesSpoken: "en,ro",
  GoogleAdsCriteriaId: 2642
}, {
  CountryId: 355,
  CountryCode: "RU",
  CountryName: "Russia",
  ContinentName: "Europe",
  CurrencySymbol: "$",
  PaypalCurrency: "RUB",
  LanguagesSpoken: "ba,cv,ce,en,myv,os,ru,sah,tt",
  GoogleAdsCriteriaId: 2643
}, {
  CountryId: 361,
  CountryCode: "SM",
  CountryName: "San Marino",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "it",
  GoogleAdsCriteriaId: 2674
}, {
  CountryId: 365,
  CountryCode: "RS",
  CountryName: "Serbia",
  ContinentName: "Europe",
  CurrencySymbol: "din",
  PaypalCurrency: "RSD",
  LanguagesSpoken: "en,sr",
  GoogleAdsCriteriaId: 2688
}, {
  CountryId: 370,
  CountryCode: "SK",
  CountryName: "Slovakia",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "en,sk",
  GoogleAdsCriteriaId: 2703
}, {
  CountryId: 371,
  CountryCode: "SI",
  CountryName: "Slovenia",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "en,sl",
  GoogleAdsCriteriaId: 2705
}, {
  CountryId: 377,
  CountryCode: "ES",
  CountryName: "Spain",
  ContinentName: "Europe",
  CurrencySymbol: "€",
  PaypalCurrency: "EUR",
  LanguagesSpoken: "eu,ca,en,gl,es",
  GoogleAdsCriteriaId: 2724
}, {
  CountryId: 382,
  CountryCode: "SE",
  CountryName: "Sweden",
  ContinentName: "Europe",
  CurrencySymbol: "kr",
  PaypalCurrency: "SEK",
  LanguagesSpoken: "en,se,sv",
  GoogleAdsCriteriaId: 2752
}, {
  CountryId: 383,
  CountryCode: "CH",
  CountryName: "Switzerland",
  ContinentName: "Europe",
  CurrencySymbol: "",
  PaypalCurrency: "CHF",
  LanguagesSpoken: "en,fr,de,it,pt,rm,gsw,wae",
  GoogleAdsCriteriaId: 2756
}, {
  CountryId: 394,
  CountryCode: "TR",
  CountryName: "Turkey",
  ContinentName: "Europe",
  CurrencySymbol: "₤",
  PaypalCurrency: "TRY",
  LanguagesSpoken: "ku,tr",
  GoogleAdsCriteriaId: 2792
}, {
  CountryId: 398,
  CountryCode: "UA",
  CountryName: "Ukraine",
  ContinentName: "Europe",
  CurrencySymbol: "₴",
  PaypalCurrency: "UAH",
  LanguagesSpoken: "ru,uk",
  GoogleAdsCriteriaId: 2804
}, {
  CountryId: 400,
  CountryCode: "GB",
  CountryName: "United Kingdom",
  ContinentName: "Europe",
  CurrencySymbol: "GBP",
  PaypalCurrency: "US",
  LanguagesSpoken: "kw,en,gd,cy",
  GoogleAdsCriteriaId: 2826
}, {
  CountryId: 210,
  CountryCode: "AG",
  CountryName: "Antigua and Barbuda",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2028
}, {
  CountryId: 213,
  CountryCode: "AW",
  CountryName: "Aruba",
  ContinentName: "North America",
  CurrencySymbol: "ƒ",
  PaypalCurrency: "US",
  LanguagesSpoken: "nl,es",
  GoogleAdsCriteriaId: 0
}, {
  CountryId: 217,
  CountryCode: "BS",
  CountryName: "Bahamas, The",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "BSD",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2044
}, {
  CountryId: 220,
  CountryCode: "BB",
  CountryName: "Barbados",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2052
}, {
  CountryId: 223,
  CountryCode: "BZ",
  CountryName: "Belize",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "BZD",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2084
}, {
  CountryId: 238,
  CountryCode: "CA",
  CountryName: "Canada",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "CAD",
  LanguagesSpoken: "fr,en",
  GoogleAdsCriteriaId: 2124
}, {
  CountryId: 247,
  CountryCode: "CR",
  CountryName: "Costa Rica",
  ContinentName: "North America",
  CurrencySymbol: "₡",
  PaypalCurrency: "CRC",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 2188
}, {
  CountryId: 250,
  CountryCode: "CU",
  CountryName: "Cuba",
  ContinentName: "North America",
  CurrencySymbol: "CUC$",
  PaypalCurrency: "CUP",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 0
}, {
  CountryId: 251,
  CountryCode: "CW",
  CountryName: "Curacao",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "nl,es",
  GoogleAdsCriteriaId: 2531
}, {
  CountryId: 256,
  CountryCode: "DM",
  CountryName: "Dominica",
  ContinentName: "North America",
  CurrencySymbol: "RD$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2212
}, {
  CountryId: 257,
  CountryCode: "DO",
  CountryName: "Dominican Republic",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "DOP",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 2214
}, {
  CountryId: 261,
  CountryCode: "SV",
  CountryName: "El Salvador",
  ContinentName: "North America",
  CurrencySymbol: "",
  PaypalCurrency: "SVC",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 2222
}, {
  CountryId: 275,
  CountryCode: "GD",
  CountryName: "Grenada",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2308
}, {
  CountryId: 276,
  CountryCode: "GT",
  CountryName: "Guatemala",
  ContinentName: "North America",
  CurrencySymbol: "Q",
  PaypalCurrency: "GTQ",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 2320
}, {
  CountryId: 280,
  CountryCode: "HT",
  CountryName: "Haiti",
  ContinentName: "North America",
  CurrencySymbol: "G",
  PaypalCurrency: "US",
  LanguagesSpoken: "fr,es",
  GoogleAdsCriteriaId: 2332
}, {
  CountryId: 282,
  CountryCode: "HN",
  CountryName: "Honduras",
  ContinentName: "North America",
  CurrencySymbol: "L",
  PaypalCurrency: "HNL",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 2340
}, {
  CountryId: 293,
  CountryCode: "JM",
  CountryName: "Jamaica",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "JMD",
  LanguagesSpoken: "en",
  GoogleAdsCriteriaId: 2388
}, {
  CountryId: 324,
  CountryCode: "MX",
  CountryName: "Mexico",
  ContinentName: "North America",
  CurrencySymbol: "",
  PaypalCurrency: "MXN",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2484
}, {
  CountryId: 337,
  CountryCode: "NI",
  CountryName: "Nicaragua",
  ContinentName: "North America",
  CurrencySymbol: "C$",
  PaypalCurrency: "NIO",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 2558
}, {
  CountryId: 346,
  CountryCode: "PA",
  CountryName: "Panama",
  ContinentName: "North America",
  CurrencySymbol: "B/.",
  PaypalCurrency: "PAB",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 2591
}, {
  CountryId: 357,
  CountryCode: "KN",
  CountryName: "Saint Kitts and Nevis",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2659
}, {
  CountryId: 358,
  CountryCode: "LC",
  CountryName: "Saint Lucia",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2662
}, {
  CountryId: 359,
  CountryCode: "VC",
  CountryName: "Saint Vincent and the Grenadines",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2670
}, {
  CountryId: 369,
  CountryCode: "SX",
  CountryName: "Sint Maarten",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "nl,en,es",
  GoogleAdsCriteriaId: 2534
}, {
  CountryId: 392,
  CountryCode: "TT",
  CountryName: "Trinidad and Tobago",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "TTD",
  LanguagesSpoken: "",
  GoogleAdsCriteriaId: 2780
}, {
  CountryId: 421,
  CountryCode: "US",
  CountryName: "United States",
  ContinentName: "North America",
  CurrencySymbol: "$",
  PaypalCurrency: "USD",
  LanguagesSpoken: "en,haw,lkt,es",
  GoogleAdsCriteriaId: 2840
}, {
  CountryId: 211,
  CountryCode: "AR",
  CountryName: "Argentina",
  ContinentName: "South America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2032
}, {
  CountryId: 226,
  CountryCode: "BO",
  CountryName: "Bolivia",
  ContinentName: "South America",
  CurrencySymbol: "$",
  PaypalCurrency: "BOB",
  LanguagesSpoken: "qu,es",
  GoogleAdsCriteriaId: 2068
}, {
  CountryId: 229,
  CountryCode: "BR",
  CountryName: "Brazil",
  ContinentName: "South America",
  CurrencySymbol: "R$",
  PaypalCurrency: "BRL",
  LanguagesSpoken: "en,pt,es",
  GoogleAdsCriteriaId: 2076
}, {
  CountryId: 241,
  CountryCode: "CL",
  CountryName: "Chile",
  ContinentName: "South America",
  CurrencySymbol: "$",
  PaypalCurrency: "CLP",
  LanguagesSpoken: "arn,es",
  GoogleAdsCriteriaId: 2152
}, {
  CountryId: 243,
  CountryCode: "CO",
  CountryName: "Colombia",
  ContinentName: "South America",
  CurrencySymbol: "$",
  PaypalCurrency: "COP",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2170
}, {
  CountryId: 259,
  CountryCode: "EC",
  CountryName: "Ecuador",
  ContinentName: "South America",
  CurrencySymbol: "",
  PaypalCurrency: "US",
  LanguagesSpoken: "es,qu",
  GoogleAdsCriteriaId: 2218
}, {
  CountryId: 279,
  CountryCode: "GY",
  CountryName: "Guyana",
  ContinentName: "South America",
  CurrencySymbol: "$",
  PaypalCurrency: "GYD",
  LanguagesSpoken: "en,es",
  GoogleAdsCriteriaId: 2328
}, {
  CountryId: 281,
  CountryCode: "",
  CountryName: "Holy See",
  ContinentName: "South America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "",
  GoogleAdsCriteriaId: 0
}, {
  CountryId: 348,
  CountryCode: "PY",
  CountryName: "Paraguay",
  ContinentName: "South America",
  CurrencySymbol: "₲",
  PaypalCurrency: "PYG",
  LanguagesSpoken: "gn,es",
  GoogleAdsCriteriaId: 2600
}, {
  CountryId: 349,
  CountryCode: "PE",
  CountryName: "Peru",
  ContinentName: "South America",
  CurrencySymbol: "S/.",
  PaypalCurrency: "PEN",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 2604
}, {
  CountryId: 380,
  CountryCode: "SR",
  CountryName: "Suriname",
  ContinentName: "South America",
  CurrencySymbol: "$",
  PaypalCurrency: "SRD",
  LanguagesSpoken: "nl,es",
  GoogleAdsCriteriaId: 2740
}, {
  CountryId: 401,
  CountryCode: "UY",
  CountryName: "Uruguay",
  ContinentName: "South America",
  CurrencySymbol: "$",
  PaypalCurrency: "UYU",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 2858
}, {
  CountryId: 404,
  CountryCode: "VE",
  CountryName: "Venezuela",
  ContinentName: "South America",
  CurrencySymbol: "$",
  PaypalCurrency: "US",
  LanguagesSpoken: "es",
  GoogleAdsCriteriaId: 2862
}];
export default CountriesData;