import { configureStore } from "@reduxjs/toolkit";
import { GetAllIndexDB, RemoveIndexDB, SetIndexedDb } from "./IndexeDB.js";
import {
  BANNER_CURRENT_INDEX,
  CFW_ACTIVATE_SOCIAL_BUTTONS,
  CFW_ADSENSE_READY,
  CFW_BANNER_REFRESHER,
  CFW_CURRENT_URL,
  CFW_ERROR,
  CFW_HANDLE_SEO_PROCESS,
  CFW_IS_MOBILE,
  CFW_LOAD_MENU,
  CFW_SCROLL_COUNTER,
  CFW_SCROLL_COUNTER_PROCESSED,
  CFW_TOTAL_CASH,
  CFW_TOTAL_STATE,
  DIALOGHOLDER,
  FILTERHOLDER,
  CFW_RECOMMEND_REFRESHER,
  Response_SelectedTranslation,
  Response_TranslationData,
  Response_TranslationItem,
  Response_SiteMapUrlData,
} from "./CFW.js";
import { Decrypt } from "./SFW.js";
const InitialState = {
  isDelete: false,
  params: {},
  responseData: {},
  error: "",
  type: "",
};
const persistConfig = {
  blacklist: [
    Response_SiteMapUrlData,
    Response_TranslationItem,
    CFW_ERROR,
    CFW_ADSENSE_READY,
    CFW_SCROLL_COUNTER,
    CFW_RECOMMEND_REFRESHER,
    CFW_SCROLL_COUNTER_PROCESSED,
    CFW_ACTIVATE_SOCIAL_BUTTONS,
    CFW_LOAD_MENU,
    "@@INIT",
    DIALOGHOLDER,
    FILTERHOLDER,
    Response_SelectedTranslation,
    CFW_IS_MOBILE,
    CFW_BANNER_REFRESHER,
    BANNER_CURRENT_INDEX,
    CFW_TOTAL_STATE,
    CFW_TOTAL_CASH,
    CFW_HANDLE_SEO_PROCESS,
    CFW_CURRENT_URL,
  ],
  blacklistContains: [Response_TranslationItem],
};
const rehydrationState = () => {
  try {
    GetAllIndexDB().then((results) => {
      if (results) {
        Object.keys(results).forEach((keyName) => {
          if (results[keyName]?.responseData?.Results) {
            SetState(Decrypt(keyName), results[keyName].responseData.Results);
          } else if (results[keyName]?.responseData) {
            SetState(Decrypt(keyName), results[keyName]?.responseData);
          } else if (results[keyName]) {
            SetState(Decrypt(keyName), results[keyName]);
          }
        });
      }
    });
    return {};
  } catch (error) {
    console.error("Rehydration State Error");
    return;
  }
};
const preloadedState = rehydrationState();
const persistMiddleware = (persistConfig) => (store) => (next) => (action) => {
  const result = next(action);
  const blacklistPersist = persistConfig.blacklist || [];
  const blacklistContainsPersist = persistConfig.blacklistContains || [];
  if (
    !blacklistPersist.includes(result.type) &&
    !blacklistContainsPersist.find((item) => item.includes(result.type))
  ) {
    SetIndexedDb(result.type, result);
  }
  blacklistPersist.forEach((key) => {
    RemoveIndexDB(key);
  });
  return result;
};

const DictionaryState = {};

const APIReducer = (state = InitialState, action) => {
  if (action.type) {
    delete DictionaryState[action.type];
    if (!action.isDelete) {
      const results = {
        isDelete: action.isDelete,
        params: action.params,
        responseData: action.responseData,
        error: action.error || "",
      };
      DictionaryState[action.type] = results;
    }
  } else {
    console.log(state);
  }
  return JSON.parse(JSON.stringify(DictionaryState));
};
const excludeSerializer = [
  "responseData.Results.0.PackageDetails.$$typeof",
  "responseData.Results.0.UrlPathOrFileName.blob",
  "responseData.Results.UrlPathOrFileName.blob",
  "params.blobFile",
  "responseData.Results.Base64File.blob",
  "responseData.Results.FileName.blob",
  "responseData.Results.Picture.blob",
  DIALOGHOLDER,
  FILTERHOLDER,
];
export const Store = configureStore({
  reducer: APIReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
        ignoredActionPaths: excludeSerializer,
        ignoredPaths: excludeSerializer,
      },
    }).concat(persistMiddleware(persistConfig));
  },
});
export const GetStateField = (pKey, pField, defaultValue = null) => {
  const currentData = typeof pKey === "string" ? GetState(pKey) : pKey;
  if (currentData) {
    return currentData[pField] ?? defaultValue;
  }
  return defaultValue;
};
export const GetState = (pKey, defaultValue = null) => {
  if (!DictionaryState[pKey] && DictionaryState[pKey] !== 0) {
    return defaultValue;
  }
  const translationState = DictionaryState[pKey];
  if (translationState?.responseData?.Results) {
    return translationState.responseData.Results ?? defaultValue;
  } else if (translationState?.responseData) {
    return translationState.responseData ?? defaultValue;
  }
  return translationState ?? defaultValue;
};
export const RemoveState = (pKey) => {
  if (DictionaryState[pKey]) {
    const results = {
      params: null,
      responseData: { Results: null, Message: null, Token: null },
      type: pKey,
      isDelete: true,
    };
    RemoveIndexDB(pKey);
    Store.dispatch(results);
  }
};

export const SetState = (storeName, pVal, secondsDelay = null) => {
  const results = {
    params: null,
    responseData: { Results: pVal, Message: null, Token: null },
    type: storeName,
    isDelete: false,
  };
  if (JSON.stringify(pVal) !== JSON.stringify(GetState(storeName))) {
    if (!secondsDelay) {
      Store.dispatch({ ...results });
    } else {
      setTimeout(() => {
        Store.dispatch({ ...results });
      }, secondsDelay * 1000);
    }
  }
};

export const ValildateEntries = (
  stateErrorList,
  stateToStoreErrorMessages,
  exceptForFields = []
) => {
  const errorCollection = GetState(stateErrorList, null);
  if (window.children) {
    delete window.children;
  }
  if (errorCollection) {
    const errors = Object.keys(errorCollection)
      .filter(
        (item) => errorCollection[item] && !exceptForFields.includes(item)
      )
      .map((item) => `${errorCollection[item]}`);
    SetState(
      stateToStoreErrorMessages,
      errors?.length
        ? `<div class="note"><h1>${
            window.initScripts[Response_TranslationData]["696"]
          }:</h1>${errors.join("")}</div>`
        : null
    );
    return !errors?.length;
  }
  return true;
};
export const ClearEntries = (state, freshState) => {
  SetState(state, { ...freshState });
};
export const UpdateStateField = (
  storeName,
  pField,
  pVal,
  secondsDelay = null
) => {
  const currentData = { ...GetState(storeName) };
  if (
    currentData &&
    JSON.stringify(currentData[pField]) !== JSON.stringify(pVal)
  ) {
    currentData[pField] = pVal;
  }
  SetState(storeName, currentData, secondsDelay);
};
