export const InitializeIndexedDBWorker = function (successEvent, errorEvent) {
  if (successEvent === void 0) {
    successEvent = () => {};
  }
  if (errorEvent === void 0) {
    errorEvent = () => {};
  }
  const worker = new Worker(/* webpackChunkName: "IndexedDBWorker" */new URL("/IndexedDBWorker.js", import.meta.url));
  worker.onmessage = results => {
    if (successEvent) {
      successEvent(results.data);
    }
  };
  worker.onerror = results => {
    if (errorEvent) {
      errorEvent(results);
    }
  };
  worker.onmessageerror = results => {
    if (errorEvent) {
      errorEvent(results);
    }
  };
  return {
    worker,
    payload: function (action, body) {
      if (body === void 0) {
        body = null;
      }
      return {
        action,
        body
      };
    }
  };
};