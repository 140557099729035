import React, { Fragment, useState } from "react";
import { GetProductAndLanguage, StringFormat } from "../generic/Common.js";
import Label from "./Label.js";
import ScreenHandler from "./Screenhandler.js";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Response_TranslationData } from "../generic/CFW.js";
const ReadMeFirst = ({ tran, children, style, userGuideTran = null }) => {
  const location = useLocation();
  const { productCode } = GetProductAndLanguage(location.pathname);
  if (!tran) {
    tran = "11";
  }
  const { data: tranList } = useQuery({
    queryKey: [Response_TranslationData],
  });
  let guideContent;
  if (userGuideTran) {
    guideContent = tranList[userGuideTran];
    if (
      guideContent.includes('id="userGuideSrc"') &&
      guideContent.includes('type="hidden"')
    ) {
      guideContent = guideContent.substring(
        guideContent.lastIndexOf("https://"),
        guideContent.indexOf('">')
      );
      guideContent = StringFormat(guideContent, productCode.toLowerCase());
    }
  }
  const [show, setShow] = useState(false);
  return (
    <Fragment>
      <br />
      <div className="toggleContainer" style={style} ishide={show ? 0 : 1}>
        <button
          style={style}
          type="button"
          onClick={() => {
            setShow(!show);
          }}
          className="headerToggle"
        >
          <span style={{ fontSize: "1rem", paddingRight: "5px" }}>
            {show ? "+" : "-"}
          </span>
          <Label tranVal={tran} />
        </button>
        {show && (
          <div className="bodyToggle">
            {guideContent ? (
              <Fragment>
                <ScreenHandler forMobile={true}>
                  <a href={guideContent} download>
                    <Label tranVal="659" isPlain={true} />
                  </a>
                </ScreenHandler>
                <ScreenHandler forMobile={false}>
                  <em>
                    <embed width="100%" height="500px" src={guideContent} />
                  </em>
                </ScreenHandler>
              </Fragment>
            ) : (
              children
            )}
          </div>
        )}
      </div>
      <br />
    </Fragment>
  );
};
export default ReadMeFirst;
