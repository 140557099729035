import React, { useEffect, useState } from "react";
import { MergeObjects, Omit } from "../generic/Common.js";
import { GetImageCustomWorker } from "../generic/SFW.js";
import { GetState, SetState } from "../generic/StoreToolkit.js";
const ImageCard = (prop) => {
  const storedImage =
    prop?.style?.backgroundImage ??
    (prop?.usedirectimage ? prop.src : GetState(prop.src));
  const [imageWorker, setImageWorker] = useState(
    storedImage ?? prop.defaultsrc
  );
  const type = prop.type ?? "div";
  const otherAttr = Omit(prop, ["type", "style", "src", "defaultsrc"]);
  useEffect(() => {
    if (!storedImage) {
      const SetImage = (currentImage) => {
        setImageWorker(currentImage);
        SetState(prop.src, currentImage);
      };
      if (!prop?.usedirectimage && prop.src) {
        GetImageCustomWorker(prop.src, SetImage);
      }
    }
  }, [imageWorker]);
  let attr = {};
  if (imageWorker && !prop?.style?.backgroundImage) {
    attr["style"] = MergeObjects(prop.style, {
      backgroundImage: imageWorker
        ? prop.isdouble
          ? `url(${imageWorker}), url(${imageWorker})`
          : `url(${imageWorker})`
        : prop.style.backgroundImage,
    });
  } else {
    attr["style"] = prop.style;
  }
  if (prop.type) {
    attr["type"] = prop.type;
  }
  attr = MergeObjects(otherAttr, attr);

  switch (type) {
    case "td":
      return <td {...attr}>{prop.children}</td>;
    default:
      return <div {...attr}>{prop.children}</div>;
  }
};
export default ImageCard;
