import React, { Fragment, lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { API_URL, MONGOURL } from "../../../common/data/GlobalData.js";
import {
  CFW_ERROR,
  CFW_REFRESHER,
  DIALOGHOLDER,
  PROCESS_ADD,
  PROCESS_EDIT,
  PROCESS_SELECT,
  REGEXP_REMOVETAGS,
  Response_SelectedTranslation,
  Response_SiteMapUrlData,
  Response_TranslateFromEnglishOpenAI,
  Response_TranslationItem,
} from "../generic/CFW.js";
import {
  Clone,
  GUID,
  GenerateStateEntry,
  GetDictionaryStorage,
  GetFirstState,
  GetProductAndLanguage,
  MatchingFieldsOnly,
} from "../generic/Common.js";
import {
  MaintainDialog,
  MaintainTranslations,
  TranslateFromEnglishOpenAI,
} from "../generic/PFW.js";
import { GetResults, PostData } from "../generic/SFW.js";
import {
  GetState,
  GetStateField,
  RemoveState,
  SetState,
  UpdateStateField,
  ValildateEntries,
} from "../generic/StoreToolkit.js";
import GroupButtons from "./GroupButtons.js";
import Label from "./Label.js";
import LanguageEntry from "./LanguageEntry.js";
import { DataHTML } from "./DataEntry/DataHTML.js";
import { LanguagesData } from "../../../common/data/LanguagesData.js";
import { useLocation } from "react-router-dom";
const InputTranslation = lazy(() =>
  import(/* webpackChunkName: "InputTranslation" */ "./InputTranslation.js")
);
const MakeTranslation = () => {
  const location = useLocation();
  const refresherState = `${CFW_REFRESHER}-translation`;
  const { languageCode } = GetProductAndLanguage(location.pathname);
  const selectedTranslation = useSelector((state) =>
    GetResults(state[Response_SelectedTranslation])
  );
  const ClearWindowTranslation = () => {
    RemoveState(Response_SelectedTranslation);
    if (window.selectedTranslation) {
      delete window.selectedTranslation;
    }
  };
  const refresher = useSelector((state) => GetResults(state[refresherState]));
  const { entry, error, list } = GenerateStateEntry(
    Response_TranslationItem,
    MaintainTranslations
  );
  const Retrieve = (languageCodeParam = null, description = null) => {
    window.selectedTranslation.Process = PROCESS_SELECT;
    if (languageCodeParam) {
      window.selectedTranslation.Filter.LanguageCode = languageCodeParam;
      window.selectedTranslation.LanguageCode = languageCodeParam;
    }
    if (window.selectedTranslation.Filter._id) {
      delete window.selectedTranslation.Filter._id;
    }
    if (window.selectedTranslation._id) {
      delete window.selectedTranslation._id;
    }
    if (window.selectedTranslation.TranslationDescription) {
      delete window.selectedTranslation.TranslationDescription;
    }
    PostData(
      [MONGOURL, "TranslationData"].join(""),
      window.selectedTranslation,
      Response_TranslationItem,
      () => {
        const results =
          GetFirstState(Response_TranslationItem) ?? window.selectedTranslation;
        let param = Clone(MaintainTranslations);
        param = MatchingFieldsOnly(results, param);
        param.Filter = MatchingFieldsOnly(results, param.Filter);
        param.Filter.TranslationDescription = null;
        if (languageCodeParam) {
          param.LanguageCode = languageCodeParam;
          param.Filter.LanguageCode = languageCodeParam;
        }
        if (!description && results.TranslationDescription) {
          description = results.TranslationDescription;
          description = new DOMParser().parseFromString(
            description,
            "text/html"
          ).body.innerHTML;
          description = description ? description : "";
        }
        if (description) {
          param.TranslationDescription = description;
        }
        param.Process = param._id ? PROCESS_EDIT : PROCESS_ADD;
        if (!param._id) {
          param._id = `_${GUID()}`;
        }
        SetState(entry.state, { ...param });
        SetState(list.state, { ...param });
        SetState(refresherState, true);
      }
    );
  };
  const Save = () => {
    const isValid = ValildateEntries(error.state, CFW_ERROR);
    if (!isValid) {
      const dialogParam = { ...MaintainDialog };
      SetState(DIALOGHOLDER, dialogParam);
    } else {
      const param = GetState(entry.state);
      if (param.TranslationDescription.substring(0, 3) === "<p>") {
        param.TranslationDescription =
          param.TranslationDescription.substring(3);
        param.TranslationDescription = param.TranslationDescription.substring(
          0,
          param.TranslationDescription.length - 4
        );
      }
      PostData(
        [MONGOURL, "TranslationData"].join(""),
        param,
        Response_TranslationItem,
        () => {
          RemoveState(entry.state);
          RemoveState(list.state);
          ClearWindowTranslation();
        }
      );
    }
  };
  const TranslateToOtherLanguage = () => {
    const languageCodeParam = GetStateField(
      list.state,
      "LanguageCode",
      languageCode
    );
    const translateParam = Clone(TranslateFromEnglishOpenAI);
    translateParam.LanguageCode = languageCodeParam;
    const description = GetStateField(entry.state, "TranslationDescription");
    translateParam.Text = description.replace(REGEXP_REMOVETAGS, "");
    PostData(
      [API_URL, "TranslateFromEnglishOpenAI"].join(""),
      translateParam,
      Response_TranslateFromEnglishOpenAI,
      () => {
        const translatedResult = GetState(Response_TranslateFromEnglishOpenAI);
        ClearWindowTranslation();
        RemoveState(refresherState);
        Retrieve(languageCodeParam, translatedResult.TranslatedText);
      }
    );
  };
  const languages = GetDictionaryStorage(
    LanguagesData,
    "LanguageCode",
    "LanguageName"
  ).filter((lang) => lang?.Key !== "en");
  useEffect(() => {
    if (!GetStateField(list.state, "LanguageCode")) {
      UpdateStateField(
        list.state,
        "LanguageCode",
        languages?.length ? languages[0]?.Key : null
      );
    }
    if (selectedTranslation && !refresher) {
      Retrieve(languageCode);
    }
    return function cleanup() {};
  }, [selectedTranslation]);
  return selectedTranslation ? (
    <Suspense fallback={<div className="loading" />}>
      <InputTranslation
        hdr={GetStateField(Response_SiteMapUrlData, "Title")}
        pcb={Save}
        ncb={() => {
          RemoveState(entry.state);
          RemoveState(list.state);
          ClearWindowTranslation();
        }}
      >
        <LanguageEntry
          state={entry.state}
          required
          onChange={() => {
            const param = GetState(entry.state);
            param.Filter.LanguageCode = param.LanguageCode;
            SetState(entry.state, param);
          }}
          defaultselected={GetStateField(entry.state, "LanguageCode")}
        />
        <GroupButtons>
          <button
            type="button"
            onClick={() => {
              RemoveState(refresherState);
              Retrieve(
                GetStateField(entry.state, "LanguageCode", languageCode)
              );
            }}
            className="button-icon ui-icon-find"
          >
            <Label tranVal="52" />
          </button>
        </GroupButtons>
        {window.selectedTranslation.Filter.LanguageCode === "en" && (
          <fieldset style={{ textAlign: "left" }}>
            <legend style={{ padding: "0px" }}>
              <Label tranVal="687" isPlain={true} />
            </legend>
            <LanguageEntry state={list.state} required data={languages} />
            <button
              type="button"
              className="primary button-icon ui-icon-switch-white"
              onClick={() => {
                const results = GetState(Response_TranslationItem);
                if (results?.length && results[0]?.TranslationDescription) {
                  TranslateToOtherLanguage();
                }
              }}
              style={{ float: "right" }}
            >
              <Label tranVal="54" />
            </button>
          </fieldset>
        )}
        {refresher ? (
          <DataHTML
            name="TranslationDescription"
            state={entry.state}
            tran="74"
            required
          />
        ) : (
          <Fragment />
        )}
      </InputTranslation>
    </Suspense>
  ) : (
    <Fragment />
  );
};
export default MakeTranslation;
