import React, { Fragment, useEffect } from "react";
import { User } from "../generic/User.js";
import { GetConfig, PostData } from "../generic/SFW.js";
import { API_URL } from "../../data/GlobalData.js";
import { Response_HashData } from "../generic/CFW.js";
import { GetState } from "../generic/StoreToolkit.js";
import { AddScriptsWithCallBack, Waiter } from "../generic/Common.js";

export const TawkToFW = ({ languageCode }) => {
  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();
    (() => {
      AddScriptsWithCallBack(
        `https://embed.tawk.to/${GetConfig(`TawkToId.${languageCode}`)}`,
        "tawkToId",
        {
          async: "",
          charset: "UTF-8",
          crossorigin: "*",
        },
        () => {
          if (User.IsLoggedIn()) {
            try {
              PostData(
                [API_URL, "GenerateKey"].join(""),
                {
                  apiType: "tawkto",
                  value: User.UserGUID().toLowerCase(),
                },
                Response_HashData,
                async () => {
                  while (!window?.Tawk_API?.login) {
                    await Waiter(1000);
                  }
                  const results = GetState(Response_HashData);
                  window.Tawk_API.login(
                    {
                      hash: results,
                      userId: User.UserGUID().toLowerCase(),
                      name: User.FullName(),
                      email: User.Email(),
                    },
                    function (error) {
                      if (error) {
                        console.log("Tawk.To error", error);
                      }
                    }
                  );
                }
              );
            } catch (error) {
              console.log("Tawk.To error", error.message);
            }
          }
        },
        null,
        true
      );
    })();
    return function cleanup() {};
  }, []);
  return <Fragment />;
};
