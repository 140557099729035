import { Workbox } from "workbox-window";
import { DELETEALLSTORE, DELETEALLSTORELOGOUT, DIALOGHOLDER } from "./common/js/generic/CFW.js";
import { SetRawState, SetState } from "./common/js/generic/StoreToolkit.js";
import { MaintainDialog } from "./common/js/generic/PFW.js";
export const UnregisterServiceWorkers = function (languageCode, productCode, logout) {
  if (logout === void 0) {
    logout = false;
  }
  sessionStorage.clear();
  localStorage.clear();
  caches.keys().then(cacheNames => {
    cacheNames.forEach(cacheName => {
      caches.delete(cacheName);
    });
  });
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
  SetRawState({
    params: null,
    responseData: null,
    type: logout ? DELETEALLSTORELOGOUT : DELETEALLSTORE,
    isDelete: 2
  });
  if (logout) {
    const dialogParam = {
      ...MaintainDialog
    };
    dialogParam.msg = "721";
    dialogParam.showpb = false;
    SetState(DIALOGHOLDER, dialogParam);
  }
};
const registerServiceWorker = (languageCode, productCode) => {
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ("serviceWorker" in navigator) {
    const wb = new Workbox(window.serviceWorker);
    const wbInstalled = event => {
      /**
       * We have the condition — event.isUpdate because we don’t want to show
       * this message on the very first service worker installation,
       * only on the updated
       */
      if (event.isUpdate) {
        UnregisterServiceWorkers(languageCode, productCode);
      }
    };
    wb.removeEventListener("installed", wbInstalled);
    wb.addEventListener("installed", wbInstalled);
    wb.register();
  }
};
export default registerServiceWorker;