import React, { lazy, Suspense } from "react";
import CountryPermission from "./common/js/controls/CountryPermission.js";
import { Dialog } from "./common/js/controls/Dialogs/index.js";
import { FileDownloader } from "./common/js/controls/FileDownloader.js";
import FileProcessor from "./common/js/controls/FileProcessor.js";
import { Filter } from "./common/js/controls/Filter/index.js";
import MakeTranslation from "./common/js/controls/MakeTranslation.js";
import ResponsiveAds from "./common/js/controls/ResponsiveAds/index.js";
import {
  ScrollEvents,
  ScrollSuspense,
} from "./common/js/controls/ScrollEvents.js";
import { Provider } from "../node_modules/react-redux/es/exports.js";
import { SetState, Store } from "./common/js/generic/StoreToolkit.js";

import { HandleSEO } from "./common/js/generic/HandleSEO.js";
import { CanonicalUrl } from "./common/js/generic/Canonical.js";
import PageSelector from "./js/views/PageSelector/PageSelector.js";
import ProcessChildren from "./common/js/controls/ProcessChildren.js";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { LoadClientSideOnly } from "./common/js/controls/LazyLoader.js";
import { Outlet } from "react-router-dom";
import {
  CFW_CURRENT_URL,
  Response_SiteMapUrlData,
  Response_TranslationData,
} from "./common/js/generic/CFW.js";
import {
  GenerateNameFromUrl,
  GeneratePageInfo,
  GetProductAndLanguage,
} from "./common/js/generic/Common.js";
import DisplayPoints from "./common/js/controls/DisplayPoints.js";
import { GetConfig } from "./common/js/generic/SFW.js";
import { Header } from "./common/js/headers/index.js";
import { InitializeScripts } from "./common/js/apiControls/InitializeScripts.js";
import { TawkToFW } from "./common/js/controls/TawkToFW.js";
const Footer = lazy(() =>
  import(/* webpackChunkName: "Footer" */ "./common/js/generic/Footer.js")
);
const App = ({ currentUrl, siteMapData, css, roles, initData }) => {
  const { productCode, languageCode } = GetProductAndLanguage(currentUrl);
  let pageInfo = initData[Response_SiteMapUrlData];
  pageInfo = GeneratePageInfo(
    languageCode,
    productCode,
    currentUrl,
    initData[Response_TranslationData],
    pageInfo?.length ? pageInfo[0] : null
  );
  SetState(Response_SiteMapUrlData, pageInfo);
  const queryClient = new QueryClient();
  for (const item of Object.keys(initData)) {
    queryClient.setQueryData([item], () => initData[item]);
  }
  SetState(CFW_CURRENT_URL, currentUrl);
  return (
    <html lang={languageCode} prefix="og: https://ogp.me/ns#">
      <head prefix="og: https://ogp.me/ns# fb: https://ogp.me/ns/fb# website: https://ogp.me/ns/website#">
        <title>{pageInfo?.Title}</title>
        <meta
          name="google-site-verification"
          content="M4gIOEJTwVwVTq9KT-loFMhqakoKVZVZeegSgyrqvfQ"
        />
        <meta charSet="utf-8" />
        <meta
          name="google-adsense-account"
          content={`ca-pub-${GetConfig("CompanyAdsenseId")}`}
        ></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:type" content="website" />
        <meta name="revisit-after" content="15 days" />
        <CanonicalUrl
          urlParam={currentUrl}
          canonicalParam={pageInfo?.CanonicalUrl}
        />
        {css.map((cssPath) => (
          <link
            id={GenerateNameFromUrl(cssPath)}
            key={cssPath}
            rel="stylesheet"
            href={cssPath}
          ></link>
        ))}
        <meta property="og:site_name" content={pageInfo?.ProductCode} />
        <meta property="og:title" content={pageInfo?.Title} />
        <meta name="twitter:title" content={pageInfo?.Title} />
        <meta name="description" content={pageInfo?.Description} />
        <meta property="og:description" content={pageInfo?.Description} />
        <meta name="twitter:description" content={pageInfo?.Description} />
        <meta property="og:image" content={pageInfo?.Image} />
        <meta
          data-n-head="ssr"
          data-hid="og:image:type"
          property="og:image:type"
          content="image/png"
        />
        <meta property="og:image:secure_url" content={pageInfo?.Image} />
        <meta name="twitter:image" content={pageInfo?.Image} />
        <meta property="og:url" content={currentUrl} />
        <meta property="fb:app_id" content="2062759787315800" />
        <meta
          name="facebook-domain-verification"
          content="ofe1rrdk4p03ze9q7qqdxo694qyqiv"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="preconnect" href="https://www.facebook.com" />
        <link rel="preconnect" href="https://staticxx.facebook.com" />
        <link rel="preconnect" href="https://www.doubleclickbygoogle.com" />
        <link rel="preconnect" href="https://connect.facebook.net" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://googleads.g.doubleclick.net" />
        <link rel="dns-prefetch" href="https://urtechcity.com/manifest.json" />
        <link
          rel="manifest"
          href="/manifest.json"
          crossOrigin="use-credentials"
        />
      </head>
      <body cz-shortcut-listen="true">
        <LoadClientSideOnly>
          <div id="fb-root"></div>
        </LoadClientSideOnly>
        <Provider store={Store}>
          <QueryClientProvider client={queryClient}>
            <Header />
            <CountryPermission />
            <InitializeScripts />
            <LoadClientSideOnly>
              <DisplayPoints />
            </LoadClientSideOnly>
            <ScrollEvents />
            <section className="mainContent">
              <Suspense fallback={<div className="loading" />}>
                <Outlet />
              </Suspense>
              <PageSelector roles={roles} />
            </section>
            <MakeTranslation />
            {!currentUrl.toLowerCase().includes("/dailytimerecord/") && (
              <LoadClientSideOnly>
                <ScrollSuspense scrollcounter={2}>
                  <TawkToFW languageCode={languageCode} />
                  <ResponsiveAds
                    slot={GetConfig("CompanyAdSlotMultiplex")}
                    format="autorelaxed"
                  />
                </ScrollSuspense>
              </LoadClientSideOnly>
            )}
            <Suspense fallback={<div className="loading" />}>
              <Footer
                pageInfo={pageInfo}
                pathname={currentUrl}
                siteMapData={siteMapData}
              />
            </Suspense>
            <Dialog />
            <Filter />
            <FileDownloader />
            <FileProcessor />
            <ProcessChildren />
            <HandleSEO />
          </QueryClientProvider>
        </Provider>
      </body>
    </html>
  );
};
export default App;
