import React, { Fragment, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { DIALOGHOLDER } from "../../generic/CFW.js";
import { GetResults } from "../../generic/SFW.js";
const ShowDialog = lazy(() =>
  import(/* webpackChunkName: "ShowDialog" */ "./ShowDialog.js")
);
export const Dialog = () => {
  const param = useSelector((state) => GetResults(state[DIALOGHOLDER]));
  if (param) {
    return (
      <Suspense fallback={<div className="loading" />}>
        <ShowDialog param={param} />
      </Suspense>
    );
  }
  return <Fragment />;
};
