import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { SVCNTURL, UI_URL } from "../../../common/data/GlobalData.js";
import {
  CFW_HANDLE_SEO_PROCESS,
  PROCESS_ADDDETAILS,
  Response_HandleSEOSiteMapData,
  Response_TranslationData,
  UPDATESITEMAP,
} from "./CFW.js";
import { CleanUrl, GetDefaultImage, MatchingFieldsOnly } from "./Common.js";
import {
  ConvertBase64ToPng,
  ConvertUrlToBase64,
  GetSiteMapImage,
  UploadSiteMapImage,
} from "./FileFW.js";
import { MaintainSiteMap } from "./PFW.js";
import { GetResults, PostData } from "./SFW.js";
import { GetState, RemoveState, SetState } from "./StoreToolkit.js";
import { useQuery } from "@tanstack/react-query";
export const HandleSEO = () => {
  const updateSiteMap = useSelector((state) =>
    GetResults(state[UPDATESITEMAP])
  );
  let siteMapParam;
  const { data: tranList } = useQuery({
    queryKey: [Response_TranslationData],
  });
  const GeneratePNGFileNameFromUrl = (paramUrl) => {
    let fileName = CleanUrl(paramUrl.toLowerCase());
    fileName = fileName.split(UI_URL)[1].substring(1);
    if (fileName.length > 100 && fileName.includes("-")) {
      fileName = fileName.substring(fileName.indexOf("-") + 1);
    }
    fileName = `${(fileName ?? "home").replace(/\W/g, "-")}.png`;
    return fileName;
  };

  const ConvertImageIfPng = () => {
    siteMapParam = { ...updateSiteMap };
    const imageUrlToConvert =
      updateSiteMap.Image ?? GetDefaultImage(updateSiteMap?.ProductCode);
    if (imageUrlToConvert) {
      ConvertUrlToBase64(imageUrlToConvert, ({ base64, name }) => {
        ConvertBase64ToPng(base64, name, false).then((results) => {
          results.fileName = GeneratePNGFileNameFromUrl(updateSiteMap?.Url);
          UploadSiteMapImage(results.blob, results.fileName, () => {
            siteMapParam.Image = GetSiteMapImage(results.fileName);
            UpdateSiteMap();
          });
        });
      });
    } else {
      UpdateSiteMap();
    }
  };
  const UpdateSiteMap = () => {
    const param = MatchingFieldsOnly(siteMapParam, MaintainSiteMap);
    param.Process = param.Process ?? PROCESS_ADDDETAILS;
    param.Title = isNaN(param.Title) ? param.Title : tranList[param.Title];
    param.Description = isNaN(param.Description)
      ? param.Description
      : tranList[param.Description];
    PostData(
      [SVCNTURL, "MaintainSiteMap"].join(""),
      param,
      Response_HandleSEOSiteMapData,
      () => {
        RemoveState(CFW_HANDLE_SEO_PROCESS);
        RemoveState(UPDATESITEMAP);
      }
    );
  };
  useEffect(() => {
    if (updateSiteMap?.Url && !GetState(CFW_HANDLE_SEO_PROCESS)) {
      SetState(CFW_HANDLE_SEO_PROCESS, true);
      document.title = isNaN(updateSiteMap.Title)
        ? updateSiteMap.Title
        : tranList[updateSiteMap.Title];
      ConvertImageIfPng();
    }
    return function cleanup() {};
  }, [updateSiteMap]);
  return <Fragment />;
};
