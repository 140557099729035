import { useMutation, useInfiniteQuery, QueryClient, useQuery } from "@tanstack/react-query";
import { GetState, SetState } from "../generic/StoreToolkit.js";
import { CleanUrl, Clone, HasProperty, MatchingFieldsOnly, Omit } from "../generic/Common.js";
import { PostDataWorker } from "../generic/SSRFW.js";
import { ORIGIN } from "../../data/GlobalData.js";
export const GenerateStateListEntry = function (state, action, params, concatName, updateParams, postEvent, preEvent) {
  if (concatName === void 0) {
    concatName = "";
  }
  if (updateParams === void 0) {
    updateParams = null;
  }
  if (postEvent === void 0) {
    postEvent = null;
  }
  if (preEvent === void 0) {
    preEvent = async () => {
      return true;
    };
  }
  if (updateParams) {
    updateParams = Clone(updateParams);
  }
  if (concatName) {
    concatName = CleanUrl(concatName);
    concatName = `-${concatName}`;
  } else {
    concatName = "";
  }
  const MergeAllItemPages = function (pages) {
    if (pages === void 0) {
      pages = [];
    }
    return pages.reduce((allItems, page) => {
      return allItems.concat(...(page ?? []));
    }, []);
  };
  const filterStateName = `${state}-filter${concatName}`;
  const listStateName = `${state}${concatName}`;
  const queryClient = new QueryClient();
  const {
    data: initialDefaultData
  } = useQuery({
    queryKey: [listStateName]
  });
  const fetchData = _ref => {
    let {
      pageParam = 0
    } = _ref;
    return new Promise(resolve => {
      preEvent().then(isValid => {
        if (isValid) {
          const filterParam = MatchingFieldsOnly(GetState(filterStateName, {
            ...params
          }), {
            ...params
          });
          if (HasProperty(filterParam, "PageIndex") && filterParam.PageIndex !== -1) {
            filterParam.PageIndex = pageParam;
          }
          resolve(PostDataWorker(action, filterParam, null, null, false, postEvent));
        } else {
          resolve([]);
        }
      });
    });
  };
  const PostData = param => {
    return PostDataWorker(action, param.param, null, false, false, param.postEvent);
  };
  const list = useInfiniteQuery({
    queryKey: [listStateName, MatchingFieldsOnly(GetState(filterStateName, {
      ...params
    }), {
      ...params
    })],
    refetchOnWindowFocus: !ORIGIN === "urtechcity.com",
    cacheTime: 86400000,
    staleTime: 86400000,
    queryFn: fetchData,
    initialPageParam: 0,
    getPreviousPageParam: firstPage => firstPage?.previousId,
    getNextPageParam: (lastPage, allPages) => !((lastPage?.length ?? 0) % 50) ? allPages.length : undefined,
    initialData: () => {
      const queryCacheData = queryClient.getQueryState(listStateName)?.data ?? initialDefaultData;
      if (queryCacheData) {
        return {
          pageParams: [undefined, 1],
          pages: [queryCacheData]
        };
      }
    }
  });
  const Search = async function (refetch) {
    if (refetch === void 0) {
      refetch = true;
    }
    const filterParam = MatchingFieldsOnly(GetState(filterStateName, {
      ...params
    }), {
      ...params
    });
    if (HasProperty(filterParam, "PageIndex") && filterParam.PageIndex !== -1) {
      filterParam.PageIndex = 0;
    }
    SetState(filterStateName, filterParam);
    await queryClient.invalidateQueries({
      queryKey: [listStateName, MatchingFieldsOnly(GetState(filterStateName, {
        params
      }), {
        ...params
      })]
    });
    if (refetch) {
      list.refetch();
    }
  };
  const UpdateData = useMutation({
    mutationFn: param => PostData(param),
    onSettled: () => {
      Search(true);
    }
  });
  return {
    entry: {
      state: `${state}-entry${concatName}`,
      param: {
        ...updateParams
      },
      UpdateData
    },
    error: {
      state: `${state}-entry${concatName}-error`
    },
    filter: {
      state: filterStateName,
      param: {
        ...params
      }
    },
    list: {
      Search,
      state: listStateName,
      results: {
        ...Omit(list, ["data"]),
        data: MergeAllItemPages(list?.data?.pages)
      }
    },
    refresherState: `${state}${concatName}-refresher`
  };
};