import { Workbox } from "workbox-window";
import { ORIGIN, UI_URL } from "./common/data/GlobalData.js";
import { GeneratePath, Waiter } from "./common/js/generic/Common.js";
import Cookies from "js-cookie";
import { CFW_LOGINFO } from "./common/js/generic/CFW.js";
export const UnregisterServiceWorkers = function (languageCode, productCode, gotoHomePage, logout) {
  if (gotoHomePage === void 0) {
    gotoHomePage = false;
  }
  if (logout === void 0) {
    logout = false;
  }
  sessionStorage.clear();
  localStorage.clear();
  caches.keys().then(cacheNames => {
    cacheNames.forEach(cacheName => {
      caches.delete(cacheName);
    });
  });
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
  const currentUrl = `${UI_URL}${GeneratePath(languageCode, productCode, "Home")}`;
  if (logout) {
    try {
      window.Tawk_API.logout(() => console.log("Tawk.To logged out"));
    } catch (error) {
      console.log("tawk.To error", error.message);
    }
    const LogOutEvent = async () => {
      Cookies.remove(CFW_LOGINFO, {
        path: "/",
        httpOnly: false,
        secure: true,
        sameSite: "none",
        domain: ORIGIN
      });
      while (Cookies.get(CFW_LOGINFO)) {
        await Waiter(1000);
      }
    };
    LogOutEvent().then(() => {
      if (gotoHomePage) {
        window.location.href = currentUrl;
      } else {
        window.location.reload(true);
      }
    });
  }
};
const registerServiceWorker = (languageCode, productCode) => {
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ("serviceWorker" in navigator) {
    const wb = new Workbox(window.serviceWorker);
    const wbInstalled = event => {
      /**
       * We have the condition — event.isUpdate because we don’t want to show
       * this message on the very first service worker installation,
       * only on the updated
       */
      if (event.isUpdate) {
        UnregisterServiceWorkers(languageCode, productCode);
      }
    };
    wb.removeEventListener("installed", wbInstalled);
    wb.addEventListener("installed", wbInstalled);
    wb.register();
  }
};
export default registerServiceWorker;