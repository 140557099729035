import { Editor } from "@tinymce/tinymce-react";
import React, { Fragment, useEffect, useState } from "react";
import { UI_URL } from "../../../data/GlobalData.js";
import {
  CFW_TEMPATTACHMENTS,
  DIALOGHOLDER,
  FileType_Document,
  FileType_Image,
  FileType_Sound,
  FileType_Video,
  Response_TranslationData,
} from "../../generic/CFW.js";
import { MaintainDialog } from "../../generic/PFW.js";
import { GetConfig } from "../../generic/SFW.js";
import {
  GetState,
  GetStateField,
  SetState,
  UpdateStateField,
} from "../../generic/StoreToolkit.js";
import { FileUploader } from "../FileUploader.js";
import { YoutubeUploader } from "../YoutubeUploader.js";
import {
  CommonLabel,
  InitalAttribute,
  Measurement,
  ValidateRequiredOrPattern,
} from "./Common.js";
import { useLocation } from "react-router-dom";
import { GetProductAndLanguage } from "../../../../common/js/generic/Common.js";
import { useQuery } from "@tanstack/react-query";
import parse from "html-react-parser";
export const DataHTML = ({
  state,
  tran,
  required,
  name,
  style,
  defaultnone,
  maxLength,
}) => {
  const location = useLocation();
  const { languageCode } = GetProductAndLanguage(location.pathname);
  if (!style) {
    style = { width: "90%" };
  }
  let defaultvalue = GetStateField(state, name) ?? "";
  const [curVal, setCurVal] = useState(defaultvalue);
  const [isStateReady, setIsStateReady] = useState();
  const attr = InitalAttribute(
    "html",
    curVal,
    setCurVal,
    defaultnone,
    style,
    null,
    required,
    state,
    name,
    null,
    null,
    maxLength
  );
  const { data: tranList } = useQuery({
    queryKey: [Response_TranslationData],
  });
  attr["apiKey"] = GetConfig("TinyMCEApiKey");
  useEffect(() => {
    if (!isStateReady) {
      setTimeout(() => {
        setIsStateReady(true);
      }, 500);
    }
    if (isStateReady) {
      UpdateStateField(state, name, curVal);
    }
    return function cleanup() {};
  }, [isStateReady, curVal]);
  return isStateReady ? (
    <Fragment>
      <br />
      {tran && (
        <CommonLabel
          name={name}
          required={required}
          tran={tran}
          state={state}
        />
      )}
      <Editor
        key={name}
        {...attr}
        element_format="html"
        value={curVal}
        encoding="xml"
        apiKey={GetConfig("TinyMCEApiKey")}
        init={{
          font_size_formats:
            ".5rem .6rem .7rem .8rem .9rem 1rem 1.1rem 1.2rem 1.3rem 1.4rem 1.5rem 1.6rem 1.7rem 1.8rem 1.9rem 2rem 2.1rem 2.2rem 2.3rem 2.4rem 2.5rem 2.6rem 2.7rem 2.8rem 2.9rem 3rem 3.1rem 3.2rem 3.3rem 3.4rem 3.5rem 3.6rem 3.7rem 3.8rem 3.9rem 4rem",
          relative_urls: false,
          remove_script_host: false,
          document_base_url: `${UI_URL}/`,
          force_br_newlines: false,
          height: 500,
          menubar: true,
          media_live_embeds: true,
          plugins:
            "autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code help wordcount",
          toolbar:
            "youtubeButton videoButton imageButton soundButton documentButton undo redo | blocks | bold italic | alignleft aligncentre alignright alignjustify | indent outdent | bullist numlist",
          setup: (editor) => {
            editor.on("init", (e) => {
              e.target.editorCommands.execCommand("fontSize", false, "1rem");
            });
            editor.ui.registry.addButton("youtubeButton", {
              onAction: () => {
                const postEvt = () => {
                  return false;
                };
                const dialogParam = { ...MaintainDialog };
                window.children = <YoutubeUploader />;
                window.pcb = postEvt;
                dialogParam.shownb = true;
                SetState(DIALOGHOLDER, dialogParam);
              },
            });
            editor.ui.registry.addButton("videoButton", {
              onAction: () => {
                const postEvt = () => {
                  const files = GetState(CFW_TEMPATTACHMENTS);
                  if (files?.length) {
                    const content = [...files].map((itm) => {
                      const fileType = itm
                        .substring(itm.lastIndexOf(".") + 1)
                        .toLowerCase();
                      return `<video style='background-color:#000000;width:93vw;height:80vw;' controls>
                              <source src="${itm}" type="video/${fileType}" />
                            </video>`;
                    });
                    SetState(CFW_TEMPATTACHMENTS, null);
                    editor.insertContent(content.join(""));
                  }
                  return !!files?.length;
                };
                const dialogParam = { ...MaintainDialog };
                window.children = (
                  <FileUploader attachmenttype={FileType_Video} />
                );
                window.pcb = postEvt;
                dialogParam.shownb = true;
                SetState(DIALOGHOLDER, dialogParam);
              },
            });
            editor.ui.registry.addButton("imageButton", {
              onAction: () => {
                const postEvt = () => {
                  const files = GetState(CFW_TEMPATTACHMENTS);
                  if (files?.length) {
                    const content = [...files].map((itm) => {
                      return `<img loading="lazy" src='${itm}' width='100%' />`;
                    });
                    SetState(CFW_TEMPATTACHMENTS, null);
                    editor.insertContent(content.join(""));
                  }
                  return !!files?.length;
                };
                const dialogParam = { ...MaintainDialog };
                window.children = (
                  <FileUploader attachmenttype={FileType_Image} />
                );
                window.pcb = postEvt;
                dialogParam.shownb = true;
                SetState(DIALOGHOLDER, dialogParam);
              },
            });
            editor.ui.registry.addButton("soundButton", {
              onAction: () => {
                const postEvt = () => {
                  const files = GetState(CFW_TEMPATTACHMENTS);
                  if (files?.length) {
                    const content = [...files].map((itm) => {
                      const fileType = itm
                        .substring(itm.lastIndexOf(".") + 1)
                        .toLowerCase();
                      return `<audio controls>
                              <source src="${itm}" type="audio/${fileType}" />
                            </audio>`;
                    });
                    SetState(CFW_TEMPATTACHMENTS, null);
                    editor.insertContent(content.join(""));
                  }
                  return !!files?.length;
                };
                const dialogParam = { ...MaintainDialog };
                window.children = (
                  <FileUploader attachmenttype={FileType_Sound} />
                );
                window.pcb = postEvt;
                dialogParam.shownb = true;
                SetState(DIALOGHOLDER, dialogParam);
              },
            });
            editor.ui.registry.addButton("documentButton", {
              onAction: () => {
                const postEvt = () => {
                  const files = GetState(CFW_TEMPATTACHMENTS);
                  if (files?.length) {
                    const content = [...files].map((itm) => {
                      return `<a href='${itm}' rel='noreferrer noopener' target='_blank' download>${parse(
                        tranList["455"]
                      )}</a><br />`;
                    });
                    SetState(CFW_TEMPATTACHMENTS, null);
                    editor.insertContent(content.join(""));
                  }
                  return !!files?.length;
                };
                const dialogParam = { ...MaintainDialog };
                window.children = (
                  <FileUploader attachmenttype={FileType_Document} />
                );
                window.pcb = postEvt;
                dialogParam.shownb = true;
                SetState(DIALOGHOLDER, dialogParam);
              },
            });
          },
        }}
        onEditorChange={(content, editor) => {
          if (!maxLength && content.length > maxLength) {
            editor.undoManager.undo();
            const dialogParam = { ...MaintainDialog };
            dialogParam.msg = "425";
            SetState(DIALOGHOLDER, dialogParam);
          } else {
            setCurVal(content.trim());
          }
        }}
      />
      <Measurement maxLength={maxLength} curVal={curVal} />
      {isStateReady && (
        <ValidateRequiredOrPattern
          curVal={curVal}
          type="html"
          tran={tran}
          name={name}
          state={state}
          required={required}
          pattern={attr["pattern"]}
          title={attr["title"]}
          languageCode={languageCode}
        />
      )}
    </Fragment>
  ) : (
    <Fragment />
  );
};
