import React, { Fragment, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { FILTERHOLDER } from "../../generic/CFW.js";
import { GetResults } from "../../generic/SFW.js";
const ShowFilter = lazy(() =>
  import(/* webpackChunkName: "ShowFilter" */ "./ShowFilter.js")
);
export const Filter = () => {
  const param = useSelector((state) => GetResults(state[FILTERHOLDER]));
  if (param) {
    return (
      <Suspense fallback={<div className="loading" />}>
        <ShowFilter param={param} />
      </Suspense>
    );
  }
  return <Fragment />;
};
