import React, { Fragment, lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { UI_URL } from "../../../common/data/GlobalData.js";
import { UnregisterServiceWorkers } from "../../../serviceWorkerRegistration.js";
import ImageButton from "../controls/ImageButton.js";
import MenuItem from "../controls/MenuItem.js";
import MessageCount from "../controls/MessageCount.js";
import {
  CFW_LOAD_MENU,
  DIALOGHOLDER,
  PRODUCTCODES_UPUBLISHME,
  PRODUCTCODES_URTECHCITY,
  PRODUCTCODES_URTECHPOGO,
  PRODUCTCODES_URTECHPOS,
  PRODUCTCODES_URTECHSHOP,
} from "../generic/CFW.js";
import { GeneratePath, GetProductAndLanguage } from "../generic/Common.js";
import { MaintainDialog } from "../generic/PFW.js";
import { GetStateField, SetState } from "../generic/StoreToolkit.js";
import { User } from "../generic/User.js";
import LanguageEntry from "../controls/LanguageEntry.js";
import ScreenHandler from "../controls/Screenhandler.js";
import { LoadClientSideOnly } from "../controls/LazyLoader.js";
import { RetrieveClientProfile } from "../apiControls/RetrieveClientProfile.js";
import { useSelector } from "react-redux";
import { GetResults } from "../generic/SFW.js";
import { LinkNav } from "../controls/LinkNav.js";
const HeaderCompany = lazy(() =>
  import(/* webpackChunkName: "HeaderCompany" */ "./Company.js")
);
const HeaderJobPortal = lazy(() =>
  import(/* webpackChunkName: "HeaderJobPortal" */ "./JobPortal.js")
);
const HeaderPOGO = lazy(() =>
  import(/* webpackChunkName: "HeaderPOGO" */ "./POGO.js")
);
const HeaderPOS = lazy(() =>
  import(/* webpackChunkName: "HeaderPOS" */ "./POS.js")
);
const HeaderShopper = lazy(() =>
  import(/* webpackChunkName: "HeaderShopper" */ "./Shopper.js")
);
const HeaderUPublishMe = lazy(() =>
  import(/* webpackChunkName: "HeaderUPublishMe" */ "./UPublishMe.js")
);
const ChangeLanguage = (pathname) => {
  const languageState = "LANGUAGE_STATE";
  const postEvt = () => {
    let urlHasLanguage = false;
    const languageCode = GetStateField(languageState, "LanguageCode");
    const pathNames = pathname.split("/").map((itm) => {
      if (itm.length === 2) {
        urlHasLanguage = true;
        return languageCode;
      }
      return itm;
    });
    if (!urlHasLanguage) {
      pathNames.splice(2, 0, languageCode);
    }
    window.location.href = `${UI_URL}${pathNames.join("/")}`;
  };
  const dialogParam = { ...MaintainDialog };
  window.children = <LanguageEntry state={languageState} tran="620" required />;
  dialogParam.title = "571";
  dialogParam.pb = "198";
  window.pcb = postEvt;
  dialogParam.shownb = true;
  SetState(DIALOGHOLDER, dialogParam);
};
export const Header = () => {
  const location = useLocation();
  const { languageCode, productCode, title } = GetProductAndLanguage(
    location.pathname
  );
  const GetHeader = () => {
    const loadMenu = useSelector((state) => GetResults(state[CFW_LOAD_MENU]));
    if (loadMenu) {
      switch (productCode.toUpperCase()) {
        case PRODUCTCODES_URTECHPOS:
          return <HeaderPOS />;
        case PRODUCTCODES_URTECHSHOP:
          return <HeaderShopper />;
        case PRODUCTCODES_URTECHCITY:
          return <HeaderCompany />;
        case PRODUCTCODES_UPUBLISHME:
          return <HeaderUPublishMe />;
        case PRODUCTCODES_URTECHPOGO:
          return <HeaderPOGO />;
        default:
          return <HeaderJobPortal />;
      }
    }
    return <Fragment />;
  };
  if (
    location.pathname.toLowerCase().includes("/dailytimerecord/") ||
    location.pathname.toLowerCase().includes("/auth/linkedin/callback")
  ) {
    return <Fragment />;
  } else {
    return (
      <header>
        <div className="menuHead" style={{ minHeight: "50px" }}>
          <div className="registrationLogin">
            <ImageButton
              clicker={() => {
                ChangeLanguage(location.pathname);
              }}
              className="ui-icon-flag-white"
              tran="73"
              hinddenOnMobile={true}
            />
            <span>
              <LoadClientSideOnly>
                {!User.IsLoggedIn() ? (
                  <span>
                    <LinkNav
                      tran={"15"}
                      exact="true"
                      to={GeneratePath(languageCode, productCode, "SignIn")}
                    >
                      <ImageButton
                        className="ui-icon-key-white"
                        tran="15"
                        hinddenOnMobile={true}
                      ></ImageButton>
                    </LinkNav>
                  </span>
                ) : (
                  <span>
                    <RetrieveClientProfile productCode={productCode} />
                    <LinkNav
                      tran={"269"}
                      exact="true"
                      to={GeneratePath(languageCode, productCode, "Message")}
                    >
                      <div className="messageCount">
                        <ImageButton
                          className="ui-icon-mail-open-white"
                          tran="269"
                          hinddenOnMobile={true}
                        ></ImageButton>
                        <MessageCount />
                      </div>
                    </LinkNav>
                    <ScreenHandler forMobile={false}>
                      <ImageButton
                        clicker={() => {
                          UnregisterServiceWorkers(
                            languageCode,
                            productCode,
                            true,
                            true
                          );
                        }}
                        className="ui-icon-locked-white"
                        tran="39"
                        hinddenOnMobile={true}
                      ></ImageButton>
                    </ScreenHandler>
                  </span>
                )}
                <LinkNav
                  tran={"22"}
                  exact="true"
                  to={GeneratePath(languageCode, productCode, "UpdateProfile")}
                >
                  <ImageButton
                    primary={!User.IsLoggedIn()}
                    className="ui-icon-person-white"
                    tran={User.IsLoggedIn() ? "22" : "16"}
                    hinddenOnMobile={true}
                  ></ImageButton>
                </LinkNav>
              </LoadClientSideOnly>
            </span>
          </div>
        </div>
        <div className="logo">
          <span className="logoTitle">{title}</span>
          <ul
            className="menu"
            onMouseEnter={() => {
              SetState(CFW_LOAD_MENU, true);
            }}
            onClick={() => {
              SetState(CFW_LOAD_MENU, true);
            }}
          >
            <MenuItem
              onClick={(e) => e.preventDefault()}
              className="ui-icon-menu"
              tran="678"
            >
              <Suspense fallback={<div className="loading" />}>
                <GetHeader />
              </Suspense>
            </MenuItem>
          </ul>
        </div>
      </header>
    );
  }
};
