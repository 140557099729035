const LanguagesData = [{
  LanguageCode: "en",
  LanguageName: "English"
}, {
  LanguageCode: "th",
  LanguageName: "ภาษาไทย"
}, {
  LanguageCode: "tl",
  LanguageName: "Tagalog"
}];
export default LanguagesData;