import { User } from "./User.js";
const timeZone = new Date().getTimezoneOffset() / 60;
const GetMonthName = function (val, isShort) {
  if (isShort === void 0) {
    isShort = false;
  }
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  if (isShort) {
    return months[val - 1].substring(0, 3);
  } else {
    return months[val - 1];
  }
};
const GetFormattedDate = function (dateParam) {
  if (dateParam === void 0) {
    dateParam = new Date();
  }
  dateParam = new Date(dateParam);
  const currentDate = `${dateParam.getDate() + 1}-${GetMonthName(dateParam.getMonth() + 1, true)}-${dateParam.getFullYear()}`;
  return currentDate;
};
export const SendEmail = {
  UserGUID: null,
  Email: null,
  Subject: null,
  Body: null,
  ModifiedGUID: null,
  ModifiedDate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainLotGames = {
  Process: null,
  Picture: null,
  LotGUID: null,
  CountryId: null,
  LotName: null,
  LotDescription: null,
  NumberOfCombinations: null,
  MinimumCombinationsToWin: null,
  MinimumNumber: null,
  MaximumNumber: null,
  IsGroupAllowed: false,
  IsCombinationUnique: false,
  IsCombinationInOrder: false,
  Price: null,
  GroupNumber: null,
  LinkedToGovernmentLot: false,
  ModifiedGUID: null,
  TimeZone: timeZone
};
export const MaintainLotDraws = {
  Process: null,
  LotDrawGUID: null,
  DrawDate: null,
  CountryId: null,
  LotGUID: null,
  TimeZone: timeZone,
  JackpotPrice: null,
  WinningCombination: null,
  ModifiedGUID: User.UserGUID()
};
export const MaintainLotBets = {
  Process: null,
  LotName: null,
  CountryId: null,
  Picture: null,
  Price: null,
  PaypalCurrency: null,
  BetGUID: null,
  LotDrawGUID: null,
  UserGUID: User.UserGUID(),
  BetDate: null,
  Combinations: null,
  GroupNumber: null,
  ClientId: null
};
export const MaintainApplicationProposal = {
  Process: null,
  Title: null,
  PostId: null,
  ClientId: null,
  UserGUID: User.UserGUID(),
  Proposal: null,
  HourlyRate: null,
  DailyRate: null,
  WeeklyRate: null,
  MonthlyRate: null,
  OfferHourlyRate: null,
  OfferDailyRate: null,
  OfferWeeklyRate: null,
  OfferMonthlyRate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainClientProfile = {
  Process: null,
  ClientId: null,
  IsConfidential: null,
  ClientName: null,
  Details: null,
  CountryId: null,
  Address: null,
  CompanyStatusId: null,
  ModifiedGUID: User.UserGUID(),
  UserGUID: null,
  TimeZone: null,
  UseLotteryProduct: null,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainWorkExperience = {
  Process: null,
  WorkExperienceId: null,
  PositionName: null,
  CompanyName: null,
  Address: null,
  Details: null,
  UserGUID: User.UserGUID(),
  DateStarted: null,
  DateEnded: null,
  ModifiedGUID: User.UserGUID(),
  PageIndex: 0,
  EndOfList: false
};
export const MaintainEducation = {
  Process: null,
  EducationId: null,
  EducationName: null,
  CourseName: null,
  Details: null,
  UserGUID: User.UserGUID(),
  DateStarted: null,
  DateEnded: null,
  EducationLevelId: null,
  ModifiedGUID: User.UserGUID(),
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainEducationFiles = {
  Filter: {
    EducationId: null,
    UrlPathOrFileName: null,
    AttachmentTypeId: null,
    AttachmentName: null
  },
  Process: null,
  EducationId: null,
  UrlPathOrFileName: null,
  AttachmentTypeId: null,
  AttachmentName: null
};
export const MaintainOtherCredential = {
  Process: null,
  OtherCredentialId: null,
  OtherCredentialName: null,
  Details: null,
  UserGUID: User.UserGUID(),
  DateStarted: null,
  OtherCredentialTypeId: null,
  ModifiedGUID: User.UserGUID(),
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainOtherCredentialFiles = {
  Filter: {
    OtherCredentialId: null,
    UrlPathOrFileName: null,
    AttachmentTypeId: null,
    AttachmentName: null
  },
  Process: null,
  OtherCredentialId: null,
  UrlPathOrFileName: null,
  AttachmentTypeId: null,
  AttachmentName: null
};
export const MaintainJobProfileFilesData = {
  Filter: {
    UserGUID: User.UserGUID(),
    UrlPathOrFileName: null,
    AttachmentTypeId: null,
    AttachmentName: null
  },
  Process: null,
  UserGUID: User.UserGUID(),
  UrlPathOrFileName: null,
  AttachmentTypeId: null,
  AttachmentName: null
};
export const MaintainPortfolio = {
  Process: null,
  PortfolioId: null,
  PortfolioName: null,
  Details: null,
  UserGUID: User.UserGUID(),
  ModifiedGUID: User.UserGUID(),
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainPortfolioFiles = {
  Filter: {
    PortfolioId: null,
    AttachmentTypeId: null,
    UrlPathOrFileName: null,
    AttachmentName: null
  },
  Process: null,
  PortfolioId: null,
  AttachmentTypeId: null,
  UrlPathOrFileName: null,
  AttachmentName: null
};
export const MaintainClientProfileFiles = {
  Filter: {
    _id: null,
    ClientId: null,
    UrlPathOrFileName: null,
    AttachmentTypeId: null,
    AttachmentName: null
  },
  Process: null,
  ClientId: null,
  UrlPathOrFileName: null,
  AttachmentTypeId: null,
  AttachmentName: null
};
export const MaintainSiteMap = {
  Process: null,
  ProductCode: null,
  LanguageCode: null,
  SiteMapId: null,
  Url: null,
  Exclude: null,
  Title: null,
  GUID: null,
  Description: null,
  Image: null,
  CanonicalSiteMapId: null
};
export const MaintainViewedShares = {
  Process: null,
  ShareUrl: null,
  PublisherId: User.PublisherId(),
  ReferralPublisherId: null
};
export const MaintainEmails = {
  TemplateId: null,
  EmailAddress: null,
  FullName: null,
  BlockSending: null,
  Process: null
};
export const MaintainEmailTemplate = {
  TemplateId: null,
  Subject: null,
  Process: null,
  Content: null,
  ModifiedGUID: User.UserGUID(),
  ModifiedDate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainHTMLContentData = {
  Filter: {
    ContentGUID: null,
    Content: null
  },
  Process: null,
  ContentGUID: null,
  Content: null
};
export const MaintainItemDetails = {
  Filter: {
    ItemGUID: null,
    Details: null
  },
  Process: null,
  Details: null,
  ItemGUID: null
};
export const MaintainResellerPackageItemDetails = {
  Filter: {
    ResellerPackageItemGUID: null,
    Details: null
  },
  Process: null,
  Details: null,
  ResellerPackageItemGUID: null
};
export const MaintainEmailTemplateContent = {
  Filter: {
    TemplateId: null,
    Content: null
  },
  Process: null,
  TemplateId: null,
  Content: null
};
export const MaintainMessage = {
  MessageGuid: null,
  Url: null,
  Title: null,
  Message: null,
  ParticipantsGUID: null,
  MessageId: null,
  ModifiedGUID: User.UserGUID(),
  PageIndex: 0,
  EndOfList: false
};
export const MaintainBanner = {
  Filter: {
    BannerGuid: null,
    MarketingText: null,
    ProductCode: null,
    Base64File: null,
    ButtonName: null,
    Url: null,
    LanguageCode: null,
    SortId: null
  },
  Process: null,
  BannerGuid: null,
  MarketingText: null,
  ProductCode: null,
  Base64File: null,
  ButtonName: null,
  Url: null,
  LanguageCode: null,
  SortId: null
};
export const GetCommonEntities = {
  Process: null,
  Val: null,
  Description: null,
  LanguageCode: null,
  ModifiedGUID: User.UserGUID(),
  ModifiedDate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainUserLanguage = {
  Process: null,
  UserGUID: null,
  LanguageCode: null,
  Rate: null,
  ModifiedGUID: User.UserGUID(),
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainOurServices = {
  Process: null,
  ServiceId: null,
  Name: null,
  Description: null,
  ModifiedGUID: User.UserGUID(),
  ModifiedDate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false,
  Picture: null
};
export const MaintainProducts = {
  Process: null,
  ProductId: null,
  ProductCode: null,
  Picture: null,
  Name: null,
  LanguageCode: null,
  Description: null,
  Web: null,
  Android: null,
  iOS: null,
  ModifiedGUID: User.UserGUID(),
  ModifiedDate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainClientLogo = {
  Filter: {
    ClientLogoGuid: null,
    FileName: null,
    Url: null
  },
  Process: null,
  FileName: null,
  ClientLogoGuid: null,
  ModifiedGUID: User.UserGUID(),
  Url: null
};
export const MaintainTranslations = {
  Filter: {
    _id: null,
    TranslationTypeId: null,
    LanguageCode: null,
    TranslationDescription: null
  },
  Process: null,
  _id: null,
  TranslationTypeId: null,
  LanguageCode: null,
  TranslationDescription: null
};
export const TranslateFromEnglishOpenAI = {
  LanguageCode: null,
  Text: null
};
export const MaintainJobProfile = {
  Process: null,
  UserGUID: null,
  JobTitle: null,
  Overview: null,
  HealthConditionIfAny: null,
  HourlyRate: null,
  DailyRate: null,
  WeeklyRate: null,
  MonthlyRate: null,
  Level: null,
  ModifiedGUID: User.UserGUID(),
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainUserSkills = {
  Process: null,
  UserGUID: User.UserGUID(),
  SkillId: null,
  SkillName: null,
  Rate: null,
  ModifiedGUID: User.UserGUID(),
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainJobPostsSkills = {
  Process: null,
  PostId: null,
  Preference: null,
  SkillId: null,
  SkillName: null,
  Rate: null,
  ModifiedGUID: User.UserGUID(),
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainAdsenseEarnings = {
  Process: null,
  PublisherId: null,
  ReportDate: null,
  Year: null,
  Earnings: null,
  MinimumEarnings: null,
  MaximumEarnings: null,
  CountryId: null,
  UserGUID: User.UserGUID()
};
export const MaintainUsers = {
  Process: null,
  UserGUID: null,
  PromoCode: null,
  ReferralsPromoCode: null,
  Picture: null,
  Email: null,
  Password: null,
  ConfirmPassword: null,
  FullName: null,
  NickName: null,
  UserStatusId: null,
  Address: null,
  Gender: null,
  Roles: null,
  ContactNumber: null,
  DateJoined: null,
  BirthDate: null,
  CountryId: null,
  FacebookId: null,
  TwitterId: null,
  LinkedInId: null,
  GoogleId: null,
  PaypalEmailAddress: null,
  ActivationCode: null,
  ModifiedGUID: User.UserGUID(),
  ModifiedDate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false,
  Remarks: null,
  Points: null,
  ShareUrl: null
};
export const MaintainDynamicOptions = {
  Process: null,
  DynamicOptionsId: null,
  DynamicOptionsName: null,
  DynamicOptionTypeId: null,
  Description: null,
  SortId: null,
  LanguageCode: null,
  ModifiedGUID: User.UserGUID(),
  ModifiedDate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false,
  Picture: null
};
export const MaintainDynamicOptionType = {
  Process: null,
  DynamicOptionTypeId: null,
  DynamicOptionTypeName: null,
  ModifiedGUID: User.UserGUID(),
  ModifiedDate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainTranslationType = {
  Process: null,
  TranslationTypeId: null,
  TranslationTypeName: null,
  ModifiedGUID: User.UserGUID(),
  ModifiedDate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false,
  TranslationTypeIds: null
};
export const MaintainRecommendUs = {
  Process: null,
  RecommendUsId: null,
  ProductId: null,
  Description: null,
  IsPublic: null,
  LanguageCode: null,
  ModifiedGUID: User.UserGUID(),
  ModifiedDate: null,
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainStoreExpenses = {
  Process: null,
  ExpenseId: null,
  Amount: null,
  ExpenseName: null,
  ExpenseDate: null,
  ModifiedGUID: User.UserGUID(),
  TimeZone: timeZone,
  DateFrom: null,
  DateTo: null,
  ClientId: null,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainPosition = {
  Process: null,
  PositionId: null,
  PositionName: null,
  ModifiedGUID: null
};
export const MaintainPOS = {
  Process: null,
  SalesGUID: null,
  SalesDate: new Date(),
  PackageGUID: null,
  ItemGUID: null,
  Quantity: null,
  ItemName: null,
  Amount: null,
  Picture: null,
  ModifiedGUID: User.UserGUID(),
  ItemType: null,
  AdditionalInfo: null,
  IsCredit: null,
  ClientId: null,
  Search: null,
  Change: null,
  Total: 0,
  SellingPrice: null,
  CurrencySymbol: null,
  CapitalPricePerPiece: null
};
export const MaintainShops = {
  Process: null,
  PackageItemName: null,
  ItemTypeId: null,
  CountryId: null
};
export const SearchJobs = {
  PageIndex: 0,
  EndOfList: false,
  Process: null,
  Title: null,
  CategoryId: null,
  LanguageCode: null,
  LevelId: null,
  WorkDuration: null,
  WorkLocationType: null,
  CountryId: null,
  StateId: null,
  MinimumHourlyRate: null,
  MaximumHourlyRate: null,
  MinimumDailyRate: null,
  MaximumDailyRate: null,
  MinimumWeeklyRate: null,
  MaximumWeeklyRate: null,
  MinimumMonthlyRate: null,
  MaximumMonthlyRate: null,
  TimeZone: null,
  PostId: null,
  ClientId: null
};
export const MaintainSkills = {
  Process: null,
  SkillId: null,
  SkillName: null,
  ModifiedGUID: User.UserGUID()
};
export const MaintainUserTasks = {
  Process: null,
  UserGUID: User.UserGUID(),
  TaskId: null,
  TaskName: null,
  Message: null,
  Hours: null,
  Minutes: null,
  UserTaskId: null,
  PageIndex: 0,
  EndOfList: false,
  ClientId: null,
  ClientIds: null,
  TaskDate: null,
  TimeZone: timeZone
};
export const MaintainContent = {
  Process: null,
  ContentGUID: null,
  Title: null,
  Content: null,
  Comments: null,
  CategoryId: null,
  LanguageCode: null,
  Status: null,
  ModifiedGUID: User.UserGUID(),
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false,
  FullName: null,
  Picture: null
};
export const AskAI = {
  Topics: null,
  GeneratedImages: null,
  GeneratedResults: null
};
export const MaintainInventory = {
  Process: null,
  ClientId: null,
  InventoryGUID: null,
  ItemGUID: null,
  ItemType: null,
  ItemName: null,
  SupplierGUID: null,
  Quantity: null,
  QuantitySold: null,
  ExpirationDate: null,
  CapitalPricePerPiece: null,
  SellingPricePerPiece: null,
  DateAdded: null,
  ModifiedGUID: User.UserGUID(),
  PageIndex: 0,
  EndOfList: false,
  TimeZone: timeZone
};
export const MaintainEmployees = {
  Process: null,
  Email: null,
  UserGUID: null,
  FullName: null,
  HourlyRate: null,
  DailyRate: null,
  WeeklyRate: null,
  MonthlyRate: null,
  TimeZone: timeZone,
  StatusId: null,
  ClientId: null,
  PageIndex: 0,
  EndOfList: false
};
export const MaintainCart = {
  Process: null,
  DeliveryInstructions: null,
  PaymentTypeId: null,
  DeliveryAddressId: null,
  DeliveryAddress: null,
  ChangeFor: null,
  ModifiedGUID: User.UserGUID()
};
export const MaintainOrderDetails = {
  Process: null,
  OrderDetailGUID: null,
  OrderGUID: null,
  ResellerPackageItemGUID: null,
  Quantity: null,
  PricePerPiece: null,
  DeliveryFee: null,
  TotalPrice: null,
  DeliveryStatus: null,
  TimeZone: timeZone,
  Remarks: null,
  PageIndex: 0,
  EndOfList: false,
  UserGUID: User.UserGUID()
};
export const MaintainOrderHeader = {
  Process: null,
  OrderGUID: null,
  PaymentTypeId: null,
  UserGUID: User.UserGUID(),
  OrderDate: null,
  DeliveryAddress: null,
  DeliveryInstructions: null,
  ChangeFor: null,
  OrderHeaderId: null
};
export const MaintainResellerPackageItems = {
  Process: null,
  ResellerPackageItemGUID: null,
  ItemGUID: null,
  Points: null,
  PackageGUID: null,
  ModifiedGUID: User.UserGUID(),
  PackageItemName: null,
  PackageItemGUID: null,
  Address: null,
  ClientName: null,
  Picture: null,
  PageIndex: 0,
  EndOfList: false,
  TopUpPrice: null,
  DeliveryFee: null,
  ItemTypeId: null,
  CountryId: null,
  Size: null,
  ItemTypeName: null,
  SellingPricePerPiece: null,
  ItemName: null,
  ResellerPackageItemGUIDs: null,
  Details: null,
  Rate: null,
  Star5: null,
  Star4: null,
  Star3: null,
  Star2: null,
  Star1: null,
  Comment: null,
  TimeZone: timeZone
};
export const MaintainLotOrder = {
  Process: null,
  OrderGUID: null,
  OrderDate: null,
  ClientName: null,
  FullName: null,
  OrderStatus: null,
  BetIds: null,
  OrderHeaderId: null,
  PaymentTypeId: null,
  Amount: null,
  UserGUID: User.UserGUID(),
  ClientId: null,
  TimeZone: timeZone
};
export const MaintainOrderPayments = {
  Process: null,
  OrderGUID: null,
  UserGUID: null,
  PaymentOptions: null,
  ChangeFor: null,
  DeliveryAddress: null,
  DeliveryInstructions: null,
  CurrencySymbol: null,
  PaymentTypeId: null,
  TotalPrice: null,
  OrderHeaderId: null
};
export const MaintainItems = {
  Process: null,
  ItemGUID: null,
  ItemName: null,
  ItemType: null,
  ClientId: null,
  Details: null,
  ClientIds: null,
  AvailableOnlineSelling: null,
  ResellingAllowed: null,
  Size: null,
  Picture: null,
  ModifiedGUID: User.UserGUID(),
  PageIndex: 0,
  EndOfList: false
};
export const MaintainPackages = {
  Process: null,
  PackageGUID: null,
  ClientId: null,
  PackageName: null,
  Description: null,
  ModifiedGUID: User.UserGUID(),
  ItemGUID: null,
  Quantity: null,
  PackageDetails: null,
  AvailableOnlineSelling: null,
  ResellingAllowed: null,
  SellingPrice: null,
  PageIndex: 0,
  EndOfList: false,
  PackageDetailId: null
};
export const MaintainPackageDetails = {
  PackageDetailId: null,
  ItemGUID: null,
  Quantity: null,
  SellingPrice: null,
  ItemName: null,
  ItemTypeName: null,
  ItemType: null,
  Process: null,
  Picture: null,
  ClientId: null
};
export const MaintainFileUplod = {
  Extensions: null
};
export const MaintainSuppliers = {
  Process: null,
  SupplierGUID: null,
  SupplierName: null,
  Description: null,
  Address: null,
  ContactNumbers: null,
  Email: null,
  ClientId: null,
  ClientIds: null,
  ModifiedGUID: User.UserGUID(),
  PageIndex: 0,
  EndOfList: false
};
export const MaintainPayments = {
  Process: null,
  GCash: null,
  UserGUID: User.UserGUID(),
  FirstName: null,
  MiddleName: null,
  LastName: null,
  Address: null,
  AccountNumber: null,
  BankName: null,
  OtherBankInfo: null,
  MobileNumber: null,
  Income: null,
  PaypalEmailAddress: null,
  Amount: null,
  IsPaymentRequested: null,
  Comments: null,
  ModifiedGUID: User.UserGUID(),
  TimeZone: timeZone,
  PageIndex: 0,
  EndOfList: false,
  Keywords: null,
  ModifiedDate: GetFormattedDate()
};
export const MaintainJobPostsFiles = {
  Filter: {
    PostId: null,
    UrlPathOrFileName: null,
    AttachmentTypeId: null,
    AttachmentName: null
  },
  Process: null,
  PostId: null,
  UrlPathOrFileName: null,
  AttachmentTypeId: null,
  AttachmentName: null
};
export const MaintainDailyTimeRecord = {
  Process: null,
  UserGUID: User.UserGUID(),
  ClientId: null,
  TimeZone: timeZone
};
export const MaintainJobPosts = {
  Process: null,
  PostId: null,
  ClientId: null,
  CountryId: null,
  HourlyRate: null,
  DailyRate: null,
  WeeklyRate: null,
  MonthlyRate: null,
  Level: null,
  WorkDuration: null,
  WorkLocationType: null,
  Title: null,
  Description: null,
  GroupName: null,
  CategoryId: null,
  LanguageCode: null,
  Vacancies: null,
  IsPublic: null,
  StateId: null,
  ModifiedGUID: User.UserGUID(),
  PageIndex: 0,
  EndOfList: false,
  ApplyToAnotherUrl: null
};
export const MaintainDialog = {
  title: null,
  showpb: true,
  shownb: false,
  pb: null,
  nb: null,
  msg: null,
  children: null
};
export const MaintainFilter = {
  title: "225",
  msg: null,
  currentState: null,
  factoryState: null
};
export const MaintainFileDownload = {
  Url: null,
  FileName: null,
  AttachmentTypeId: null
};
export const MaintainFiles = {
  Process: null,
  Id: null,
  Param: null,
  Response: null,
  Service: null,
  ColumnGroup: null
};
export const MaintainChildren = {
  Process: null,
  ColumnId: null,
  Id: null,
  Param: null,
  Response: null,
  Service: null,
  ColumnGroup: null
};
export const MaintainTransaction = {
  Process: null,
  ModifiedDate: null,
  UserGUID: null,
  Amount: null,
  TransactionName: null,
  TransactionId: null
};
export const MaintainSocials = {
  FacebookId: null,
  TwitterId: null,
  LinkedInId: null,
  GoogleId: null
};