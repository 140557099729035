import React, { Fragment, useEffect, useRef } from "react";
import {
  AcceptedFileExtensions,
  DIALOGHOLDER,
  FileExtensions_Video,
  Response_SiteMapUrlData,
  Response_TranslationData,
} from "../generic/CFW.js";
import { useQuery } from "@tanstack/react-query";
import parse from "html-react-parser";
import { AddScriptsWithCallBack, CommaFormatDigit } from "../generic/Common.js";
import { MaintainDialog } from "../generic/PFW.js";
import { GetConfig } from "../generic/SFW.js";
import { GetStateField, SetState } from "../generic/StoreToolkit.js";
import Label from "./Label.js";
import ReadMeFirst from "./ReadMeFirst.js";
import { MediaUploader } from "./YoutubeMediaUploader.js";
export const YoutubeUploader = () => {
  const noAccessEntry = useRef();
  const videoTitle = useRef();
  const videoFile = useRef();
  const uploadButton = useRef();
  const beforeUpload = useRef();
  const duringUpload = useRef();
  const percentTransferred = useRef();
  const bytesTransferred = useRef();
  const totalBytes = useRef();
  const youtubeList = useRef();
  const channelId = "UC21a1GvUjMW6_-YCaO6sszw";
  let GoogleAuth;
  const contentOwnerId = "112516717710870251039";
  const scopes = GetConfig("Youtube.OAUTH2_SCOPES").split(" ");
  const { data: tranList } = useQuery({
    queryKey: [Response_TranslationData],
  });
  const GoogleApiClientReady = () => {
    if (window.gapi) {
      window.gapi.load("client:auth2", () => {
        window.gapi.client
          .init({
            apiKey: GetConfig("Youtube.APIKey"),
            clientId: GetConfig("GoogleId"),
            discoveryDocs: [
              "https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest",
            ],
            scope: scopes.join(" "),
          })
          .then(() => {
            GoogleAuth = window.gapi.auth2.getAuthInstance();
            GoogleAuth.isSignedIn.listen(SetSigninStatus);
            SetSigninStatus();
          });
      });
    } else {
      setTimeout(GoogleApiClientReady, 1000);
    }
  };
  const SetSigninStatus = () => {
    const user = GoogleAuth.currentUser.get();
    if (user.hasGrantedScopes(scopes[0])) {
      //user.grant({ 'scope': "https://www.googleapis.com/auth/youtubepartner" });
      window.gapi.client.load("youtube", "v3", () => {
        window.gapi.auth.authorize(
          {
            client_id: GetConfig("GoogleId"),
            scope: scopes,
            immediate: true,
          },
          (authResult) => {
            if (authResult && !authResult.error) {
              noAccessEntry.current.style.display = "none";
              beforeUpload.current.style.display = "";
              youtubeList.current.style.display = "";
              duringUpload.current.style.display = "none";
            } else {
              ShowAlert(authResult.error);
            }
          }
        );
        window.gapi.client.setApiKey(GetConfig("Youtube.APIKey"));
        window.gapi.client.load(
          "https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest"
        );
        window.gapi.client.youtube.subscriptions
          .insert({
            part: ["snippet"],
            snippet: {
              resourceId: {
                channelId: channelId,
              },
            },
          })
          .then(
            (response) => {
              console.log("Youtube subscription", response);
            },
            (err) => {
              console.log("Youtube subscription", err);
            }
          );
      });

      //RetrievePlayList();
    } else {
      noAccessEntry.current.style.display = "";
      beforeUpload.current.style.display = "none";
      youtubeList.current.style.display = "none";
      duringUpload.current.style.display = "none";
      GoogleAuth.signIn();
    }
  };
  // eslint-disable-next-line no-unused-vars
  const RetrievePlayList = () => {
    const request = window.gapi.client.request({
      method: "GET",
      path: "/youtube/v3/channels",
      params: { part: "snippet", mine: "true" },
    });
    // Execute the API request.
    request.execute(function (response) {
      console.log("Response", response);
    });
  };
  useEffect(() => {
    const youtubeSDK1 = "youtube-jssdk1";
    AddScriptsWithCallBack(
      "https://apis.google.com/js/api.js",
      youtubeSDK1,
      {
        async: "",
        crossorigin: "anonymous",
      },
      GoogleApiClientReady
    );
    return function cleanup() {
      if (GoogleAuth) {
        GoogleAuth.disconnect();
      }
    };
  }, []);
  const ShowAlert = (msg) => {
    const dialogParam = { ...MaintainDialog };
    dialogParam.msg = msg;
    SetState(DIALOGHOLDER, dialogParam);
  };
  const UploadFile = () => {
    videoFile.current.addEventListener("change", () => {
      const newTitle = window.prompt(tranList["639"], "");
      if (newTitle) {
        //const userId = GoogleAuth.currentUser.get().getBasicProfile().getId()
        //"112516717710870251039"
        const metadata = {
          onBehalfOfContentOwner: contentOwnerId,
          onBehalfOfContentOwnerChannel: channelId,
          notifySubscribers: true,
          snippet: {
            title: newTitle,
            description: `${parse(
              tranList["638"]
            )}\r\n${window.location.href.substring(
              0,
              window.location.href.lastIndexOf("/")
            )}`,
            tags: [
              GetStateField(Response_SiteMapUrlData, "Tags", "")
                .trim()
                .split(","),
            ]
              .slice(0, 10)
              .map((itm) => {
                return itm.innerHTML;
              }),
            categoryId: "22",
          },
          status: {
            privacyStatus: "public",
          },
        };
        const uploader = new MediaUploader({
          baseUrl: "https://www.googleapis.com/upload/youtube/v3/videos",
          file: videoFile.current.files[0],
          token: GoogleAuth.currentUser.get().getAuthResponse().access_token,
          metadata: metadata,
          params: {
            part: "snippet,status",
          },
          onError: function (data) {
            let message = data;
            try {
              if (message) {
                const errorResponse = JSON.parse(data);
                message = errorResponse.error.message;
              } else {
                message = "637";
              }
            } finally {
              ShowAlert(message);
            }
          },
          onProgress: function (data) {
            const bytesUploaded = data.loaded;
            percentTransferred.current.innerHTML = parseInt(
              (bytesUploaded * 100) / data.total
            );
            totalBytes.current.innerHTML = CommaFormatDigit(data.total);
            bytesTransferred.current.innerHTML =
              CommaFormatDigit(bytesUploaded);
            duringUpload.current.style.display = "";
          },
          onComplete: function (data) {
            window.gapi.client
              .load(
                "https://content.googleapis.com/discovery/v1/apis/youtubePartner/v1/rest"
              )
              .then(
                () => {
                  window.gapi.client.youtubePartner.policies
                    .list({
                      onBehalfOfContentOwner: contentOwnerId,
                      sort: "TIME_UPDATED_ASC",
                    })
                    .then(
                      (policyResponse) => {
                        console.log(
                          "policyResponse",
                          JSON.stringify(policyResponse)
                        );
                        window.gapi.client.youtubePartner.assets
                          .insert({
                            onBehalfOfContentOwner: contentOwnerId,
                            resource: {
                              type: "web",
                              metadata: {
                                title: newTitle,
                                customId: data.id,
                              },
                            },
                          })
                          .then(
                            (assetResponse) => {
                              console.log(
                                "assetResponse",
                                JSON.stringify(assetResponse)
                              );
                              window.gapi.client.youtubePartner.ownership
                                .update({
                                  assetId: assetResponse.id,
                                  onBehalfOfContentOwner: contentOwnerId,
                                  resource: {
                                    general: {
                                      owner: "CONTENT_OWNER_ID",
                                      ratio: 100,
                                      type: "exclude",
                                    },
                                  },
                                })
                                .then(
                                  (ownerResponse) => {
                                    console.log(
                                      "ownerResponse",
                                      JSON.stringify(ownerResponse)
                                    );
                                    window.gapi.client.youtubePartner.claims
                                      .insert({
                                        resource: {
                                          assetId: assetResponse.id,
                                          videoId: data.id,
                                          policy: {
                                            id: policyResponse[0].id,
                                          },
                                          contentType: "audiovisual",
                                        },
                                      })
                                      .then(
                                        (response) => {
                                          console.log("Response", response);
                                        },
                                        (err) => {
                                          ShowAlert(err);
                                        }
                                      );
                                  },
                                  (err) => {
                                    ShowAlert(err);
                                  }
                                );
                            },
                            (err) => {
                              ShowAlert(err);
                            }
                          );
                      },
                      (err) => {
                        ShowAlert(err);
                      }
                    );
                  console.log("MediaUploader", JSON.stringify(data));
                  duringUpload.current.style.display = "none";
                },
                (err) => {
                  ShowAlert(err);
                }
              );
          },
        });
        uploader.upload();
      }
    });
    videoFile.current.click();
  };
  const Retrieve = () => {};
  useEffect(() => {
    Retrieve();
    return function cleanup() {};
  }, []);
  const PreviewFiles = () => {};
  return (
    <Fragment>
      <div
        style={{ textAlign: "left", maxHeight: "500px", paddingBottom: "20px" }}
      >
        <div style={{ display: "none" }} ref={noAccessEntry}>
          <Label tranVal="305" />
        </div>
        <div style={{ textAlign: "left", maxWidth: "1024px" }}>
          <ReadMeFirst>
            <Label tranVal="301" />
          </ReadMeFirst>
          <div ref={beforeUpload} style={{ display: "none" }}>
            <button
              type="button"
              ref={uploadButton}
              onClick={UploadFile}
              style={{ position: "relative", float: "right", right: "0px" }}
            >
              <Label tranVal="175" />
            </button>
            <br />
            <br />
            <label className="requiredLabel">
              <Label tranVal="174" />
            </label>
            <input
              maxLength="150"
              ref={videoTitle}
              required
              type="text"
              autoComplete="off"
              dataentry=""
              style={{ width: "97%" }}
            />
            <input
              type="file"
              ref={videoFile}
              style={{ display: "none" }}
              accept={AcceptedFileExtensions(FileExtensions_Video)}
            />
          </div>
          <div ref={duringUpload} style={{ display: "none" }}>
            <p>
              <span ref={percentTransferred}></span>% done (
              <span ref={bytesTransferred}></span>/
              <span ref={totalBytes}></span> bytes)
            </p>
          </div>
          <br />
        </div>
        <div
          ref={youtubeList}
          style={{ display: "none", textAlign: "left", paddingTop: "30px" }}
        >
          <Label tranVal="307" />
          {PreviewFiles()}
        </div>
      </div>
    </Fragment>
  );
};
