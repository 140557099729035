import { CFW_CURRENT_URL } from "./CFW.js";
import { GetProductAndLanguage } from "./Common.js";
import { InitializeCustomWorker } from "./InitializeCustomWorker.js";
import { golbaVariables } from "./StoreToolkit.js";
import { User } from "./User.js";
const MaintainDataWorker = async function (method, paramUrl, paramBody, headers, strictHeaders, isFormData, postEvent) {
  if (headers === void 0) {
    headers = {};
  }
  if (strictHeaders === void 0) {
    strictHeaders = false;
  }
  if (isFormData === void 0) {
    isFormData = false;
  }
  if (postEvent === void 0) {
    postEvent = null;
  }
  if (!headers) {
    headers = {};
  }
  if (!strictHeaders) {
    headers["Accept-Encoding"] = "*";
    headers["Content-Type"] = "application/json";
    headers["Access-Control-Allow-Origin"] = "*";
  }
  const requestOptions = {
    method: method,
    headers: headers,
    body: typeof window === "undefined" ? JSON.stringify(paramBody) : paramBody
  };
  if (typeof window === "undefined") {
    return await fetch(paramUrl, {
      ...requestOptions
    }).then(async res => {
      if (isFormData) {
        return await res;
      }
      return await res.json();
    }).then(res => {
      if (!res.ok) {
        throw new Error(res.Message ?? "An error has occurred");
      } else if (res?.Results) {
        return res.Results;
      }
      return res;
    }).then(res => {
      if (postEvent) {
        return postEvent(res);
      }
      return res;
    }).catch(error => {
      console.log(paramUrl, "Looks like there was a problem on doing post: \r\n", JSON.stringify(error.message), requestOptions.body);
      return null;
    });
  } else {
    const {
      productCode,
      languageCode
    } = GetProductAndLanguage(golbaVariables[CFW_CURRENT_URL]);
    requestOptions.headers["Lang"] = languageCode;
    requestOptions.headers["x-access-token"] = User.AccessToken();
    requestOptions.headers["App"] = productCode.toLowerCase();
    return new Promise((resolve, reject) => {
      const postWorker = InitializeCustomWorker(responseAsJson => {
        if (postEvent) {
          resolve(postEvent(responseAsJson));
        }
        resolve(responseAsJson);
      }, error => {
        reject(error);
        console.log("Looks like there was a problem:", error);
      });
      const payload = postWorker.payload(paramUrl, requestOptions, 0);
      postWorker.worker.postMessage(payload);
    });
  }
};
export const PostDataWorker = async function (paramUrl, paramBody, headers, strictHeaders, isFormData, postEvent) {
  if (headers === void 0) {
    headers = {};
  }
  if (strictHeaders === void 0) {
    strictHeaders = false;
  }
  if (isFormData === void 0) {
    isFormData = false;
  }
  if (postEvent === void 0) {
    postEvent = null;
  }
  if (typeof window === "undefined") {
    return await MaintainDataWorker("POST", paramUrl, paramBody, headers, strictHeaders, isFormData, postEvent);
  }
  return MaintainDataWorker("POST", paramUrl, paramBody, headers, strictHeaders, isFormData, postEvent);
};